import React, { useEffect } from 'react'
import { INVOICE_LIST_PAGE_LOADED, INVOICE_LIST_PAGE_UNLOADED } from '../../constants/actionTypes';
import { connect } from 'react-redux';
import api from '../../data/api'
import ListInvoices from './ListInvoices';
// import getTime from 'date-fns/getTime';

const mapStateToProps = state => ({
    currentUser: state.common.currentUser,
    truckroutes: state.common.truckroutes ? state.common.truckroutes.data : null,
    trucks: state.common.trucks ? state.common.trucks.data : null,
    // invoices: state.invoices ? state.invoices : null,
    isSearchResults: state.invoices.search_results ? state.invoices.search_results : false,
});
  
const mapDispatchToProps = dispatch => ({
    onLoad: (payload, pager) =>
      dispatch({ type: INVOICE_LIST_PAGE_LOADED, payload }),
    onUnload: () =>
      dispatch({  type: INVOICE_LIST_PAGE_UNLOADED })
});

function Invoices(props) {
    const [selectedRoute, setSelectedRoute] = React.useState([]);
    const [selectedRouteTitle, setSelectedRouteTitle] = React.useState([]);
    const [defaultRouteSelected, setDefaultRouteSelected] = React.useState(null);
    
    //let mTimeStamp = getTime(selectedDate)/1000; 

    useEffect(() => {
        // const currentDate = Math.floor(Date.now() / 1000);

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        const currentDate = dd + '-' + mm + '-' + yyyy;

        if(!props.isSearchResults) {
            if(props.truckroutes && props.truckroutes.length) {
                let firstRoute=null;
                let firstRouteName=null;
                const firstRouteObj = JSON.parse(localStorage.getItem('current_invoice_filter_route'));
                if(firstRouteObj) {
                    firstRoute = firstRouteObj.id;
                    firstRouteName = firstRouteObj.name;
                }
                if(firstRoute===null) {
                    firstRoute = props.truckroutes[0].id;
                }
                if(firstRouteName===null) {
                    firstRouteName = props.truckroutes[0].name;
                }
                setSelectedRoute(firstRoute);
                setSelectedRouteTitle(firstRouteName);
                //const mSelected = {value: selectedRoute, label: selectedRouteTitle}
                setDefaultRouteSelected({value: selectedRoute, label: selectedRouteTitle});
                props.onLoad(api.Invoices.all(currentDate, firstRoute), null);
            }
        }
        return () => {
            props.onUnload();
        }
    }, []);
    
    return (
        <React.Fragment>
            <ListInvoices 
                invoices={props && props.invoices} 
                routes={props.truckroutes ? props.truckroutes : null} />
        </React.Fragment>)
}


export default connect(mapStateToProps, mapDispatchToProps)(Invoices);