import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../../data/api' 

//theme
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { PROFILE_EDITOR_MODAL_LOADED, UPDATE_FIELD_PROFILE, PROFILE_SUBMITTED, PROFILE_EDITOR_DIALOG_UNLOADED } from '../../../constants/actionTypes';


const useStyles = makeStyles(theme => ({
    container: {
      display: 'block',
      flexWrap: 'wrap',
    },
    textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1)
    },
    btnSubmit: {
        backgroundColor: '#1c3e5d',
        width: '100%',
        color: '#FFFFFF',
        minHeight: 50
    },
    dialogueActions: {
        display: 'block',
        width: '100%',
        padding: '10px 24px 30px',
    },
    uploadButton : {
        textAlign: 'center',
        backgroundColor: '#EEEEEE',
        width: '100%',
        margin: '16px 0 8px'
    },
  }));


const mapStateToProps = state => ({ ...state.users, currentUser: state.common.currentUser,});

const mapDispatchToProps = dispatch => ({
    onLoad: payload => 
        dispatch({ type: PROFILE_EDITOR_MODAL_LOADED, payload }),
    onUpdateField: (key, value) => 
        dispatch({ type: UPDATE_FIELD_PROFILE, key, value }),
    onSubmit: (payload) => 
        dispatch({ type: PROFILE_SUBMITTED, payload }),
    onUnload: () => 
        dispatch({ type: PROFILE_EDITOR_DIALOG_UNLOADED })
});

function ProfileEditor(props) {
    const classes = useStyles();
    const {name} = props.currentUser;
    const [selectedPhoto, setSelectedPhoto] = React.useState(null);

    const updateFieldEvent = key => ev => props.onUpdateField(key, ev.target.value);
    
    const changeName = updateFieldEvent('name');    
    const changeEmail = updateFieldEvent('email');    

    useEffect(() => {
        props.onLoad(props.currentUser); 
        return () => {
            props.onUnload();
        }
    }, []);

    const onPhotoSelectedHandler = event => {
        //console.log(event.target.files[0]);
       setSelectedPhoto(event.target.files[0]);
     }

    const handleSubmitForm = ev => {
        // console.log('handleSubmitForm > first_name:', props.name);
        ev.preventDefault();
        const user = { 
            name: props.currentUser.name, 
            email: props.currentUser.email,
            photo: selectedPhoto ? selectedPhoto : null 
        };
   
        //submit post
        const promise = api.Profile.update(user, props.user.id);
  
        promise.then((response) => {
            //then get a new list
            props.onSubmit(response);
        })
        

        //close modal
        props.handleClose();
    };
    
    return (
        <Dialog open={props.opened} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <form className={classes.container} noValidate autoComplete="off">
                <DialogTitle id="form-dialog-title">EDIT PROFILE</DialogTitle>
                <DialogContent>
                    
                    <TextField
                        id="name"
                        label="YOUR FULL NAME"
                        placeholder="Input number"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 8 }}
                        variant="outlined"
                        value={props.currentUser.name}
                        // value={props.currentUser.name}
                        onChange={changeName}
                        />
                    
                    <TextField
                        id="name"
                        label="YOUR EMAIL"
                        placeholder="Input email"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 8 }}
                        variant="outlined"
                        value={props.currentUser.email}
                        onChange={changeEmail}
                        />
                    <input
                        accept="image/*"
                        className={classes.uploadButton}
                        // style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={onPhotoSelectedHandler}
                        />
                    
                </DialogContent>
                <DialogActions className={classes.dialogueActions}>
                    <Button 
                        fullWidth
                        onClick={handleSubmitForm} 
                        className={classes.btnSubmit}>
                        SUBMIT
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditor);

