import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import api from '../../../data/api'
import { USER_VIEW_PAGE_LOADED, USER_VIEW_PAGE_UNLOADED } from '../../../constants/actionTypes';

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import UserEditorModal from './UserEditorModal.jsx';
import UserAuthModal from './UserAuthModal.jsx';
import ShowSnackbar from '../../common/ShowSnackbar.jsx';

const useStyles = makeStyles(theme => ({ 
    container: {
        backgroundColor: 'f1f2f6'
    },
    heading: {
        padding: 16,
        backgroundColor: '#FFFFFF',
    },
    pageTitle: {
        fontSize: '1.3rem'
    },
    btnApproval: {
        backgroundColor: '#1c3e5d',
        color: '#FFFFFF'
    },
    btnEdit: {
        backgroundColor: '#1c3e5d',
        minWidth: 100,
        color: '#FFFFFF',
        marginLeft: theme.spacing(1),
    },
    boxMap : {
        padding: 16,
        minHeight: 400
    },
    boxDetails: {
        padding: 26,
        backgroundColor: '#FFFFFF',
    },
    input: {display: 'none'},
    userDetailsThumb: {
        minHeight: 300,
        minWidth: 100,
        margin: '0 20px 20px 20px',
        borderRadius: 15,
        border: '1px solid #1c3e5d',
    },
 }));

 const mapStateToProps = state => ({
    ...state.users,
    user: state.users.user
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload) =>
      dispatch({ type: USER_VIEW_PAGE_LOADED, payload }), 
    onUnload: () =>
      dispatch({  type: USER_VIEW_PAGE_UNLOADED })   
});
 
function Viewuser(props) {
    const classes = useStyles();
    
    const [modalOpened, setModalOpen] = React.useState(false);
    const [authModalOpened, setAuthModalOpen] = React.useState(false);

    let { id } = useParams();   
    
    const handleUserEditorLaunch = () => {
        setModalOpen(true);
    };
    const handleUserEditorClose = () => {
        setModalOpen(false);
    };

    const handleAuthEditorLaunch = () => {
        setAuthModalOpen(true);
    };
    const handleAuthEditorClose = () => {
        setAuthModalOpen(false);
    };

    useEffect(() => {
        props.onLoad(api.Users.get(id));
        return () => {
            props.onUnload();
        }
    }, []);

    if (!props.user) {
        return (
          <div className="article-preview">Loading...</div>
        );
    }
    
    if (props.user.length === 0) {
        return (
          <div className="article-preview">
            No details here... yet.
          </div>
        );
    }
    // console.log(' Viewuser user', user);
    return (
        <Box className={classes.container}>
            {props.responseStatus ? 
                <ShowSnackbar 
                    opened={props.responseStatus ? true : false}
                    variant={props.responseStatus ? props.responseStatus : null}
                    message={props.responseMessage!=="" ? props.responseMessage : false} />
                : ''}
            <Grid container className={classes.heading}> 
                <Grid item xs={6}>
                    <Typography variant='h5' className={classes.pageTitle}>AGENT DETAILS</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                    <Button className={classes.btnEdit} onClick={()=> {props.history.push(`/agents/`)}}>BACK TO AGENTS</Button>
                    <Button 
                        variant="contained" 
                        className={classes.btnEdit} 
                        onClick={handleUserEditorLaunch}>EDIT</Button>
                {modalOpened ? 
                    <UserEditorModal
                        opened={modalOpened}
                        handleClose={handleUserEditorClose}
                        user={props.user}
                        mode="update" />
                    : ''}
                    <Button 
                        variant="contained" 
                        className={classes.btnEdit} 
                        onClick={handleAuthEditorLaunch}>EDIT PASS CODE</Button>
                {authModalOpened ? 
                    <UserAuthModal
                        opened={authModalOpened}
                        handleClose={handleAuthEditorClose}
                        user={props.user} />
                        : ''}
                </Grid>
            </Grid>
            <Box className={classes.detailsBody}>
                <Grid container> 
                    {/* <Grid item xs={3}>
                        <Box>
                            <div className={classes.userDetails}>
                                <div className={classes.userDetailsThumb}>

                                </div>
                            </div>
                        </Box>
                    </Grid> */}
                    <Grid item xs={9}>
                        <Box className={classes.boxDetails}>
                            <Box>
                                <div><Typography variant='h4'>{props.user.name}</Typography></div>
                                <div><Typography variant='h6'>ID Number: {props.user.id_number}</Typography></div>
                                <div><Typography variant='h6'>Phone Number: {props.user.phone_number}</Typography></div>
                                <div><Typography variant='h6'>Pass code: --</Typography></div>
                            </Box>
                            {/* <Box>
                                <Button variant="contained" size="small" className={classes.btnApproval} onClick={()=> {console.log('clicked me')}}>SAVE</Button>
                            </Box> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            
        </Box>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Viewuser)