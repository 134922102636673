import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    btnSubmit: {
        backgroundColor: '#1c3e5d',
        width: '100%',
        color: '#FFFFFF',
        minHeight: 50
    },
    dialogueActions: {
        display: 'block',
        width: '100%',
        padding: '10px 24px 30px',
    }, 
    fullWidth : {
        width: '100%'
    }, 
    button: {
        border: '1px solid #444'
    }
  }));


export default function UploadCreditNoteModal(props) {
    const classes = useStyles();
    
    return (
        <Dialog open={props.opened} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <form className={classes.container} noValidate autoComplete="off">
                <DialogTitle id="form-dialog-title">UPLOAD CREDIT NOTE</DialogTitle>
                <DialogContent  className={classes.fullWidth}>
                    <Box className={classes.boxMap}>
                        <input
                            accept="image/*"
                            className={classes.input}
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            multiple
                            type="file"
                            />
                        <label htmlFor="raised-button-file">
                            <Button variant="raised" component="span" className={classes.button}>
                                click to Select file
                            </Button>
                        </label> 
                    </Box>
                    <TextField
                        id="reg_no"
                        label="NOTES"
                        placeholder="Input additional notes"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 8 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        />
                    
                </DialogContent>
                <DialogActions className={classes.dialogueActions}>
                    <Button 
                        fullWidth
                        onClick={props.handleClose} 
                        className={classes.btnSubmit}>
                        SUBMIT
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
