import React from 'react'
import Filter from '../common/listRow/listTitle/components/Filter';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import api from '../../data/api'
import { CURRENT_MODULE_LOADED, INVOICE_LIST_APPLY_ROUTE_FILTER, INVOICE_LIST_FILTER_BY_PAYMENT_MODE, DELETE_INVOICE, SET_INVOICES_PAGE, LOAD_SUMMARIES } from '../../constants/actionTypes';
// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import UploadCashMemoModal from './UploadCashMemoModal';
import AddInvoiceModal from './AddInvoiceModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShowSnackbar from '../common/ShowSnackbar.jsx';
import DateFnsUtils from '@date-io/date-fns';
import AutoSuggest from '../common/AutoSuggest/AutoSuggest';
// import getTime from 'date-fns/getTime';
import format from 'date-fns/format';
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Pagination from 'react-mui-pagination'; //https://github.com/bnabriss/react-mui-pagination

const mapStateToProps = state => ({
    ...state.invoices,
});

const mapDispatchToProps = dispatch => ({
    onOnSetModule: () => 
      dispatch({ type: CURRENT_MODULE_LOADED, module:'INVOICES' }),
    onSetPage: (payload, page) => 
      dispatch({ type: SET_INVOICES_PAGE, payload, page }),
    onSetRouteFilter: (payload, page) => 
      dispatch({ type: INVOICE_LIST_APPLY_ROUTE_FILTER, payload, page }),
    onSetFilterByPaymentMode: (payload, page) => 
      dispatch({ type: INVOICE_LIST_FILTER_BY_PAYMENT_MODE, payload, page }),
    onReloadSummaries: (payload) => 
      dispatch({ type: LOAD_SUMMARIES, payload }),
    onDeleteInvoice: (invoiceID, payload) => 
      dispatch({ type: DELETE_INVOICE, invoiceID, payload })
});

const useStyles = makeStyles(theme => ({
    list_root: {
        minHeight: 300
    },
    listheader:{
        backgroundColor: '#EEEEEE',
        fontSize: 16, 
        "& tr th": {
            fontSize: 16,
        }
    },
    rowheader: {
        height: 60
    },
    seeMore: {
      marginTop: theme.spacing(3),
    },
    btnAddCashMemo: {
        margin: theme.spacing(1),
        backgroundColor: '#1c3e5d',
        width: 200,
        height: 36, 
        "& span": {
            fontSize: 16
        },
        marginTop: 5,
        color: '#FFFFFF'
    },
    btnAdd: {
        backgroundColor: '#db3332',
        width: 200,
        height: 36, 
        "& span": {
            fontSize: 16
        }
    },
    btnEdit: {
        backgroundColor: '#1c3e5d',
        width: 171,
        color: '#FFFFFF'
    },
    btnRemove: {
        color: '#1c3e5d',
        border: '1px solid #1c3e5d',
        width: 171,
        fontSize: 16
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    pageTitle: {
        padding: 15,
        backgroundColor: '#FFFFFF',
        maxWidth: '100%'
    },
    selectRoute: {
        maxWidth: 250,
        "& label": {
            transform: 'translate(14px, 13px) scale(1)',
        },
        "& div": {
            "& div" :{
                height: 40
            }
        }
    },
    selectDate: {
        maxWidth: 250,
        marginTop: 5,
        marginLeft: 5,
        "& label": {
            transform: 'translate(14px, 13px) scale(1)',
        },
        "& div": {
            "& input" :{
                padding: '10px 0 10px 10px',
            }
        }
    },
    selectOther: {
        maxWidth: 250,
        marginTop: 5,
        marginLeft: 10,
        textAlign: 'justify',
        "& label": {
            transform: 'translate(14px, 13px) scale(1)',
        },
        "& div": {
            "& div" :{
                padding: 10
            }
        }
    },
    txtRight: {
        textAlign: "right"
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
    },
    btnView : {
        backgroundColor: '#1c3e5d',
        width: '100%',
        minWidth: 100,
        maxWidth: 200,
        boxSizing: 'border-box',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontWeight: 500,
        borderRadius: 4,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        margin: 0,
        display: 'inline-flex',
        verticalAlign: 'middle',
        color: '#FFFFFF',
        justifyContent: 'center',
        textDecoration: 'none',
        padding: '8px',
        fontSize: 16,
        "&:hover": {
            backgroundColor: '#cccccc'
        }
    },
    loading: {
      minHeight: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fff'
    },
    proggressRoot: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        minHeight: '30vh',
    },
    tableBody: {
        "& tr td": {
            fontSize: 16,
            padding: '15px 10px'
        }
    },
  }));

const customSelectStyles = {
    control: (base, state) => ({
        ...base,
        padding: "1px",
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused || state.isSelected ? 'grey' : 'white'
    }),
    menu: (provided, state) => ({
      ...provided,
      padding: 5,
      zIndex: 10000,
      background: '#FFFFFF'
    })
}

function ListInvoices(props) {
    const classes = useStyles();
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    const {current_invoice_filter, invoices=null} = props;

    // console.log("props.routes", props.routes);
    const [selectedRoute, setSelectedRoute] = React.useState(props.routes && props.routes.length ? (current_invoice_filter && current_invoice_filter.selected_route ? current_invoice_filter.selected_route.id : props.routes[0].id) : null);
    const [selectedRouteTitle, setSelectedRouteTitle] = React.useState(props.routes && props.routes.length ? (current_invoice_filter && current_invoice_filter.selected_route ? current_invoice_filter.selected_route.name : props.routes[0].name) : null);
    const [filterBy, setSelectedFilter] = React.useState(10);
    const [openCashMemo, setCashMemoOpen] = React.useState(false);
    let mySelectedDate = mm + '-' + dd + '-' + yyyy;
    if(current_invoice_filter!=null && current_invoice_filter.selected_date!=null) {
        const splitDate = current_invoice_filter.selected_date.split('-');
        const m = splitDate[0];
        const d = splitDate[1];
        const y = splitDate[2];
        mySelectedDate =  d + '-' + m + '-' + y;
    }
    const [selectedDate, setSelectedDate] = React.useState(mySelectedDate);
    // const [openCreditMemo, setCreditMemoOpen] = React.useState(false);
    const [openInvoice, setInvoiceOpen] = React.useState(false);
    const [page, setPage] = React.useState(null);

    props.onOnSetModule();

    const defaultRouteSelected = {
        value: selectedRoute,
        label: selectedRouteTitle
    }

    const handleCashMemoLaunch = () => {
        setCashMemoOpen(true);
    };

    const handleCashMemoClose = () => {
        setCashMemoOpen(false);
    };

    const handleAddInvoiceLaunch = () => {
        setInvoiceOpen(true);
    };

    const handleAddInvoiceClose = () => {
        setInvoiceOpen(false);
        props.onSetRouteFilter(api.Invoices.all(formattedDate(selectedDate), selectedRoute), null);
        const summaryDate = dd + '-' + mm + '-' + yyyy;
        
        setTimeout(()=>{
            const promise = api.Summary.all(summaryDate);
            promise.then(response => {
                props.onReloadSummaries(response);
            })
        }, 1000);
         
    };

    const formattedDate = date => {
        const splitDate = date.split('-');
        const m = splitDate[0];
        const d = splitDate[1];
        const y = splitDate[2];
        return  d + '-' + m + '-' + y;
    }
    
    const handleFilterRoute = value => {
        const val = value.value;
        
        setSelectedRoute(val);
        props.onSetRouteFilter(api.Invoices.all(formattedDate(selectedDate), val), null);
    };

    const handleDateChange = date => {
        const mNewDate = format(date, 'MM-dd-yyyy');
        setSelectedDate(mNewDate);
        
        setSelectedRoute(selectedRoute);
        props.onSetRouteFilter(api.Invoices.all(formattedDate(mNewDate), selectedRoute), null);
    };

    const handleFilterByPaymentMode = event => {
        const mode = event.target.value;
        // console.log('mode', mode);
        setSelectedFilter(mode);
        const promise = api.Invoices.filter(mode, formattedDate(selectedDate), selectedRoute, page);
        props.onSetFilterByPaymentMode(promise, page);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.onSetPage(api.Invoices.all(formattedDate(selectedDate), selectedRoute, newPage), newPage);
    };
   
    let page_total = props.pagination ? props.pagination.total : 0;
    return (
        <React.Fragment>
            <div  className={classes.list_root}>
            {props.responseStatus ? 
                <ShowSnackbar 
                    opened={props.responseStatus ? true : false}
                    variant={props.responseStatus ? props.responseStatus : null}
                    message={props.responseMessage!=="" ? props.responseMessage : false} />
                : ''}
            <Container className={classes.pageTitle}>
                <Box
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                    >
                    <Grid container display="flex" alignItems="center">
                        <Grid item xs={6}>
                            <Box display="flex" style={{'align-items': 'center'}}>
                                <Box sm={6} xs={12} style={{'flex-grow': '1'}}>
                                    <div style={{'margin-top':'-3px'}}>
                                        <AutoSuggest 
                                            placeholder='Select a route'
                                            onOptionSelected={handleFilterRoute}
                                            style={customSelectStyles}
                                            defaultValue={{value: selectedRoute, label: selectedRouteTitle}} />
                                    </div>
                                    
                                </Box>
                                <Box sm={6} xs={12} style={{'flex-grow': '1'}}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} variant="outlined">
                                        <KeyboardDatePicker
                                            disableToolbar
                                            // variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="SELECT DATE (MM/DD/YY)"
                                            value={selectedDate}
                                            className={classes.selectDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }} 
                                            style={{'width':'100%'}}
                                            // InputProps={{ className: classes.selectRoute }}
                                            inputVariant="outlined"
                                            inputSize="small"
                                        />
                                    </MuiPickersUtilsProvider>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}  className={classes.txtRight} display="flex">
                            {/*<Button 
                                variant="contained" 
                                size="small" 
                                onClick={handleCashMemoLaunch}
                                className={classes.btnAddCashMemo}>UPLOAD CASH MEMO</Button>
                             {openCashMemo ? 
                                <UploadCashMemoModal
                                    opened={openCashMemo}
                                    handleClose={handleCashMemoClose} />
                                : ''} */}
                            {/* <Filter slug="Delivery Status" css={{display:'block'}}></Filter> */}
                            <TextField
                                id="delivery_agent"
                                select
                                fullWidth
                                label="FILTER"
                                className={classes.selectOther}
                                onChange={handleFilterByPaymentMode}
                                SelectProps={{
                                    MenuProps: {
                                    className: classes.menu,
                                    },
                                }}
                                margin="normal"
                                style={{ marginBottom: 8 }}
                                variant="outlined"
                                >
                                    <MenuItem>
                                        <em>SELECT</em>
                                    </MenuItem>
                                    <MenuItem value="cash">CASH</MenuItem>
                                    <MenuItem value="credit">BANK CREDIT</MenuItem>
                                    <MenuItem value="kcb_jaza_duka">KCB JAZA DUKA</MenuItem>
                                
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            
            <Table size="small">
                <TableHead className={classes.listheader}>
                    <TableRow className={classes.rowheader}>
                        <TableCell><b>ORDER NUMBER</b></TableCell>
                        <TableCell><b>SHOP NAME</b></TableCell>
                        <TableCell><b>NO. OF ITEMS</b></TableCell>
                        <TableCell><b>PAYMENT METHOD</b></TableCell>
                        {/* <TableCell><b>PAYMENT STATUS</b></TableCell> */}
                        <TableCell colSpan={2} align="right">
                            <Button 
                                variant="contained" 
                                size="small" 
                                color="secondary" 
                                onClick={handleAddInvoiceLaunch}
                                className={classes.btnAdd}><b>ADD INVOICES</b>
                            </Button>
                            {openInvoice ? 
                                <AddInvoiceModal
                                    opened={openInvoice}
                                    handleClose={handleAddInvoiceClose} 
                                    mode="create"/>
                            : ''}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                {invoices ? invoices.map((i, index) => (
                    <TableRow key={index}>
                        <TableCell><Checkbox></Checkbox> {index}</TableCell>
                        <TableCell>{i.invoice.shop ? i.invoice.shop.name : ''}</TableCell>
                        <TableCell>{i.invoice.items ? i.invoice.items.length : ''}</TableCell>
                        <TableCell>{i.invoice.shop ? i.invoice.shop.payment_mode : ''}</TableCell>
                        {/* <TableCell>{i.invoice.payment_status}</TableCell> */}
                        
                        <TableCell align="right">
                            {/* <Button 
                                variant="outlined" 
                                size="small" 
                                className={classes.btnRemove}
                                onClick={() => handleRemoveInvoice(index)}>REMOVE</Button> */}
                        </TableCell>
                        <TableCell align="right">
                            <Link variant="contained" size="small" className={classes.btnView} to={`/invoices/${index}`}>VIEW </Link>
                        </TableCell>
                    </TableRow>
                )) : ""}
                </TableBody>
            </Table>
            { invoices ? "" : <div className={classes.loading}> No invoices for today... yet. </div>}
            {invoices ?  
                <Pagination 
                    page={page} 
                    setPage={handleChangePage} 
                    total={page_total}
                    activeProps={{ style: { backgroundColor: '#db3332' } }} />
            : <div></div>}
            </div>
        </React.Fragment>)
}


export default connect(mapStateToProps, mapDispatchToProps)(ListInvoices);