import React from 'react'
import PageTitle from './components/PageTitle/PageTitle'
import Search from './components/Search/Search'

//UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(()=> ({
    titlePanel: {
        padding: 10,
        backgroundColor: '#FFFFFF'
    }
}))

export default function TitlePanel() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid container className={classes.titlePanel} alignItems="center">
                <Grid item  xs={6} sm={3}>
                    <PageTitle></PageTitle>
                </Grid>
                <Grid item xs={6}>
                    <Search></Search>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
