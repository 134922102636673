import React from "react";
import { connect } from "react-redux";
import api from "../../data/api";
import Title from "../common/listRow/listTitle/components/Title";
import { Link } from "react-router-dom";
import {
  CURRENT_MODULE_LOADED,
  DELETE_ROUTE,
  SET_ROUTES_PAGE,
  CLEAR_ROUTE_SUBMIT_RESPONSE
} from "../../constants/actionTypes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

//Theme
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// import TextField from '@material-ui/core/TextField';
// import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Pagination from "react-mui-pagination"; //https://github.com/bnabriss/react-mui-pagination
import TruckRouteEditorModal from "./TruckRouteEditorModal";
import ShowSnackbar from "../common/ShowSnackbar.jsx";

const useStyles = makeStyles(theme => ({
  list_root: {
    minHeight: 300
  },
  listheader: {
    backgroundColor: "#EEEEEE",
    fontSize: 16,
    "& tr th": {
      fontSize: 16
    }
  },
  rowheader: {
    height: 60
  },
  seeMore: {
    marginTop: theme.spacing(3)
  },
  btnAdd: {
    backgroundColor: "#db3332",
    width: 200,
    height: 36,
    "& span": {
      fontSize: 16
    }
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30
  },
  selectTruck: {
    maxWidth: 200,
    textAlign: "left"
  },
  txtRight: {
    textAlign: "right"
  },
  pageTitle: {
    padding: 15,
    backgroundColor: "#FFFFFF",
    maxWidth: "100%"
  },
  btnRemove: {
    color: "#1c3e5d",
    border: "1px solid #1c3e5d",
    width: 171,
    fontSize: 16,
    "& span": {
      fontSize: 16
    }
  },
  btnView: {
    backgroundColor: "#1c3e5d",
    width: 171,
    boxSizing: "border-box",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontWeight: 500,
    borderRadius: 4,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    marginLeft: 10,
    position: "relative",
    alignItems: "center",
    userSelect: "none",
    margin: 0,
    display: "inline-flex",
    verticalAlign: "middle",
    color: "#FFFFFF",
    justifyContent: "center",
    textDecoration: "none",
    padding: "8px",
    fontSize: 16,
    "&:hover": {
      backgroundColor: "#cccccc"
    }
  },
  loading: {
    minHeight: 250,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff"
  },
  proggressRoot: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    },
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    minHeight: "30vh"
  },
  tableBody: {
    "& tr td": {
      fontSize: 16,
      padding: "15px 10px"
    }
  }
}));

// const routes = null;
// let reducedRoutes = null;
// let routesMenu = null;
// let cleanRoutes = null; //[...reducedRoutes];

const mapStateToProps = state => ({
  ...state.truckroutes
});

const mapDispatchToProps = dispatch => ({
  onOnSetModule: () =>
    dispatch({ type: CURRENT_MODULE_LOADED, module: "TRUCKROUTES" }),
  onSetPage: (payload, page) =>
    dispatch({ type: SET_ROUTES_PAGE, payload, page }),
  onBeforeDelete: () => dispatch({ type: CLEAR_ROUTE_SUBMIT_RESPONSE }),
  onDeleteTruckRoute: (routeID, payload) =>
    dispatch({ type: DELETE_ROUTE, routeID, payload })
});

function ListTruckRoutes(props) {
  const classes = useStyles();

  props.onOnSetModule();
  //console.log('cleanRoutes', cleanRoutes);

  const [openModal, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);

  const handleAddTruckLaunch = () => {
    setOpen(true);
  };

  const handleAddTruckClose = () => {
    setOpen(false);
  };

  // const [cleanRoutes, setFiltered] = React.useState(reducedRoutes);
  // const handleFilterRoutes = event => {
  //     // setFiltered(reducedRoutes.filter(r => r.primary_key == event.target.value));
  // };

  const MySwal = withReactContent(Swal);
  const handleRemoveTruckRoute = val => {
    props.onBeforeDelete();
    MySwal.fire({
      title: "Are you sure?",
      text: "Confirm delete",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        props.onDeleteTruckRoute(val, api.Routes.del(val));
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.onSetPage(api.Routes.all(newPage), newPage);
  };

  if (!props.truckroutes) {
    return (
      <div className={classes.proggressRoot}>
        <CircularProgress size={24} thickness={4} />
      </div>
    );
  }
  // if (props.truckroutes.length === 0) {
  //     return ( <div className={classes.loading}> No routes are here... yet. </div> );
  // }
  const reducedRoutes = props.truckroutes.map(route => {
    return {
      primary_key: route.id,
      route_name: route.name,
      stops: route.stops.length
    };
  });
  // console.log("reducedRoutes", reducedRoutes);

  // const routesMenu = reducedRoutes.map((route) => {
  //     return {
  //         route_id: route.primary_key,
  //         name: route.route_name
  //     }
  // });
  let page_total = props.pagination ? props.pagination.total : 0;
  return (
    <React.Fragment>
      <div className={classes.list_root}>
        <Container className={classes.pageTitle}>
          {props.responseStatus ? (
            <ShowSnackbar
              opened={props.responseStatus ? true : false}
              variant={props.responseStatus ? props.responseStatus : null}
              message={
                props.responseMessage !== "" ? props.responseMessage : false
              }
            />
          ) : (
            ""
          )}
          <Box justifyContent="center" display="flex" alignItems="center">
            <Grid container display="flex" alignItems="center">
              <Grid item xs={6}>
                <Title title="ROUTE DETAILS"></Title>
              </Grid>
              <Grid item xs={6} className={classes.txtRight}>
                {/* <TextField
                                    id="delivery_agent"
                                    select
                                    fullWidth
                                    label="Filter by Route"
                                    className={classes.selectTruck}
                                    value=""
                                    onChange={handleFilterRoutes}
                                    SelectProps={{
                                        MenuProps: {
                                        className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    style={{ marginBottom: 8 }}
                                    variant="outlined"
                                    >
                                    {routesMenu.map(option => (
                                        <MenuItem key={option.route_id} value={option.route_id}>
                                        {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField> */}
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Table size="small">
          <TableHead className={classes.listheader}>
            <TableRow className={classes.rowheader}>
              <TableCell>
                <b>ROUTE</b>
              </TableCell>
              <TableCell>
                <b>STOPS</b>
              </TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={handleAddTruckLaunch}
                  className={classes.btnAdd}
                >
                  <b>ADD ROUTE</b>
                </Button>
                {openModal ? (
                  <TruckRouteEditorModal
                    opened={openModal}
                    handleClose={handleAddTruckClose}
                    mode="create"
                  />
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {reducedRoutes.length
              ? reducedRoutes.map((route, index) => (
                  // <ListRow
                  //     key={index}
                  //     item={route}
                  //     dir="routes"
                  //     onClickRemove={() => handleRemoveTruckRoute(route.primary_key)}></ListRow>
                  <TableRow key={index}>
                    <TableCell>{route.route_name}</TableCell>
                    <TableCell>{route.stops}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          handleRemoveTruckRoute(route.primary_key)
                        }
                        className={classes.btnRemove}
                      >
                        REMOVE
                      </Button>

                      {/* <Button variant="contained" size="small" className={classes.btnEdit} onClick={()=> {console.log('clicked me')}}>VIEW</Button> */}
                      <Link
                        variant="contained"
                        size="small"
                        className={classes.btnView}
                        to={`/routes/${route.primary_key}`}
                      >
                        VIEW{" "}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              : ""}
          </TableBody>
        </Table>
        {reducedRoutes.length ? (
          ""
        ) : (
          <div className={classes.loading}> No routes are here... yet. </div>
        )}
        {reducedRoutes.length ? (
          <Pagination
            page={page}
            setPage={handleChangePage}
            total={page_total}
            activeProps={{ style: { backgroundColor: "#db3332" } }}
          />
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ListTruckRoutes);
