import React from 'react';
import Footer from './common/footer/Footer'
import Leftmenu from './common/leftmenu/Leftmenu';

//routing
import { Route, Redirect } from 'react-router-dom'

//Views
import Trucks  from './Trucks/';
import ViewTruck from './Trucks/ViewTruck';
import TruckRoutes  from './TruckRoutes/';
import ViewTruckRoute  from './TruckRoutes/ViewTruckRoute';
import Invoices  from './Invoices/';
import ViewInvoice  from './Invoices/ViewInvoice';
import Statistics  from './Statistics/Statistics';
import SalesReturns  from './SalesReturns/';
import ViewSalesReturn from './SalesReturns/ViewSalesReturn';
// import Notfound  from './NotFound/NotFound'
import Summaries from './common/summary/Summaries';
import Search from './common/titlePanel/TitlePanel';
import ViewUser from './Settings/UserManagement/ViewUser';
import MyProfile from './Settings/MyProfile/MyProfile';
import Debtors from './Debtors/';
import ViewDebtor from './Debtors/ViewDebtor';

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import UserManagement from './Settings/UserManagement/';
import Policy from './common/Policy';

const useStyles = makeStyles(theme=> ({
  // appBarSpacer: theme.mixins.toolbar,
  appBarSpacer:  {
    minHeight: 76
  },
  
  container: {
    paddingLeft: '0px',
    paddingRight: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    paddingBottom: 30,
    maxWidth: '100%'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  contentBody: {
    flexGrow: 1,
    minHeight: '30vh',
    overflow: 'auto',
    background: '#fff'
  }
}))

function MainBody(summaryData) {
  const classes = useStyles();
  // console.log(summaryData.summaries);
  // const {summaries} = summaryData;
  const [showSearch, setShowSearch] = React.useState(true);
  const [showSummary, setShowSummary] = React.useState(true);

  return (
    <React.Fragment>
    
        <Leftmenu></Leftmenu>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container className={classes.container}>
            <Search></Search>
            {showSummary ? <Summaries></Summaries> : ''}
            <Box className={classes.contentBody}>         
                  <Route exact path="/" activeClassName="active" component={Trucks} hasSummary />
                  <Route path="/trucks/:id" activeClassName="active" component={ViewTruck} />
                  <Route path="/routes/:id" activeClassName="active" component={ViewTruckRoute} />
                  <Route exact path="/routes" activeClassName="active" component={TruckRoutes} />
                  <Route path="/statistics" activeClassName="active" component={Statistics} />
                  <Route path="/invoices/:id" activeClassName="active" component={ViewInvoice} />
                  <Route exact path="/invoices" activeClassName="active" component={Invoices} />
                  <Route path="/salesreturns/:id" activeClassName="active" component={ViewSalesReturn} />
                  <Route exact path="/salesreturns" activeClassName="active" component={SalesReturns} />
                  <Route path="/debtors/:id" activeClassName="active" component={ViewDebtor} />
                  <Route exact path="/debtors" activeClassName="active" component={Debtors} />
                  <Route path="/agents/:id" activeClassName="active" component={ViewUser} />
                  <Route exact path="/agents" activeClassName="active" component={UserManagement} />
                  <Route path="/profile" activeClassName="active" component={MyProfile} />
                  <Route path="/policy" activeClassName="active" component={Policy} showSummary={()=>setShowSummary(false)}/>
                  <Redirect to="/" />          
            </Box>        
          </Container>
          <Footer></Footer>
        </main>
      
    </React.Fragment>
  );
}

export default MainBody;