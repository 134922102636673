import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from '../../../data/api'
import { connect } from 'react-redux';
import { USER_EDITOR_MODAL_LOADED, USER_LIST_PAGE_LOADED, UPDATE_FIELD_D_AGENT, USER_SUBMITTED } from '../../../constants/actionTypes';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1)
    },
    btnSubmit: {
        backgroundColor: '#1c3e5d',
        width: '100%',
        color: '#FFFFFF',
        minHeight: 50
    },
    dialogueActions: {
        display: 'block',
        width: '100%',
        padding: '10px 24px 30px',
    },

    passcodePaper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)'
      },
      input: {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
      iconButton: {
        padding: 10,
        backgroundColor: '#999999',
      },
  }));

const mapStateToProps = state => ({ 
    user: state.users.user 
});

const mapDispatchToProps = dispatch => ({
    onLoad: payload => 
        dispatch({ type: USER_EDITOR_MODAL_LOADED, payload }),
    onReLoad: payload => 
        dispatch({ type: USER_LIST_PAGE_LOADED, payload }),
    onUpdateField: (key, value) => 
      dispatch({ type: UPDATE_FIELD_D_AGENT, key, value }),
    onSubmit: (payload) => 
        dispatch({ type: USER_SUBMITTED, payload }),
  });

function UserEditorModal(props) {
    
    const classes = useStyles();
    
    const [namerFieldError, setNameFieldError] = React.useState(false);
    const nameErrorText = "Enter a valid name";
    const [nameFieldErrorText, setNameFieldErrorText] = React.useState(nameErrorText);

    const [phoneNumberError, setPhoneNumberError] = React.useState(false);
    const phoneErrorText = "Enter only 10 numbers in the format: 07123456789";
    const [phoneNumberErrorText, setPhoneNumberErrorText] = React.useState(phoneErrorText);
    
    const mIDErrorText = "Enter only numbers";
    const [idNumberError, setIdNumberError] = React.useState(false);
    const [idNumberErrorText, setIdNumberErrorText] = React.useState(mIDErrorText);

    const updateFieldEvent = key => ev => props.onUpdateField(key, ev.target.value);
    const validatePhoneNumber = new RegExp(/([+]?\d{10})/);
    const validID = new RegExp(/^\d+$/);
    
    //const changeName = updateFieldEvent('name');
    // const changePhoneNo = updateFieldEvent('phone_number');
    //const changeIDNo = updateFieldEvent('id_number');
    // const handleGeneratePasscode = () => {
    //     return console.log('code:', Math.random(0, 1000) * 10000);
    // };
    const changeName = ev => {
        const val = ev.target.value;
        setNameFieldError(false);
        setNameFieldErrorText("");
        props.onUpdateField('name',val);
    }
    const changeIDNo = ev => {
        let validated = true;
        const val = ev.target.value;
        const validID = new RegExp(/^\d+$/);
        if(!validID.test(val)) {
            setIdNumberError(true);
            setIdNumberErrorText(mIDErrorText);
            validated = false;
            return false;
        }
        if (val.length > 12){
            setIdNumberError(true);
            setIdNumberErrorText("Please enter a valid ID number format");
            validated = false;
            return;
        } 
        if(validated) {
            setIdNumberError(false);
            props.onUpdateField('id_number',val);
        }
    }
    const onChangePhoneNo = ev => {
        const val = ev.target.value;
        const vPhone = new RegExp(/^[+]?\d+$/);
        
        if(!vPhone.test(val)) {
            setPhoneNumberError(true);
            setPhoneNumberErrorText("Please enter only numbers");
            return false;
        } 
        if (val.length > 10){
            setPhoneNumberError(true);
            setPhoneNumberErrorText("Please enter 10 numbers");
            return false;
        } 
        setPhoneNumberError(false);
        props.onUpdateField('phone_number',val);
        
    }

    useEffect(() => {
        if(props && props.user) {
            props.onLoad(api.Users.get(props.user.id));
        }
        // return () => {
        //     props.onUnload();
        // }
    }, []);

    const handleSubmitForm = ev => {
        //console.log('props.user', props.user);
        ev.preventDefault();
        if(!props.user) {
            setNameFieldError(true);
            setPhoneNumberError(true);
            setIdNumberError(true);
            return;
        }
        if(!props.user || !props.user.name || props.user.name.length===0) {
            setNameFieldError(true);
            setNameFieldErrorText("Please enter agent names");
        }
        if(!props.user || !props.user.phone_number || props.user.phone_number.length===0) {
            setPhoneNumberError(true);
            setPhoneNumberErrorText("Please enter phone number");
            
        }
        if(!props.user || !props.user.id_number || props.user.id_number.length===0) {
            setIdNumberError(true);
            setIdNumberErrorText("Please enter id number");
            
        }
        if(props.user.name && props.user.phone_number && props.user.id_number) {
            if(props.user.phone_number.length !== 10) {
                setPhoneNumberError(true);
                setPhoneNumberErrorText("Please enter 10 numbers");
                return;
            }
            if(!validatePhoneNumber.test(props.user.phone_number)) {
                setPhoneNumberError(true);
                setPhoneNumberErrorText(phoneErrorText);
                return;
            }
            if(props.user.id_number.length > 12) {
                setIdNumberError(true);
                setIdNumberErrorText("Please enter max 12 numbers");
                return;
            }
            if(!validID.test(props.user.id_number)) {
                setIdNumberError(true);
                setIdNumberErrorText(mIDErrorText);
                return;
            }
            const user = {
                name: props.user.name,
                phone_number: props.user.phone_number,
                id_number: props.user.id_number,
                user_id: props.user.user_id ? props.user.user_id : 0,
            };
    
            //submit post
            const promise = props.mode && props.mode==="update" ? api.Users.update(user, props.user.id) : api.Users.create(user) ;
            props.onSubmit(promise);
            props.onReLoad(api.Users.all());
            /*promise.then((response)=> {
                //then get a new list
                props.onSubmit(response);
                props.onReLoad(api.Users.all());
            }).catch((error)=> {
                console.log('error', error);
            })*/
            

            //close modal
            props.handleClose();
        }
    };

    let modalTitle = null;
    if(props.user && props.mode==="update") {
        modalTitle = 'EDIT AGENT';
    } else {
        modalTitle = 'ADD A NEW AGENT';
    }

    return (
        <Dialog open={props.opened} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <form 
                className={classes.container} 
                noValidate 
                autoComplete="off">
                
                <DialogTitle id="form-dialog-title">{modalTitle}</DialogTitle>
                <DialogContent>
                    
                    <TextField
                        id="names"
                        label="FULLNAME"
                        fullWidth
                        margin="normal"
                        size="small"
                        style={{ marginBottom: 8 }}
                        variant="outlined"
                        value={props.user && props.mode==="update" ? props.user.name : null}
                        helperText= {nameFieldErrorText}
                        onChange={changeName}
                        error={namerFieldError}
                        />

                    <TextField
                        id="phone"
                        label="PHONE NUMBER"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 8 }}
                        variant="outlined"
                        helperText= {phoneNumberErrorText}
                        inputProps = {{maxLength: 10}}
                        value={props.user && props.mode==="update" ? props.user.phone_number : null}
                        onChange={onChangePhoneNo}
                        error={phoneNumberError}
                        />

                    <TextField
                        id="id_no"
                        label="ID NUMBER"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 8 }}
                        variant="outlined"
                        type="number"
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0, 10)
                        }}
                        helperText={idNumberErrorText}
                        value={props.user && props.mode==="update" ? props.user.id_number : null}
                        onChange={changeIDNo}
                        error={idNumberError}
                        />
                        
                    {/* <TextField
                        id="user_name"
                        label="USERNAME"
                        style={{ marginBottom: 8 }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={user_name}
                        /> */}
                    
                    
                    
                    {/* <Paper 
                        variant="outlined" 
                        className={classes.passcodePaper}
                        margin="normal"
                        style={{ marginBottom: 8 }}>
                        <Button 
                            className={classes.iconButton} 
                            aria-label="menu"
                            onClick={handleGeneratePasscode}>
                            Generate
                        </Button>
                        <InputBase
                            className={classes.input}
                            placeholder="Click button to generate pass code"
                            // value={passcode}
                        />
                    </Paper> */}
                </DialogContent>
                <DialogActions className={classes.dialogueActions}>
                    <Button 
                        fullWidth
                        onClick={handleSubmitForm} 
                        className={classes.btnSubmit}
                        type="submit">
                        SUBMIT
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEditorModal);