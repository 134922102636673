import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fade from '@material-ui/core/Fade';

const useStylesFacebook = makeStyles({
    root: {
        background: 'beige',
        height: '100%',
        width: 48,
        zIndex: 100,
        borderRadius: '0 0 10px 10px'
    },
    holder: {
      position: 'relative',
    },
    top: {
      color: '#eef3fd',
      position: 'absolute',
      left: '27%',
      top: 10,
    },
    bottom: {
      color: '#6798e5',
      animationDuration: '550ms',
      position: 'absolute',
      left: '27%',
      top: 10,
    },
});

export default function ShowCircularLoader(props) {
  
  const queueRef = React.useRef([]);
  let [open, setOpen] = React.useState(props.opened);

  const processQueue = () => {
    if (queueRef.current.length > 0) {
      setOpen(true);
    }
  };
  useEffect(() => {
    if (open) {
      // immediately begin dismissing current message
      // to start showing new one
      setOpen(false);
    } else {
      processQueue();
    }  
  }, []);

  const classes = useStylesFacebook();
  return (
      <Fade
          in={props.opened}
          style={{
            transitionDelay: open ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <div className={classes.root}>
            {/* {open ?  */}
            <div className={classes.holder}>
                <CircularProgress
                    variant="determinate"
                    value={100}
                    className={classes.top}
                    size={24}
                    thickness={4}
                    {...props}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    className={classes.bottom}
                    size={24}
                    thickness={4}
                    {...props}
                />
            </div>
            {/* : null }  */}
        </div>
      </Fade>
    
  );
}
