import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { ROWS_PER_PAGE } from "../constants/";

const superagent = superagentPromise(_superagent, global.Promise);

// const API_ROOT = 'https://staging.quadco.ihub.co.ke/api';
// const API_ROOT = "https://api.staging.quadco.made.ke/api";
// const API_ROOT = 'https://quadco.tsavopay.com/api';
const API_PRODUCTION = "https://api.quadco.made.ke/api";
const API_STAGING = "https://api.staging.quadco.made.ke/api";
const PRODUCTION_DOMAIN = "https://quadco.made.ke";
let API_ROOT = API_STAGING;

let currentUrl = document.location.protocol + '//' + document.location.hostname;

if (currentUrl === PRODUCTION_DOMAIN ) {
  API_ROOT = API_PRODUCTION;
}

const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set("Authorization", `Bearer ${token}`);
  }
};
const AUTH_KEY = "6f1a50156927248c79905751f74ced4038518809";
// const AUTH_KEY = "81cdae89d8c32a158d55f6f87da88c0470187b4b"; //new
const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .set("X-Authorization", AUTH_KEY)
      .use(tokenPlugin)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .set("X-Authorization", AUTH_KEY)
      .use(tokenPlugin)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .set("X-Authorization", AUTH_KEY)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body) => {
    return superagent
      .post(`${API_ROOT}${url}`)
      .send(body)
      .set("X-Authorization", AUTH_KEY)
      .use(tokenPlugin)
      .then(responseBody);
    // superagent.post(`${API_ROOT}${url}`).send(JSON.stringify(body)).set('Accept', 'text/plain').use(tokenPlugin).then(responseBody)
  },
  postMultipart: (url, field1, field2, file) => {
    return superagent
      .post(`${API_ROOT}${url}`)
      .field(field1)
      .field(field2)
      .attach(Object.keys(file)[0], Object.values(file)[0]) // .attach('invoice_file', file)
      .set("X-Authorization", AUTH_KEY)
      .use(tokenPlugin)
      .then(responseBody);
  }
};

const Auth = {
  current: () => requests.get("/profile"),
  login: (username, password) =>
    requests.post("/auth/login", {
      username,
      password
    })
};

const Organisations = {
  all: () => requests.get(`/organizations/`)
};

const Tags = {
  getAll: () => requests.get("/tags")
};

// const limit = (count, p) => `records=${count}&page=${p ? p * count : 0}`;
const checkPage = p => {
  if (p) {
    if (p === 1) {
      p++;
    }
  } else {
    p = 0;
  }
};
const limit = (count, p) => `records=${count}&page=${p ? p : 1}`;
const omitSlug = article =>
  Object.assign({}, article, {
    slug: undefined
  });
const Articles = {
  all: page => requests.get(`/articles?${limit(10, page)}`),
  byAuthor: (author, page) =>
    requests.get(`/articles?author=${encode(author)}&${limit(5, page)}`),
  byTag: (tag, page) =>
    requests.get(`/articles?tag=${encode(tag)}&${limit(10, page)}`),
  del: slug => requests.del(`/articles/${slug}`),
  favorite: slug => requests.post(`/articles/${slug}/favorite`),
  favoritedBy: (author, page) =>
    requests.get(`/articles?favorited=${encode(author)}&${limit(5, page)}`),
  feed: () => requests.get("/articles/feed?limit=10&offset=0"),
  get: slug => requests.get(`/articles/${slug}`),
  unfavorite: slug => requests.del(`/articles/${slug}/favorite`),
  update: article =>
    requests.put(`/articles/${article.slug}`, {
      article: omitSlug(article)
    }),
  create: article =>
    requests.post("/articles", {
      article
    })
};

const Summary = {
  all: date => requests.get(`/routes/summary?date=${date}`)
};

const Trucks = {
  create: truck => requests.post("/trucks", truck),
  all: page => requests.get(`/trucks?${limit(ROWS_PER_PAGE, page)}`),
  allUnpaged: () => requests.get(`/trucks`),
  filter: (ID, page) =>
    requests.get(`/trucks/filter/?id=${ID}&${limit(ROWS_PER_PAGE, page)}`),
  get: ID => requests.get(`/trucks/${ID}`),
  search: (keyword, page) =>
    requests.get(`/trucks?s=${encode(keyword)}&${limit(ROWS_PER_PAGE, page)}`),
  update: (truck, id) => requests.put(`/trucks/${id}`, truck),
  del: ID => requests.del(`/trucks/${ID}`)
};

const Debtors = {
  create: truck => requests.post("/debtors", truck),
  all: page => requests.get(`/debtors?org_id=1&${limit(ROWS_PER_PAGE, page)}`),
  filter: (ID, page) =>
    requests.get(`/debtors/filter/?id=${ID}&${limit(ROWS_PER_PAGE, page)}`),
  get: ID => requests.get(`/debtors/${ID}/?org_id=1`),
  search: (keyword, page) =>
    requests.get(
      `/debtors?org_id=1&s=${encode(keyword)}&${limit(ROWS_PER_PAGE, page)}`
    ),
  update: (truck, id) => requests.put(`/debtors/${id}`, truck),
  del: ID => requests.del(`/debtors/${ID}`)
};

const TruckSettings = {
  create: (settings, ID) => requests.post(`/trucks/${ID}/settings`, settings)
};

const Routes = {
  create: route => requests.post("/routes", route),
  all: page => requests.get(`/routes?${limit(ROWS_PER_PAGE, page)}`),
  allUnpaged: () => requests.get(`/routes`),
  byTrackID: (routeID, page) =>
    requests.get(`/routes/filter/?id=${routeID}&${limit(ROWS_PER_PAGE, page)}`),
  get: id => requests.get(`/routes/${id}`),
  search: (keyword, page) => requests.get(`/routes?s=${encode(keyword)}`),
  update: (route, id) => requests.put(`/routes/${id}`, route),
  del: ID => requests.del(`/routes/${ID}`)
};

const RouteStops = {
  create: (routeStop, route_id) =>
    requests.post(`/routes/${route_id}/stops`, routeStop),
  update: (routeStop, route_id, stop_id) =>
    requests.put(`/routes/${route_id}/stops/${stop_id}`, routeStop),
  all: route_id => requests.get(`/routes/${route_id}/stops/`),
  get: (route_id, stop_id) =>
    requests.get(`/routes/${route_id}/stops/${stop_id}`),
  search: (keyword, page) =>
    requests.get(`/routes?s=${encode(keyword)}&${limit(ROWS_PER_PAGE, page)}`),
  del: (route_id, routeStopID) =>
    requests.del(`/routes/${route_id}/stops/${routeStopID}`)
};

const Invoices = {
  create: invoice =>
    requests.postMultipart(
      "/invoices",
      {
        invoice_date: invoice.invoice_date
      },
      {
        route_id: invoice.route_id
      },
      {
        invoice_file: invoice.invoice_file
      }
      // invoice.invoice_file
    ), //invoice_date, route_id, invoice_file
  all: (date, route_id, page) =>
    requests.get(
      `/invoices/?invoice_date=${date}&route_id=${route_id}&${limit(
        ROWS_PER_PAGE,
        page
      )}`
    ),
  byRouteID: (routeID, page) => requests.get(`/invoices/${routeID}`),
  byInvoiceStatus: (statusID, page) =>
    requests.get(
      `/invoices/filter/?status=${statusID}&${limit(ROWS_PER_PAGE, page)}`
    ),
  get: invoiceID => requests.get(`/invoices/${invoiceID}`),
  search: (keyword, page) =>
    requests.get(
      `/invoices/search?s=${encode(keyword)}&${limit(ROWS_PER_PAGE, page)}`
    ),
  filter: (mode, date, route_id, page) =>
    requests.get(
      `/invoices/?invoice_date=${date}&route_id=${route_id}&payment_mode=${mode}&${limit(
        ROWS_PER_PAGE,
        page
      )}`
    ),
  update: invoice =>
    requests.put(`/invoices/${invoice.invoiceID}`, {
      invoice: omitSlug(invoice)
    }),
  uploadCreditNote: invoice =>
    requests.put(`/invoices/${invoice.invoiceID}`, {
      invoice: omitSlug(invoice)
    }),
  del: invoiceID => requests.del(`/invoices/${invoiceID}`)
};

const SalesReturns = {
  create: sales_return =>
    requests.post("/sales_returns", {
      sales_return
    }),
  all: page => requests.get(`/returns?${limit(ROWS_PER_PAGE, page)}`),
  byRouteID: (routeID, page) =>
    requests.get(
      `/returns/filter/?return_id=${routeID}&${limit(ROWS_PER_PAGE, page)}`
    ),
  byPaymentModeStatus: (mode, page) =>
    requests.get(
      `/returns/?payment_mode=${mode}&${limit(ROWS_PER_PAGE, page)}`
    ),
  filter: (mode, date, route_id, page) =>
    requests.get(
      `/invoices/returns?Invoice_date=${date}&route_id=${route_id}&payment_mode=${mode}&${limit(
        ROWS_PER_PAGE,
        page
      )}`
    ),
  get: returnID => requests.get(`/returns/${returnID}`),
  search: (keyword, page) =>
    requests.get(`/returns?s=${encode(keyword)}&${limit(ROWS_PER_PAGE, page)}`),
  validate: returnID => requests.post(`/returns/validate/`, returnID),
  del: returnID => requests.del(`/sales_returns/${returnID}`)
};

const Users = {
  create: user => requests.post("/delivery_agents", user),
  all: page => requests.get(`/delivery_agents?${limit(ROWS_PER_PAGE, page)}`),
  byTrackID: (id, page) =>
    requests.get(
      `/delivery_agents/filter/?return_id=${id}&${limit(ROWS_PER_PAGE, page)}`
    ),
  bySReturnStatus: (statusID, page) =>
    requests.get(
      `/delivery_agents/filter/?status=${statusID}&${limit(
        ROWS_PER_PAGE,
        page
      )}`
    ),
  get: id => requests.get(`/delivery_agents/${id}`),
  search: (keyword, page) =>
    requests.get(
      `/delivery_agents?s=${encode(keyword)}&${limit(ROWS_PER_PAGE, page)}`
    ),
  update: (user, id) => requests.put(`/delivery_agents/${id}`, user),
  getCreds: id => requests.get(`/delivery_agents/fetch/${id}/auth`),
  createCreds: user =>
    requests.post(`/delivery_agents/${user.id}/auth`, {
      username: user.username,
      password: user.password
    }),
  updateCreds: user =>
    requests.put(`/delivery_agents/${user.id}/auth`, {
      username: user.username,
      password: user.password
    }),
  uploadCreditNote: user =>
    requests.put(`/delivery_agents/${user.id}`, {
      user: omitSlug(user)
    }),
  del: id => requests.del(`/delivery_agents/${id}`)
};

const Profile = {
  get: ID => requests.get(`/profile/${ID}`),
  update: (user, ID) =>
    requests.postMultipart(
      "/profile",
      {
        name: user.name
      },
      {
        email: user.email
      },
      {
        profile_picture: user.photo
      }
    ),
  updatePass: (user, ID) => requests.put(`/profile/change-password/`, user)
};

export default {
  Auth,
  Summary,
  Articles,
  Trucks,
  TruckSettings,
  Routes,
  RouteStops,
  Invoices,
  SalesReturns,
  Debtors,
  Users,
  Profile,
  Tags,
  Organisations,
  setToken: _token => {
    token = _token;
  }
};
