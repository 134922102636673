import React from 'react'

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({ 
    pageTitle: {
        // minHeight: 100,
        backgroundColor: '#FFFFFF'
    },
    titleText : {
        color: '#000000',
        fontSize: '1.5em'
    }
 }));

export default function Title(props) {
    // console.log(props);
    const classes = useStyles();
    const {title} = props;
    return (
        // <Box>
            <div maxwidth="sm">
                <Typography variant="h6" className={classes.titleText} gutterBottom>
                {title}
                </Typography>
            </div>
        // </Box>
    )
}
