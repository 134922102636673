import React, { useEffect } from "react";
import { connect } from "react-redux";
import api from "../../data/api";
import {
  TRUCK_SETTINGS_MODAL_LOADED,
  UPDATE_TRUCK_SETTING_FIELD_EDITOR,
  TRUCK_SETTINGS_SUBMITTED
} from "../../constants/actionTypes";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AutoSuggestBetter from "../common/AutoSuggest/AutoSuggestBetter";

const useStyles = makeStyles(theme => ({
  container: {
    display: "block",
    flexWrap: "wrap",
    width: 450
  },
  textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1)
  },
  btnSubmit: {
    backgroundColor: "#1c3e5d",
    width: "100%",
    color: "#FFFFFF",
    minHeight: 50
  },
  dialogueActions: {
    display: "block",
    width: "100%",
    padding: "10px 24px 30px"
  },
  error_text: {
    fontSize: "0.75rem",
    color: "#f44336"
  }
}));

const mapStateToProps = state => ({
  truck: state.trucks.truck,
  truckroutes: state.common.truckroutes && state.common.truckroutes.data,
  users: state.common.users && state.common.users.data
});

const mapDispatchToProps = dispatch => ({
  onLoad: () => dispatch({ type: TRUCK_SETTINGS_MODAL_LOADED }),
  onUpdateField: (key, value) =>
    dispatch({ type: UPDATE_TRUCK_SETTING_FIELD_EDITOR, key, value }),
  onSubmit: payload => dispatch({ type: TRUCK_SETTINGS_SUBMITTED, payload })
});

const customSelectStyles = {
  control: (base, state) => ({
    ...base,
    padding: "10px"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? "grey" : "white"
  }),
  menu: (provided, state) => ({
    ...provided,
    padding: 5,
    zIndex: 10000,
    background: "#FFFFFF"
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999
  })
};

function TruckSettingsModal(props) {
  const classes = useStyles();

  const [route, setRoute] = React.useState(props.truck.route_name);
  const [agent, setAgent] = React.useState(props.truck.delivery_agent_name);
  const [truckRoutes, setTruckRoutes] = React.useState([]);
  const [truckAgents, setTruckAgents] = React.useState([]);
  const [tillNumberError, setTillNumberError] = React.useState(false);
  const [tillNumberErrorText, setTillNumberErrorText] = React.useState(
    "Enter only digits"
  );
  const [missingRouteError, setMissingRouteError] = React.useState(false);
  const [missingRouteErrorText, setMissingRouteErrorText] = React.useState(
    false
  );
  const [missingAgentError, setMissingAgentError] = React.useState(false);
  const [missingAgentErrorText, setMissingAgentErrorText] = React.useState(
    false
  );
  const validTillNumber = new RegExp(/([+]?\d+$)/);

  const handleRouteChange = ev => {
    const route_ID = ev.value;
    const route_name = ev.label;
    setRoute(route_ID);
    props.onUpdateField("route_id", route_ID);
    props.onUpdateField("route_name", route_name);
    setMissingRouteError(false);
  };
  const handleAgentChange = ev => {
    const agent_ID = ev.value;
    const agent_name = ev.label;
    props.onUpdateField("delivery_agent_id", agent_ID);
    props.onUpdateField("delivery_agent_name", agent_name);
    setAgent(agent_ID);
    setMissingAgentError(false);
  };

  const handleTillNoChange = ev => {
    const val = ev.target.value;
    const validTNumber = new RegExp(/^\d*$/);
    if (!validTNumber.test(val)) {
      setTillNumberError(true);
      setTillNumberErrorText("Please enter only numbers");
      return false;
    }
    props.onUpdateField("till_number", val);
    setTillNumberError(false);
  };

  useEffect(() => {
    //get latest routes data and store locally
    const routes_promise = api.Routes.all();
    routes_promise.then(response => {
      setTruckRoutes(response.data);
    });

    //get latest agents data and store locally
    const agents_promise = api.Users.all();
    agents_promise.then(response => {
      setTruckAgents(response.data);
    });
    props.onLoad();
  }, []);

  const handleSubmitForm = ev => {
    ev.preventDefault();
    let valid = true;
    if (!props.truck.delivery_agent_id) {
      setMissingAgentError(true);
      setMissingAgentErrorText("Please select an agent");
      valid = false;
    } else {
      setMissingAgentError(false);
      setMissingAgentErrorText("");
    }
    if (!props.truck.route_id) {
      setMissingRouteError(true);
      setMissingRouteErrorText("Please select a route");
      valid = false;
    } else {
      setMissingRouteError(false);
      setMissingRouteErrorText("");
    }
    if (!props.truck.till_number) {
      setTillNumberError(true);
      setTillNumberErrorText("Please enter till number");
      valid = false;
    }
    if (!valid) {
      return false;
    }
    if (
      props.truck.till_number &&
      props.truck.route_id &&
      props.truck.delivery_agent_id
    ) {
      if (props.truck.till_number.length > 10) {
        setTillNumberError(true);
        return false;
      }
      const truckSettings = {
        till_number: props.truck.till_number,
        route_id: props.truck.route_id,
        delivery_agent_id: props.truck.delivery_agent_id
      };

      //submit post
      const promise = api.TruckSettings.create(truckSettings, props.truck.id);

      //then get a new list
      props.onSubmit(promise);

      //close modal
      props.handleClose();
    }
  };

  return (
    <Dialog
      open={props.opened}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form className={classes.container} noValidate autoComplete="off">
        <DialogTitle id="form-dialog-title">SET TRUCK SETTINGS</DialogTitle>
        <DialogContent>
          <label>Routes</label>

          <AutoSuggestBetter
            placeholder="Search a route"
            entity="routes"
            defaultValue={{
              value: props.truck.route_id,
              label: props.truck.route_name
            }}
            onOptionSelected={handleRouteChange}
          />
          {missingRouteError ? (
            <label className={classes.error_text}>
              {missingRouteErrorText}
            </label>
          ) : (
            ""
          )}
          <br />
          <label>Agents</label>

          <AutoSuggestBetter
            placeholder="Search an agent"
            entity="agents"
            defaultValue={{
              value: props.truck.delivery_agent_id,
              label: props.truck.delivery_agent_name
            }}
            onOptionSelected={handleAgentChange}
          />
          {missingAgentError ? (
            <label className={classes.error_text}>
              {missingAgentErrorText}
            </label>
          ) : (
            ""
          )}

          <br />
          <TextField
            id="till_number"
            label="TILL NUMBER"
            placeholder="Input number"
            fullWidth
            margin="normal"
            style={{ marginBottom: 8 }}
            variant="outlined"
            inputProps={{ maxLength: 8 }}
            helperText={tillNumberError ? tillNumberErrorText : ""}
            value={props.truck.till_number}
            onChange={handleTillNoChange}
            error={tillNumberError}
          />
        </DialogContent>
        <DialogActions className={classes.dialogueActions}>
          <Button
            fullWidth
            onClick={handleSubmitForm}
            className={classes.btnSubmit}
          >
            SUBMIT
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckSettingsModal);
