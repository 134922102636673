import {
    SET_USERS_PAGE,
    USER_LIST_PAGE_LOADED,
    USER_LIST_APPLY_FILTER,
    USER_SUBMITTED,
    USER_LIST_PAGE_UNLOADED,
    USER_VIEW_PAGE_LOADED,
    USER_VIEW_PAGE_UNLOADED,
    // ASYNC_START,
    ACTIVATE_USER,
    DEACTIVATE_USER,
    DELETE_USER,
    UPDATE_FIELD_D_AGENT,
    USER_AUTH_SUBMITTED,
    USER_EDITOR_MODAL_LOADED,
    SEARCH_USERS_SUBMITTED,
    USER_AUTH_MODAL_LOADED,
    UPDATE_USER_AUTH_FIELD_EDITOR,
  } from '../constants/actionTypes';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case USER_LIST_PAGE_LOADED:
        return {
          ...state,
          pager: action.pager,
          users: action.payload ? action.payload.data : null,
          usersCount: action.payload ? action.payload.meta.pagination.total : null,
          pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: 0,
          search_results: false,
        };
      case SEARCH_USERS_SUBMITTED: {
        return {
          ...state,
          pager: action.pager,
          users: action.payload ? action.payload.data : null,
          usersCount: action.payload ? action.payload.meta.pagination.total : null,
          pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: 0,
          search_results: action.payload.data ? true : false,
        };
      }
      case SET_USERS_PAGE:
        return {
          ...state,
          users: action.payload ? action.payload.data : null,
          usersCount: action.payload ? action.payload.usersCount : null,
          pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: action.page
        };
      case USER_LIST_APPLY_FILTER:
        return {
          ...state,
          pager: action.pager,
          users: action.payload.users,
          usersCount: action.payload.usersCount,
          currentPage: 0,
          search_results: false
        };
      
      case ACTIVATE_USER:
      case DEACTIVATE_USER:
        return {
          ...state,
          users: state.users.map(user => {
            if(action.payload  && user.id===action.payload.user.id) {
              return {
                ...user,
                activated: action.payload ? action.payload.user : null
              }
            }  
            return null;          
          })
        }
      case USER_EDITOR_MODAL_LOADED:
        return {
          ...state,
          // id: action.payload ? action.payload.id : '',
          // name: action.payload ? action.payload.name : '',
          // phone_number: action.payload ? action.payload.phone_number : '',
          user: action.mode && action.mode==="create" ? null : {...state.user},
          responseStatus: null,
          responseMessage: null,
        };
        
      case UPDATE_FIELD_D_AGENT:
        return { 
          ...state, 
          user : {
            ...state.user,
            [action.key]: action.value
          } 
        };
      case USER_SUBMITTED:
        return {
          ...state,
          // users: action.payload ? action.payload.data : null,
          // usersCount: action.payload ? action.payload.meta.pagination.total : null,
          inProgress: null,
          // errors: action.error ? action.payload.errors : null,
          // name : null,
          // phone_number: null,
          responseStatus:action.payload ? action.payload.Response.status : null,
          responseMessage:action.payload ? action.payload.Response.message : null
        };
      case USER_LIST_PAGE_UNLOADED:
        return {
          ...state,
          responseStatus: null,
          responseMessage: null,
        };
      case USER_VIEW_PAGE_LOADED:
        return {
          ...state,
          user : {
            id: action.payload ? action.payload.id : null,
            name: action.payload ? action.payload.name : null,
            phone_number: action.payload ? action.payload.phone_number : null,
            id_number: action.payload ? action.payload.id_number : null,
          }
        };
      case USER_AUTH_MODAL_LOADED:
        return {
          ...state,
          responseStatus: null,
          responseMessage: null,
          user: {
            ...state.user,
            username: action.payload ? action.payload.username : null,
            password: action.payload ? action.payload.password : null,
          }
        };
      case UPDATE_USER_AUTH_FIELD_EDITOR:
        return { 
          ...state, 
          user: {
            ...state.user,
            [action.key]: action.value
          } 
        };
        
      case USER_AUTH_SUBMITTED:
        return {
          ...state,
          // inProgress: null,
          // errors: action.error ? action.payload.errors : null,
          // username : null,
          // password: null,
          inProgress: null,
          responseStatus:action.payload ? action.payload.Response.status : null,
          responseMessage:action.payload ? action.payload.Response.message : null,
        };
      
      
      case USER_VIEW_PAGE_UNLOADED:
        return {
          ...state,
          user: null,
          responseStatus: null,
          responseMessage: null,
        };
        
      case DELETE_USER:
        const ID = action.userID
        return {
          ...state,
          users: state.users.filter(user => user.id !== ID),
          responseStatus:action.payload ? action.payload.Response.status : null,
          responseMessage:action.payload ? action.payload.Response.message : null,
        };
        
      default:
        return state;
    }
  };
  