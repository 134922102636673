//Home
export const APP_LOAD = 'APP_LOAD';
export const LOAD_TOKEN = 'LOAD_TOKEN';
export const REDIRECT = 'REDIRECT';
export const TOGGLE_SUMMARY = 'TOGGLE_SUMMARY';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const LOAD_AVATAR = 'LOAD_AVATAR';
export const LOAD_ORGANISATIONS = 'LOAD_ORGANISATIONS';
export const UPDATE_SEARCH_FIELD = 'UPDATE_SEARCH_FIELD';
export const CURRENT_MODULE_LOADED = 'CURRENT_MODULE_LOADED';
export const LOAD_SUMMARY = 'LOAD_SUMMARY';
export const LOAD_SUMMARIES = 'LOAD_SUMMARIES';

//Auth
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';

export const ARTICLE_PAGE_LOADED = 'ARTICLE_PAGE_LOADED';
export const ARTICLE_SUBMITTED = 'ARTICLE_SUBMITTED';
export const ARTICLE_FAVORITED = 'ARTICLE_FAVORITED';
export const ARTICLE_UNFAVORITED = 'ARTICLE_UNFAVORITED';
export const ARTICLE_PAGE_UNLOADED = 'ARTICLE_PAGE_UNLOADED';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';

//Trucks
export const SET_TRUCKS_PAGE = 'SET_TRUCKS_PAGE';
export const TRUCK_LIST_PAGE_LOADED = 'TRUCK_LIST_PAGE_LOADED';
export const TRUCK_LIST_APPLY_FILTER = 'TRUCK_LIST_APPLY_FILTER';
export const TRUCK_VIEW_PAGE_LOADED = 'TRUCK_VIEW_PAGE_LOADED';
export const TRUCK_EDITOR_MODAL_LOADED = 'TRUCK_EDITOR_MODAL_LOADED';
export const TRUCK_EDITOR_MODAL_UNLOADED = 'TRUCK_EDITOR_MODAL_UNLOADED';
export const TRUCK_SUBMIT_FOR_APPROVAL = 'TRUCK_SUBMIT_FOR_APPROVAL';
export const TRUCK_SUBMITTED = 'TRUCK_SUBMITTED';
export const DELETE_TRUCK = 'DELETE_TRUCK';
export const TRUCK_LIST_PAGE_UNLOADED = 'TRUCK_LIST_PAGE_UNLOADED';
export const TRUCK_VIEW_PAGE_UNLOADED = 'TRUCK_VIEW_PAGE_UNLOADED';
export const TRUCK_SETTINGS_MODAL_LOADED = 'TRUCK_SETTINGS_MODAL_LOADED';
export const UPDATE_TRUCK_SETTING_FIELD_EDITOR = 'UPDATE_TRUCK_SETTING_FIELD_EDITOR';
export const TRUCK_SETTINGS_SUBMITTED = 'TRUCK_SETTINGS_SUBMITTED';
export const TRUCK_SETTINGS_MODAL_UNLOADED = 'TRUCK_SETTINGS_MODAL_UNLOADED';
export const SEARCH_TRUCKS_SUBMITTED = 'SEARCH_TRUCKS_SUBMITTED';
export const CLEAR_TRUCK_SUBMIT_RESPONSE = 'CLEAR_TRUCK_SUBMIT_RESPONSE';

//Routes
export const ROUTE_LIST_PAGE_LOADED = 'ROUTE_LIST_PAGE_LOADED';
export const ROUTE_LIST_APPLY_FILTER = 'ROUTE_LIST_APPLY_FILTER';
export const ROUTE_VIEW_PAGE_LOADED = 'ROUTE_VIEW_PAGE_LOADED';
export const ROUTE_EDITOR_MODAL_LOADED = 'ROUTE_EDITOR_MODAL_LOADED';
export const ROUTE_EDITOR_MODAL_UNLOADED = 'ROUTE_EDITOR_MODAL_UNLOADED';
export const UPDATE_FIELD_ROUTE = 'UPDATE_FIELD_ROUTE';
export const ROUTE_EDIT_PAGE_LOADED = 'ROUTE_EDIT_PAGE_LOADED';
export const TRUCK_ROUTE_SUBMITTED = 'TRUCK_ROUTE_SUBMITTED';
export const DELETE_ROUTE = 'DELETE_ROUTE';
export const ROUTE_LIST_PAGE_UNLOADED = 'ROUTE_LIST_PAGE_UNLOADED';
export const ROUTE_VIEW_PAGE_UNLOADED = 'ROUTE_VIEW_PAGE_UNLOADED';
export const SET_ROUTES_PAGE = 'SET_ROUTES_PAGE';
export const SEARCH_ROUTES_SUBMITTED = 'SEARCH_ROUTES_SUBMITTED';
export const CLEAR_ROUTE_SUBMIT_RESPONSE = 'CLEAR_ROUTE_SUBMIT_RESPONSE';

//Routes stops
export const ROUTE_STOPS_LIST_LOADED = 'ROUTE_STOPS_LIST_LOADED';
export const ROUTE_STOPS_EDITOR_MODAL_LOADED = 'ROUTE_STOPS_EDITOR_MODAL_LOADED';
export const UPDATE_FIELD_ROUTE_STOP = 'UPDATE_FIELD_ROUTE_STOP';
export const TRUCK_ROUTE_STOP_SUBMITTED = 'TRUCK_ROUTE_STOP_SUBMITTED';
export const ROUTE_STOP_EDITOR_MODAL_UNLOADED = 'ROUTE_STOP_EDITOR_MODAL_UNLOADED';
export const DELETE_ROUTE_STOP = 'DELETE_ROUTE_STOP';

//Invoices
export const INVOICE_LIST_PAGE_LOADED = 'INVOICE_LIST_PAGE_LOADED';
export const INVOICE_LIST_APPLY_ROUTE_FILTER = 'INVOICE_LIST_APPLY_ROUTE_FILTER';
export const INVOICE_LIST_APPLY_STATUS_FILTER = 'INVOICE_LIST_APPLY_STATUS_FILTER';
export const INVOICE_VIEW_PAGE_LOADED = 'INVOICE_VIEW_PAGE_LOADED';
export const ADD_INVOICE_MODAL_LOADED = 'ADD_INVOICE_MODAL_LOADED';
export const INVOICE_SUBMITTED = 'INVOICE_SUBMITTED';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const INVOICE_LIST_PAGE_UNLOADED = 'INVOICE_LIST_PAGE_UNLOADED';
export const INVOICE_VIEW_PAGE_UNLOADED = 'INVOICE_VIEW_PAGE_UNLOADED';
export const SET_INVOICES_PAGE = 'SET_INVOICES_PAGE';
export const SEARCH_INVOICES_SUBMITTED = 'SEARCH_INVOICES_SUBMITTED';
export const INVOICE_LIST_FILTER_BY_PAYMENT_MODE = 'INVOICE_LIST_FILTER_BY_PAYMENT_MODE';

//Sales returns
export const SET_SALES_RETURNS_PAGE = 'SET_SALES_RETURNS_PAGE';
export const SALES_RETURNS_LIST_PAGE_LOADED = 'SALES_RETURNS_LIST_PAGE_LOADED';
export const SALES_RETURNS_LIST_APPLY_FILTER = 'SALES_RETURNS_LIST_APPLY_FILTER';
export const SALES_RETURNS_LIST_PAGE_UNLOADED = 'SALES_RETURNS_LIST_PAGE_UNLOADED';
export const SALES_RETURNS_VIEW_PAGE_LOADED = 'SALES_RETURNS_VIEW_PAGE_LOADED';
export const SALES_RETURNS_VIEW_PAGE_UNLOADED = 'SALES_RETURNS_VIEW_PAGE_UNLOADED';
export const ADD_SALES_RETURNS_MODAL_LOADED = 'ADD_SALES_RETURNS_MODAL_LOADED';
export const DELETE_SALES_RETURNS = 'DELETE_SALES_RETURNS';
export const SEARCH_SALES_RETURNS_SUBMITTED = 'SEARCH_SALES_RETURNS_SUBMITTED';
export const SALES_RETURN_SUBMITTED = 'SALES_RETURN_SUBMITTED';
export const SALES_RETURN_VALIDATED = 'SALES_RETURN_VALIDATED';
export const SALES_RETURN_LIST_FILTER_BY_PAYMENT_MODE = 'SALES_RETURN_LIST_FILTER_BY_PAYMENT_MODE';

//Debtors
export const SET_DEBTORS_PAGE = 'SET_DEBTORS_PAGE';
export const DEBTOR_LIST_PAGE_LOADED = 'DEBTOR_LIST_PAGE_LOADED';
export const DEBTOR_LIST_APPLY_FILTER = 'DEBTOR_LIST_APPLY_FILTER';
export const DEBTOR_VIEW_PAGE_LOADED = 'DEBTOR_VIEW_PAGE_LOADED';
export const DEBTOR_EDITOR_MODAL_LOADED = 'DEBTOR_EDITOR_MODAL_LOADED';
export const DEBTOR_EDITOR_MODAL_UNLOADED = 'DEBTOR_EDITOR_MODAL_UNLOADED';
export const DEBTOR_SUBMIT_FOR_APPROVAL = 'DEBTOR_SUBMIT_FOR_APPROVAL';
export const DEBTOR_SUBMITTED = 'DEBTOR_SUBMITTED';
export const DELETE_DEBTOR = 'DELETE_DEBTOR';
export const DEBTOR_LIST_PAGE_UNLOADED = 'DEBTOR_LIST_PAGE_UNLOADED';
export const DEBTOR_VIEW_PAGE_UNLOADED = 'DEBTOR_VIEW_PAGE_UNLOADED';
export const DEBTOR_SETTINGS_MODAL_LOADED = 'DEBTOR_SETTINGS_MODAL_LOADED';
export const UPDATE_DEBTOR_SETTING_FIELD_EDITOR = 'UPDATE_DEBTOR_SETTING_FIELD_EDITOR';
export const DEBTOR_SETTINGS_SUBMITTED = 'DEBTOR_SETTINGS_SUBMITTED';
export const DEBTOR_SETTINGS_MODAL_UNLOADED = 'DEBTOR_SETTINGS_MODAL_UNLOADED';
export const SEARCH_DEBTORS_SUBMITTED = 'SEARCH_DEBTORS_SUBMITTED';
export const CLEAR_DEBTOR_SUBMIT_RESPONSE = 'CLEAR_DEBTOR_SUBMIT_RESPONSE';

//Users
export const USER_LIST_PAGE_LOADED = 'USER_LIST_PAGE_LOADED';
export const USER_LIST_APPLY_FILTER = 'USER_LIST_APPLY_FILTER';
export const USER_VIEW_PAGE_LOADED = 'USER_VIEW_PAGE_LOADED';
export const USER_EDITOR_MODAL_LOADED = 'USER_EDITOR_MODAL_LOADED';
export const USER_EDITOR_MODAL_UNLOADED = 'USER_EDITOR_MODAL_UNLOADED';
export const USER_SUBMITTED = 'USER_SUBMITTED';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const USER_LIST_PAGE_UNLOADED = 'USER_LIST_PAGE_UNLOADED';
export const USER_VIEW_PAGE_UNLOADED = 'USER_VIEW_PAGE_UNLOADED';
export const USER_AUTH_MODAL_LOADED = 'USER_AUTH_MODAL_LOADED';
export const UPDATE_USER_AUTH_FIELD_EDITOR = 'UPDATE_USER_AUTH_FIELD_EDITOR';
export const USER_AUTH_SUBMITTED = 'USER_AUTH_SUBMITTED';
export const USER_AUTH_MODAL_UNLOADED = 'USER_AUTH_MODAL_UNLOADED';
export const SET_USERS_PAGE = 'SET_USERS_PAGE';
export const SEARCH_USERS_SUBMITTED = 'SEARCH_USERS_SUBMITTED';

//Profile
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const PROFILE_EDITOR_MODAL_LOADED = 'PROFILE_EDITOR_MODAL_LOADED';
export const UPDATE_FIELD_PROFILE = 'UPDATE_FIELD_PROFILE';
export const PROFILE_SUBMITTED = 'PROFILE_SUBMITTED';
export const PROFILE_EDITOR_DIALOG_UNLOADED = 'PROFILE_EDITOR_DIALOG_UNLOADED';
export const PROFILE_VIEW_PAGE_UNLOADED = 'PROFILE_VIEW_PAGE_UNLOADED';

//common
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const SWITCH_ORG_ACCOUNT = 'SWITCH_ORG_ACCOUNT';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const SET_PAGE = 'SET_PAGE';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const UPDATE_FIELD_D_AGENT = 'UPDATE_FIELD_D_AGENT';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const PROFILE_FAVORITES_PAGE_UNLOADED = 'PROFILE_FAVORITES_PAGE_UNLOADED';
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED';