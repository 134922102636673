import React, { useEffect } from "react";
import {
  SALES_RETURNS_LIST_PAGE_LOADED,
  SALES_RETURNS_LIST_APPLY_FILTER,
  CURRENT_MODULE_LOADED,
  SET_SALES_RETURNS_PAGE,
  SALES_RETURN_LIST_FILTER_BY_PAYMENT_MODE,
  SALES_RETURN_VALIDATED
} from "../../constants/actionTypes";
import { connect } from "react-redux";
import api from "../../data/api";
import Filter from "../common/listRow/listTitle/components/Filter";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ShowSnackbar from "../common/ShowSnackbar.jsx";

//Theme
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import CreditNoteModal from "./CreditNoteModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import AutoSuggest from "../common/AutoSuggest/AutoSuggest";

import Pagination from "react-mui-pagination"; //https://github.com/bnabriss/react-mui-pagination

const useStyles = makeStyles(theme => ({
  list_root: {
    minHeight: 300
  },
  listheader: {
    backgroundColor: "#EEEEEE",
    fontSize: 16,
    "& tr th": {
      fontSize: 16
    }
  },
  rowheader: {
    height: 60
  },
  seeMore: {
    marginTop: theme.spacing(3)
  },
  btnAddCreditNote: {
    margin: theme.spacing(1),
    marginTop: 5,
    backgroundColor: "#1c3e5d",
    width: 300,
    color: "#FFFFFF",
    height: 38,
    "& span": {
      fontSize: 16
    }
  },
  btnAdd: {
    backgroundColor: "#db3332",
    width: 200,
    height: 36,
    "& span": {
      fontSize: 16
    }
  },
  btnEdit: {
    backgroundColor: "#1c3e5d",
    width: 171,
    color: "#FFFFFF"
  },
  btnRemove: {
    color: "#1c3e5d",
    border: "1px solid #1c3e5d",
    width: 171,
    fontSize: 16
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  pageTitle: {
    padding: 15,
    backgroundColor: "#FFFFFF",
    maxWidth: "100%"
  },
  txtRight: {
    textAlign: "right"
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30
  },
  btnView: {
    backgroundColor: "#1c3e5d",
    width: "100%",
    minWidth: 100,
    boxSizing: "border-box",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontWeight: 500,
    borderRadius: 4,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    position: "relative",
    alignItems: "center",
    userSelect: "none",
    margin: 0,
    display: "inline-flex",
    verticalAlign: "middle",
    color: "#FFFFFF",
    justifyContent: "center",
    textDecoration: "none",
    padding: "8px",
    fontSize: 16
  },
  loading: {
    minHeight: 250,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff"
  },
  proggressRoot: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    },
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    minHeight: "30vh"
  },
  selectRoute: {
    maxWidth: 200,
    "& label": {
      transform: "translate(14px, 13px) scale(1)"
    },
    "& div": {
      "& div": {
        height: 40
      }
    }
  },

  selectOther: {
    maxWidth: 250,
    marginTop: 5,
    marginLeft: 10,
    textAlign: "justify",
    "& label": {
      transform: "translate(14px, 13px) scale(1)"
    },
    "& div": {
      "& div": {
        padding: "10px 0 10px 10px"
      }
    }
  },
  tableBody: {
    "& tr td": {
      fontSize: 16
    }
  }
}));
const customSelectStyles = {
  control: (base, state) => ({
    ...base,
    padding: "1px"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? "grey" : "white"
  }),
  menu: (provided, state) => ({
    ...provided,
    padding: 5,
    zIndex: 10000,
    background: "#FFFFFF"
  })
};

const mapStateToProps = state => ({
  ...state.salesreturns,
  // trucks: state.trucks ? state.trucks.trucks : null,
  truckroutes: state.common.truckroutes ? state.common.truckroutes.data : null
});

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, pager) =>
    dispatch({ type: SALES_RETURNS_LIST_PAGE_LOADED, payload }),
  onOnSetModule: () =>
    dispatch({ type: CURRENT_MODULE_LOADED, module: "SALESRETURNS" }),
  onSetRouteFilter: (payload, page) =>
    dispatch({ type: SALES_RETURNS_LIST_APPLY_FILTER, payload, page }),
  onSetFilterByPaymentMode: (payload, page) =>
    dispatch({ type: SALES_RETURN_LIST_FILTER_BY_PAYMENT_MODE, payload, page }),
  onValidate: promise =>
    dispatch({ type: SALES_RETURN_VALIDATED, payload: promise }),
  onSetPage: (payload, page) =>
    dispatch({ type: SET_SALES_RETURNS_PAGE, payload, page })
  // onUnload: () =>
  //   dispatch({  type: ROUTE_LIST_PAGE_UNLOADED })
});

function ListSalesReturns(props) {
  const classes = useStyles();

  const { salesreturns } = props;

  const [selectedRoute, setSelectedRoute] = React.useState(
    props.truckroutes && props.truckroutes.length
      ? props.truckroutes[0].id
      : null
  );
  const [filterBy, setSelectedFilter] = React.useState(10);
  const [selectedPaymentMode, setSelectedPaymentMode] = React.useState("cash");
  const [openCreditNote, setCreditNoteOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  let mySelectedDate = mm + "-" + dd + "-" + yyyy;
  const [selectedDate, setSelectedDate] = React.useState(mySelectedDate);
  const [invoiceIDs, setInvoiceIDs] = React.useState(null);

  props.onOnSetModule();

  const handleCreditNoteLaunch = () => {
    setCreditNoteOpen(true);
  };

  const handleCreditNoteClose = () => {
    setCreditNoteOpen(false);
  };

  const handleChangePage = newPage => {
    setPage(newPage);
    props.onSetPage(api.SalesReturns.all(newPage), newPage);
  };

  const handleFilterRoute = event => {
    const val = event.value;
    const currentDate = Math.floor(Date.now() / 1000);
    setSelectedRoute(val);
    props.onSetRouteFilter(
      api.SalesReturns.filter(selectedPaymentMode, currentDate, val, page),
      null
    );
  };

  const handleFilterByOther = event => {
    const val = event.target.value;
    setSelectedFilter(val);
  };

  const formattedDate = date => {
    const splitDate = date.split("-");
    const m = splitDate[0];
    const d = splitDate[1];
    const y = splitDate[2];
    return d + "-" + m + "-" + y;
  };

  const handleFilterByPaymentMode = event => {
    const mode = event.target.value;
    // console.log('mode', mode);
    setSelectedPaymentMode(mode);
    const promise = api.SalesReturns.byPaymentModeStatus(mode, page);
    props.onSetFilterByPaymentMode(promise, page);
  };

  const handleValidateSReturn = () => {
    if (salesreturns && salesreturns.length > 0) {
      const invoiceIDs = salesreturns.map(item => {
        return item.invoice_id;
      });
      setInvoiceIDs(invoiceIDs);
      const promise = api.SalesReturns.validate({ invoices: invoiceIDs });
      props.onValidate(promise);
    }
  };

  let page_total = props.pagination ? props.pagination.total : 0;

  return (
    <React.Fragment>
      <div className={classes.list_root}>
        {props.responseStatus ? (
          <ShowSnackbar
            opened={props.responseStatus ? true : false}
            variant={props.responseStatus ? props.responseStatus : null}
            message={
              props.responseMessage !== "" ? props.responseMessage : false
            }
          />
        ) : (
          ""
        )}
        <Container className={classes.pageTitle}>
          <Box justifyContent="center" display="flex" alignItems="center">
            <Grid container display="flex" alignItems="center">
              <Grid item xs={4}>
                <Box sm={6} xs={12} style={{ "flex-grow": "1" }}>
                  {/* <TextField
                                        id="delivery_agent"
                                        select
                                        fullWidth
                                        label="SELECT ROUTE"
                                        className={classes.selectRoute}
                                        value={firstRoute}
                                        onChange={handleFilterRoute}
                                        SelectProps={{
                                            MenuProps: {
                                            className: classes.menu,
                                            },
                                        }}
                                        margin="normal"
                                        style={{ marginBottom: 8 }}
                                        variant="outlined"
                                        >
                                        {props.truckroutes ? props.truckroutes.map((option, index) => (
                                            <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                            </MenuItem>
                                        )) : null}
                                    </TextField> */}
                  <div style={{ marginTop: "-3px" }}>
                    <AutoSuggest
                      placeholder="Select a route"
                      onOptionSelected={handleFilterRoute}
                      style={customSelectStyles}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={8} className={classes.txtRight} display="flex">
                <Button
                  variant="contained"
                  size="small"
                  className={classes.btnAddCreditNote}
                  onClick={handleValidateSReturn}
                >
                  VALIDATE SALES RETURNS
                </Button>

                {openCreditNote ? (
                  <CreditNoteModal
                    opened={openCreditNote}
                    handleClose={handleCreditNoteClose}
                  />
                ) : (
                  ""
                )}

                <TextField
                  id="delivery_agent"
                  select
                  fullWidth
                  label="FILTER"
                  className={classes.selectOther}
                  onChange={handleFilterByPaymentMode}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  margin="normal"
                  style={{ marginBottom: 8 }}
                  variant="outlined"
                >
                  <MenuItem>
                    <em>SELECT</em>
                  </MenuItem>
                  <MenuItem value="cash">CASH</MenuItem>
                  <MenuItem value="credit">BANK CREDIT</MenuItem>
                  <MenuItem value="kcb_jaza_duka">KCB JAZA DUKA</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Table size="small">
          <TableHead className={classes.listheader}>
            <TableRow className={classes.rowheader}>
              <TableCell>INVOICE NUMBER</TableCell>
              <TableCell>SHOP NAME</TableCell>
              <TableCell>NO. OF ITEMS</TableCell>
              <TableCell>AMOUNT</TableCell>
              <TableCell align="center">ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {salesreturns
              ? salesreturns.map(i => (
                  <TableRow key={i.invoice_id}>
                    <TableCell>
                      <Checkbox></Checkbox> {i.invoice_id}
                    </TableCell>
                    <TableCell>{i.shop.name}</TableCell>
                    <TableCell>{i.items.length}</TableCell>
                    <TableCell>{i.items.amount}</TableCell>
                    <TableCell align="right">
                      <Link
                        variant="contained"
                        size="small"
                        className={classes.btnView}
                        to={`/salesreturns/${i.invoice_id}`}
                      >
                        VIEW{" "}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              : ""}
          </TableBody>
        </Table>
        {salesreturns && salesreturns.length ? (
          ""
        ) : (
          <div className={classes.loading}> No items are here... yet. </div>
        )}
        {salesreturns && salesreturns.length ? (
          <Pagination
            page={page}
            setPage={handleChangePage}
            total={page_total}
            activeProps={{ style: { backgroundColor: "#db3332" } }}
            className={classes.pagination}
          />
        ) : (
          <div></div>
        )}
      </div>
    </React.Fragment>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ListSalesReturns);
