import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { LOAD_ORGANISATIONS, SWITCH_ORG_ACCOUNT } from '../../../../../constants/actionTypes';
import api from '../../../../../data/api'
import SwitchAccountsButton from './SwitchAccountsButton';
import AccountsList from './AccountsList';

const mapStateToProps = state => ({
    organisations: state.common.organisations
});
  
const mapDispatchToProps = dispatch => ({
    onLoad: (payload) =>
      dispatch({ type: LOAD_ORGANISATIONS, payload }),
    onSwitchOrgAccount: (payload) =>
      dispatch({ type: SWITCH_ORG_ACCOUNT, payload }),
});


function SwitchAccounts(props) {
    useEffect(() => {
        props.onLoad(api.Organisations.all());
    }, []);

    const [accountsAnchorEl, setAccountsAnchorEl] = React.useState(null);
    const [org_id, setOrgID] = React.useState(props!=null && props.organisations!=null ? props.organisations.data[0].id : 0);
    
    const handleShowAccountsList = event => {
      setAccountsAnchorEl(event.currentTarget);
    };
  
    const handleSwitchAccountsList = val => {
        setOrgID(val);
        props.onSwitchOrgAccount(val);
    };
  
    const handleCloseAccountsList = () => {
      setAccountsAnchorEl(null);
    };

    // console.log('AccountsList props', props);

    return (
        <React.Fragment>
            <SwitchAccountsButton 
                onShowAccounts={handleShowAccountsList}/>
            <AccountsList
                onHideAccounts={handleCloseAccountsList}
                onSwitchAccounts={handleSwitchAccountsList}
                anchorEl={accountsAnchorEl}
                organisations={props!=null && props.organisations!=null ? props.organisations : null } >
              </AccountsList>
        </React.Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(SwitchAccounts);
