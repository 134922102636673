import React from 'react'
import api from '../../../data/api'
import Title from '../../common/listRow/listTitle/components/Title';
import { Link } from 'react-router-dom';
import { CURRENT_MODULE_LOADED, ACTIVATE_USER, DEACTIVATE_USER, USER_LIST_PAGE_UNLOADED, DELETE_USER, SET_USERS_PAGE } from '../../../constants/actionTypes';
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShowSnackbar from '../../common/ShowSnackbar.jsx';

import Pagination from 'react-mui-pagination'; //https://github.com/bnabriss/react-mui-pagination
import UserEditorModal from './UserEditorModal';
import IconButton from '@material-ui/core/IconButton';
import LensIcon from '@material-ui/icons/Lens';

const useStyles = makeStyles(theme => ({
    list_root: {
        minHeight: 300
    },
    listheader:{
        backgroundColor: '#EEEEEE',
        fontSize: 16, 
        "& tr th": {
            fontSize: 16,
        }
    },
    rowheader: {
        height: 60
    },
    seeMore: {
      marginTop: theme.spacing(3),
    },
    btnAddCreditNote: {
        margin: theme.spacing(1),
        backgroundColor: '#1c3e5d',
        width: 200,
        color: '#FFFFFF'
    },
    btnAdd: {
        backgroundColor: '#db3332',
        width: 200,
        height: 36, 
        "& span": {
            fontSize: 16
        }
    },
    btnEdit: {
        backgroundColor: '#1c3e5d',
        width: 171,
        color: '#FFFFFF'
    },
    btnRemove: {
        color: '#1c3e5d',
        border: '1px solid #1c3e5d',
        width: 171,
        fontSize: 16,
        "& span": {
            fontSize: 16
        }
    },
    btnIcon: {
        color: '#1c3e5d',
        // border: '1px solid #1c3e5d'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    pageTitle: {
        padding: 15,
        backgroundColor: '#FFFFFF',
        maxWidth: '100%'
    },
    selectTruck: {
        maxWidth: 200
    },
    txtRight: {
        textAlign: "right"
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
    },
    btnView : {
        backgroundColor: '#1c3e5d',
        width: 171,
        boxSizing: 'border-box',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontWeight: 500,
        borderRadius: 4,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        margin: 0,
        display: 'inline-flex',
        verticalAlign: 'middle',
        color: '#FFFFFF',
        justifyContent: 'center',
        textDecoration: 'none',
        padding: '8px',
        fontSize: 16,
        "&:hover": {
            backgroundColor: '#cccccc'
        }
    },
    loading: {
      minHeight: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fff'
    },
    proggressRoot: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        minHeight: '30vh',
    },
    tableBody: {
        "& tr td": {
            fontSize: 16,
            padding: '15px 10px'
        }
    },
  }));

// const {users} = mockData.data;

// const cleanUsers = [...users];

const mapStateToProps = state => ({
    ...state.users
});
  
const mapDispatchToProps = dispatch => ({
    onSetPage: (payload, page) => 
      dispatch({ type: SET_USERS_PAGE, payload, page }),
    onOnSetModule: () => 
      dispatch({ type: CURRENT_MODULE_LOADED, module:'USERS' }),
    activateUser: () =>
      dispatch({  type: ACTIVATE_USER }),
    deActivateUser: () =>
      dispatch({  type: DEACTIVATE_USER }),
    deleteUser: (userID, payload) =>
      dispatch({  type: DELETE_USER, userID, payload }),
    onUnload: () =>
      dispatch({  type: USER_LIST_PAGE_UNLOADED })
});

// const usersMenu = users.map(user => {
//     return {
//         user_id: user.user_id,
//         title: user.id_no
//     }
// });

function ListUsers(props) {
    
    const classes = useStyles();  
    
    props.onOnSetModule();

    const [modalOpened, setModalOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);

    const handleUserEditorLaunch = () => {
        setModalOpen(true);
    };
    const handleUserEditorClose = () => {
        setModalOpen(false);
    };

    const MySwal = withReactContent(Swal);
    const handleRemoveUser = (val) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: 'Confirm delete',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            
        }).then((result) => {
            if (result.value) {
                props.deleteUser(val, api.Users.del(val));;
            }
        })
    }

    const handleChangeUserStatus = (val, status) => {
        const mStatus = status === 0 ? 'disable' : 'enable';
        MySwal.fire({
            title: 'Are you sure?',
            text: 'Confirm ' +mStatus + ' user',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!',
            
        }).then((result) => {
            //TODO
        })
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.onSetPage(api.Users.all(newPage), newPage);
    };

    const users = props.users;
    if (!props.users) {
        return ( <div className={classes.proggressRoot}><CircularProgress size={24} thickness={4} /></div> );
    }
    
    if (props.users.length === 0) {
        return (
          <div className={classes.loading}>
            No agents are here... yet.
          </div>
        );
    }

    let page_total = props.pagination ? props.pagination.total : 0;

    return (
        <React.Fragment>
            <div  className={classes.list_root}>
                <Container className={classes.pageTitle}>
                    <Box
                        justifyContent="center"
                        display="flex"
                        alignItems="center"
                    >
                        <Grid container display="flex" alignItems="center">
                            <Grid item xs={6}>
                                <Title title="DELIVERY AGENTS"></Title>
                            </Grid>
                            <Grid item xs={6}  className={classes.txtRight}>
                                {/* <TextField
                                    id="delivery_agent"
                                    select
                                    fullWidth
                                    label="Filter by ID"
                                    className={classes.selectTruck}
                                    value=""
                                    onChange={handleFilterUsers}
                                    SelectProps={{
                                        MenuProps: {
                                        className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    style={{ marginBottom: 8 }}
                                    variant="outlined"
                                    >
                                    {usersMenu.map(option => (
                                        <MenuItem key={option.user_id} value={option.user_id}>
                                        {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField> */}
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                {props.responseStatus ? 
                    <ShowSnackbar 
                        opened={props.responseStatus ? true : false}
                        variant={props.responseStatus ? props.responseStatus : null}
                        message={props.responseMessage ? props.responseMessage : false} />
                    : ''}
                <Table size="small">
                    <TableHead className={classes.listheader}>
                        <TableRow className={classes.rowheader}>
                            <TableCell>AGENT NAME</TableCell>
                            <TableCell>PHONE NUMBER</TableCell>
                            <TableCell>ID NUMBER</TableCell>
                            {/* <TableCell>PASS CODE</TableCell>
                            <TableCell>ENABLE AGENT</TableCell> */}
                            <TableCell colSpan={2} align="right">
                                <Button 
                                    variant="contained" 
                                    size="small" 
                                    color="secondary"
                                    onClick={handleUserEditorLaunch}
                                    className={classes.btnAdd}><b>ADD AN AGENT</b>
                                </Button>
                                {modalOpened ? 
                                    <UserEditorModal
                                        opened={modalOpened}
                                        handleClose={handleUserEditorClose}
                                        mode="create" />
                                    : ''}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                    {users.map(i => (
                        <TableRow key={i.id}>
                            <TableCell>{i.name}</TableCell>
                            <TableCell>{i.phone_number}</TableCell>
                            <TableCell>{i.id_number}</TableCell>
                            {/* <TableCell>--</TableCell> */}
                            {/* <TableCell>{i.status}
                                { i.status ===0 ?
                                    <IconButton 
                                        size="small" 
                                        className={classes.btnIcon}
                                        style={{color: '#e4e4e4'}}
                                        onClick={() => handleChangeUserStatus(i.id, 1)}><LensIcon /> </IconButton>
                                    :
                                    <IconButton 
                                        size="small" 
                                        className={classes.btnIcon}
                                        style={{color: 'green'}}
                                        onClick={() => handleChangeUserStatus(i.id, 0)}><LensIcon /> </IconButton>
                                }
                            </TableCell> */}
                            
                            <TableCell align="right">
                                <Button 
                                    variant="outlined" 
                                    size="small" 
                                    className={classes.btnRemove}
                                    onClick={() => handleRemoveUser(i.id)}> REMOVE </Button>
                            </TableCell>
                            <TableCell align="right">
                                <Link variant="contained" size="small" className={classes.btnView} to={`/agents/${i.id}`}>VIEW </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                <Pagination 
                    page={page} 
                    setPage={handleChangePage} 
                    total={page_total}
                    activeProps={{ style: { backgroundColor: '#db3332' } }} />
            </div>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
