import React, { useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import api from '../../data/api' 
import { GoogleMap, Polyline, useLoadScript } from '@react-google-maps/api';
//https://react-google-maps-api-docs.netlify.com/#section-getting-started

import Script from 'react-load-script';
import { ROUTE_VIEW_PAGE_LOADED, ROUTE_VIEW_PAGE_UNLOADED, DELETE_ROUTE_STOP } from '../../constants/actionTypes';
import { MAP_LIBRARIES } from '../../constants/';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import TruckRouteEditorModal from './TruckRouteEditorModal.jsx';
import TruckRouteStopsEditorModal from './TruckRouteStopsEditorModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ShowSnackbar from '../common/ShowSnackbar.jsx';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Icon from '@mdi/react'
import { mdiPencil } from '@mdi/js';

const useStyles = makeStyles(theme => ({ 
    container: {
        backgroundColor: 'f1f2f6'
    },
    heading: {
        padding: 16,
        backgroundColor: '#FFFFFF',
    },
    pageTitle: {
        fontSize: '1.3rem'
    },
    btnApproval: {
        backgroundColor: '#1c3e5d',
        color: '#FFFFFF'
    },
    btnEdit: {
        backgroundColor: '#1c3e5d',
        minWidth: 100,
        color: '#FFFFFF',
        marginLeft: 10,
    },
    boxMap : {
        padding: 16,
        minHeight: 400,
        margin: '4px 20px 20px 20px',
        borderRadius: 3,
        border: '1px solid #e4e4e4',
        position: 'relative'
    },
    mapContainer : {
        position: 'absolute',
        top: 10,
        right: 10,
        left: 10,
        bottom: 10,
    },
    boxDetails: {
        padding: 26,
        paddingTop: 0,
        backgroundColor: '#FFFFFF',
    },
    loading: {
      minHeight: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fff'
    },
    proggressRoot: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        minHeight: '30vh',
    },
    stopBox : {
        padding: 16,
        border: '1px solid #f7f7f7',
        borderRadius: 6,
        marginBottom: 10,

    }
 }));

const mapStateToProps = state => ({
    ...state.truckroutes,
    truckroute: state.truckroutes.truckroute
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload) =>
      dispatch({ type: ROUTE_VIEW_PAGE_LOADED, payload }),
    onUnload: () =>
      dispatch({  type: ROUTE_VIEW_PAGE_UNLOADED }),
    onDeleteRouteStop: (routeStopID, payload) =>
      dispatch({  type: DELETE_ROUTE_STOP, routeStopID, payload })
});

function ViewTruckRoute(props) {
    // console.log('ViewTruckRoute >> here', props);
    const classes = useStyles();
    const [modalOpened, setModalOpen] = React.useState(false);
    const [stopsModalOpened, setStopsModalOpen] = React.useState(false);
    const [stopsModalMode, setStopsModalMode] = React.useState("create");
    const mapContainer = useRef();
    
    const [selectedRouteStopId, setSelectedRouteStop] = React.useState(null);
    // let [mapContainer, setmapContainer] = React.useState(null);
    
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyCgxrIzbrMWSNsVcO0kQmP52X1MWD1YaeU" 
    })

    let { id } = useParams();

    useEffect(() => {
        props.onLoad(api.Routes.get(id));
        
        return () => {
            props.onUnload();
        }
    }, []);
    
    const truckroutestops = props.truckroute ? props.truckroute.stops : null;

    const handleTruckRouteEditorLaunch = () => {
        setModalOpen(true);
    };

    const handleTruckRouteEditorClose = () => {
        setModalOpen(false);
    };
    
    const handleRouteStopsEditorLaunch = (e, mode) => {
        setStopsModalOpen(true);
        setStopsModalMode(mode);
        setSelectedRouteStop(e.id);
    };

    const handleRouteStopsEditorClose = () => {
        setStopsModalOpen(false);
    };

    const MySwal = withReactContent(Swal);
    const handleRemoveRouteStop = (val) => {
        // props.onBeforeDelete();
        MySwal.fire({
            title: 'Are you sure?',
            text: 'Confirm delete',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            
        }).then((result) => {
            if (result.value) {
                props.onDeleteRouteStop(val, api.RouteStops.del(id, val));
            }
        })
    }
    
    if (!props.truckroute) {
        return ( <div className={classes.proggressRoot}><CircularProgress size={24} thickness={4} /></div> );
    }
    if (props.truckroute.length === 0) {
        return ( <div className={classes.loading}> No details are here... yet. </div> );
    }

    const mapContainerStyle = {
        height: "400px",
        width: "100%"
    };

    const onmLoad = polyline => {
        console.log('polyline: ', polyline)
    };
    let mPaths = null;
    let center = null;
    if(truckroutestops != null) {
        //get center
        const count = truckroutestops.length;
        const ci = count > 2 ? Math.ceil(count/2) : 0;
        const mCenter = truckroutestops[ci] && truckroutestops[ci].coordinates ? truckroutestops[ci].coordinates : null;
        if(mCenter) {
            const centerParts = mCenter.split(",");
            const cLat = centerParts.length ? Number((centerParts[0]).trim()) : 0;
            const cLng = centerParts.length && centerParts[1] ? Number((centerParts[1]).trim()) : 0;
            center ={lat: cLat, lng: cLng};
        }
        //get coordinates
        mPaths = truckroutestops.map(route => {
            const parts = route.coordinates.split(",");
            const lat = parts.length ? Number((parts[0]).trim()) : 0;
            const lng = parts.length && parts[1] ? Number((parts[1]).trim()) : 0;
            return {
                lat, lng
            };
        })  
    }
      
    const options = {
        strokeColor: '#69A0F3',
        strokeOpacity: 0.8,
        strokeWeight: 10,
        fillColor: '#69A0F3',
        fillOpacity: 0.35,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        radius: 30000,
        paths: mPaths,
        zIndex: 1
    };
    
    // console.log("mPaths", mPaths);  
    // console.log("center", center);  
    return (
        <Box className={classes.container}>
            {props.responseStatus ? 
                <ShowSnackbar 
                    opened={props.responseStatus ? true : false}
                    variant={props.responseStatus ? props.responseStatus : null}
                    message={props.responseMessage!=="" ? props.responseMessage : false} />
                : ''}
            <Grid container className={classes.heading}> 
                <Grid item xs={6}>
                    <Typography variant='h5' className={classes.pageTitle}>ROUTE DETAILS</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                    <Button className={classes.btnEdit} onClick={()=> {props.history.push(`/routes/`)}}>BACK TO ROUTES</Button>
                    <Button 
                        variant="contained" 
                        className={classes.btnEdit} 
                        onClick={handleTruckRouteEditorLaunch}>EDIT</Button>
                    {modalOpened ? 
                        <TruckRouteEditorModal 
                            opened={modalOpened}
                            handleClose={handleTruckRouteEditorClose}
                            truckroute={props.truckroute}
                            mode="update"/>
                        : ''}

                    <Button 
                        variant="contained" 
                        className={classes.btnEdit} 
                        onClick={handleRouteStopsEditorLaunch}>ADD STOPS</Button>
                    
                    
                    {stopsModalOpened ? 
                        <TruckRouteStopsEditorModal 
                            opened={stopsModalOpened}
                            handleClose={handleRouteStopsEditorClose}
                            truckroute={props.truckroute}
                            selectedRouteStopId={selectedRouteStopId}
                            mode={stopsModalMode}
                            // truckroutestop={truckroutestop}
                            />
                        : ''}
                </Grid>
            </Grid>
            <Box className={classes.detailsBody}>
                <Grid container> 
                    <Grid item md={6} sm={12}>
                        <Box className={classes.boxMap}>                            
                            {isLoaded ? 
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    center={center}
                                    zoom={15}
                                >
                                <Polyline
                                    onLoad={onmLoad}
                                    path={mPaths}
                                    options={options}
                                    />
                                </GoogleMap>
                                : <div>{loadError}</div>}   
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <Box className={classes.boxDetails}>
                            <Box>
                                <div><Typography variant='h4'>{props.truckroute.name}</Typography></div><br/>
                            </Box>
                            <Box>
                                {/* {!truckroutestops ? <CircularProgress size={24} thickness={4} /> : ''} */}
                                {(()=> {
                                    if(truckroutestops != null) {
                                        return(
                                            truckroutestops.map((r_stop, i)=> (
                                                <Grid 
                                                    container 
                                                    className={classes.stopBox} 
                                                    key={i}>
                                                    <Grid item xs={9}>
                                                        <Grid container>
                                                            <strong>Stop : </strong>{r_stop.name}
                                                        </Grid>
                                                        <Grid container>
                                                            <Grid item>
                                                                <strong>Shops : </strong>
                                                            </Grid>
                                                            <Grid item>
                                                                {r_stop.shop_names 
                                                                    ?  r_stop.shop_names.map((name, index) => ( 
                                                                        <div key={index}> {name} </div> 
                                                                        )) 
                                                                    : ''}
                                                            </Grid>
                                                        </Grid>
                                                        <div><strong>GPS Locations : </strong>{r_stop.coordinates}</div>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton 
                                                            color="primary" 
                                                            onClick={()=>handleRouteStopsEditorLaunch(r_stop, "update")}
                                                            mode="update"
                                                            routestop_id={r_stop.id}
                                                            aria-label="edit route stop">

                                                            <Icon path={mdiPencil}
                                                                title="Edit route stop"
                                                                size={1}
                                                                color="#757575"/>
                                                        </IconButton>
                                                        <IconButton 
                                                            color="primary" 
                                                            onClick={() => handleRemoveRouteStop(r_stop.id)}
                                                            mode="update"
                                                            aria-label="delete route stop">
                                                            <DeleteOutlinedIcon
                                                                color="action"/>
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        )
                                    } else {
                                        return (
                                            <div>No route stops yet</div>
                                        )
                                    }
                                }) }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            
        </Box>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTruckRoute)