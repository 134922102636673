import React, { useEffect } from 'react'
import { PROFILE_PAGE_LOADED, PROFILE_VIEW_PAGE_UNLOADED } from '../../../constants/actionTypes';
import { connect } from 'react-redux';
import api from '../../../data/api'

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ProfileEditor from './ProfileEditor.jsx';

const useStyles = makeStyles(theme => ({ 
    container: {
        backgroundColor: 'f1f2f6'
    },
    heading: {
        padding: 16,
        backgroundColor: '#FFFFFF',
    },
    btnApproval: {
        backgroundColor: '#1c3e5d',
        color: '#FFFFFF'
    },
    btnEdit: {
        backgroundColor: '#1c3e5d',
        minWidth: 200,
        color: '#FFFFFF'
    },
    boxMap : {
        padding: 16,
        minHeight: 400
    },
    boxDetails: {
        padding: 26,
        backgroundColor: '#FFFFFF',
    },
    input: {display: 'none'},
    userDetailsThumb: {
        minHeight: 300,
        minWidth: 200,
        margin: '0 20px 20px 20px',
        borderRadius: 15,
        border: '1px solid #1c3e5d',
    },
    userDetailsThumbImg: {
        display: 'flex',
        justifyContent: 'center'
    },
    uploadButton : {
        textAlign: 'center',
        backgroundColor: '#1c3e5d',
        color: '#FFFFFF'
    }
 }));

 const mapStateToProps = state => {
    return {
      currentUser: state.common.currentUser
    }};
  
  const mapDispatchToProps = dispatch => ({
    onLoad: (payload, token) =>
      dispatch({ type: PROFILE_PAGE_LOADED, payload }),
    onUnload: () =>
      dispatch({  type: PROFILE_VIEW_PAGE_UNLOADED })
  });

function MyProfile(props) {
    const classes = useStyles();

    const user = props.currentUser;

    useEffect(() => {
        props.onLoad(api.Auth.current(), null);
        return () => {
            props.onUnload();
        }
    }, []);

    const [openEditor, setOpenEditor] = React.useState(false);

    const handleLoadProfileEditor = () => {
        setOpenEditor(true);
    };

    const handleCloseProfileEditor = () => {
        setOpenEditor(false);
    };

    return (
        <Box className={classes.container}>
            <Grid container className={classes.heading}> 
                <Grid item xs={6}>
                    <Typography variant="h6">
                        <b>APP USER</b>
                    </Typography>
                </Grid>
                <Grid item xs={6} align="right">
                    <Button 
                        variant="contained" 
                        size="small" 
                        className={classes.btnEdit} 
                        onClick={handleLoadProfileEditor}
                        ><b>EDIT</b>
                    </Button>
                    <ProfileEditor
                        opened={openEditor}
                        handleClose={handleCloseProfileEditor}
                        user={user}>
                    </ProfileEditor>
                    
                </Grid>
            </Grid>
            <Box className={classes.detailsBody}>
                <Grid container> 
                    <Grid item xs={4}>
                        <Box className={classes.boxMap}>
                            <div className={classes.userDetails}>
                                <div className={classes.userDetailsThumb}>
                                    <img src={props.currentUser.profile_picture} alt=""/>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box className={classes.boxDetails}>
                            <Box>
                                <div><Typography variant='h4'>{props.currentUser.name}</Typography></div>
                                {/* <div><Typography variant='h6'>ID Number: 324568741</Typography></div> */}
                                {/* <div><Typography variant='h6'>Phone Number: 67498322</Typography></div> */}
                                <div><Typography variant='h6'>Email address: {props.currentUser.email}</Typography></div>
                                {/* <div><Typography variant='h6'>Pass code: ****</Typography></div> */}
                            </Box>
                            {/* <Box>
                                <br/>
                                <Button variant="contained" size="small" className={classes.btnApproval} onClick={()=> {console.log('clicked me')}}>SAVE</Button>
                            </Box> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            
        </Box>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)
