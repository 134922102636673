import React, { useEffect } from 'react';
//Theme
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import api from '../../../../data/api';

const useStyles = makeStyles(theme => ({ 
    iconBox : {
        width: '2.5rem',
        height: '2.5rem',
        borderRadius: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '-5px'
    },
    iconReturn: {
        backgroundColor: '#F6D7E2'
    },
    iconJaza: {
        backgroundColor: '#FCE6D3'
    },
    iconMpesa: {
        backgroundColor: '#E1F2DB'
    },
    iconCredit: {
        backgroundColor: '#D3ECEF'
    },
    iconUnaccounted: {
        backgroundColor: '#D2D6DC'
    },
    sumTitle: {
        fontSize: '1rem'
    }, 
    sumValue: {
        fontSize: '2rem'
    }
 }));

const Summary = (props) => {
    //console.log('props', props);
    //UI
    const classes = useStyles();
    
    //const { title, currency, value, icon} = props.summary;

    /*useEffect(() => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        const currentDate = dd + '-' + mm + '-' + yyyy;

        const promise = api.Summary.one(props.summary, currentDate);
        
        promise.then(response => {
            
        }) 
        // props.onLoad(promise);
    }, []);*/

    // const {logo} = props.logo;
    return ( 
        <Grid item xs>
            <Card>
                <CardContent>
                <Grid container>
                    <Grid item xs={10}>
                        <Typography variant="subtitle1" color="textSecondary" className={classes.sumTitle}>
                            <strong>{props.assets.title}</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <div className={clsx(classes.iconBox, classes[props.assets.icon])}>
                            <img src={`${props.logo}`} alt={props.assets.icon} />
                        </div>
                    </Grid>
                </Grid>
                    <Typography variant="h5" className={classes.sumValue}>
                        {props.assets.currency} {props.summary !==null ? props.summary.value : '0'}
                    </Typography>                    
                </CardContent>
            </Card>
        </Grid> );
}
 
export default Summary;