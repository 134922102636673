import React from 'react'

//UI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    titleDash: {
        fontSize: '2rem'
      },
}))

export default function PageTitle() {
    const classes = useStyles();
    return (
        <Box className={classes.card}>
            <CardContent>
                <Typography variant="h5" className={classes.titleDash}>
                    <b>Dashboard</b>
                </Typography>
            </CardContent>
        </Box>
    )
}
