import React from 'react'

//Theme
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import Avatar from '@material-ui/core/Avatar';
// import iconAvatar from '../../../../../assets/images/img/switch_accounts.png';
import RecentActorsIcon from '@material-ui/icons/RecentActors';

const useStyles = makeStyles(theme => ({
    userAvatar: {
        marginRight: 5,
        borderRadius:0, 
        "& img" : {
            width: 'auto'
        }
    },
    button: {
        margin: theme.spacing(1),
        fontSize: '.9rem',
        fontWeight: 700
      }
}))

export default function SwitchAccountsButton(props) {
    const classes = useStyles();
    return (
        <div>
            <Button className={classes.button} onClick={props.onShowAccounts}> 
                <RecentActorsIcon className={clsx(classes.userAvatar, classes.bigAvatar)} />
                {/* <Avatar variant="square" alt="Remy Sharp" src={iconAvatar} className={clsx(classes.userAvatar, classes.bigAvatar)} /> */}
                SWITCH ACCOUNTS
            </Button> 
        </div>
    )
}
