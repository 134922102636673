import React from 'react'
import api from '../../data/api'
import { ROWS_PER_PAGE } from '../../constants/'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import ListRow from '../common/listRow/ListRow';
// import mockData from '../../data/mock.json';
import Title from '../common/listRow/listTitle/components/Title';
import { CURRENT_MODULE_LOADED, DELETE_TRUCK, SET_TRUCKS_PAGE, CLEAR_TRUCK_SUBMIT_RESPONSE } from '../../constants/actionTypes';

//Theme
import { makeStyles } from '@material-ui/core/styles';
// import InputBase from '@material-ui/core/InputBase';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import Pagination from 'react-mui-pagination'; //https://github.com/bnabriss/react-mui-pagination
import TruckEditorModal from './TruckEditorModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShowSnackbar from '../common/ShowSnackbar.jsx';

const useStyles = makeStyles(theme => ({
    list_root: {
        minHeight: 300
    },
    listheader:{
        backgroundColor: '#EEEEEE',
        fontSize: 16, 
        "& tr th": {
            fontSize: 16,
        }
    },
    rowheader: {
        height: 60
    },
    seeMore: {
      marginTop: theme.spacing(3),
    },
    btnAdd: {
        backgroundColor: '#db3332',
        width: 200,
        height: 36, 
        "& span": {
            fontSize: 16
        }
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
    },
    selectTruck: {
        maxWidth: 200,
        minWidth: 150,
        textAlign: 'left'
    },
    selectLable: {
        zIndex: 100,
        top: 17,
        left: 10,
        backgroundColor: '#FFFFFF',
    },
    txtRight: {
        textAlign: "right"
    },
    pageTitle: {
        padding: 15,
        backgroundColor: '#FFFFFF',
        maxWidth: '100%'
    },
    loading: {
      minHeight: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fff'
    },
    proggressRoot: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        minHeight: '30vh',
    },
    btnEdit: {
        backgroundColor: '#1c3e5d',
        width: 171,
        color: '#FFFFFF'
    },
    btnRemove: {
        color: '#1c3e5d',
        border: '1px solid #1c3e5d',
        width: 170,
        fontSize: 16,
        "& span": {
            fontSize: 16
        }
    },
    btnView : {
        backgroundColor: '#1c3e5d',
        width: 171,
        boxSizing: 'border-box',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontWeight: 500,
        borderRadius: 4,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        marginLeft: 10,
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        margin: 0,
        display: 'inline-flex',
        verticalAlign: 'middle',
        color: '#FFFFFF',
        justifyContent: 'center',
        textDecoration: 'none',
        padding: '8px',
        fontSize: 16,
        "&:hover": {
            backgroundColor: '#cccccc'
        }
    },
    tableBody: {
        "& tr td": {
            fontSize: 16,
            padding: '15px 10px'
        }
    },
    unassigned: {
        fontSize: 14,
        color: '#999999'
    },
    pagination: {
        textAlign: 'center'
    }
  }));


  const mapStateToProps = state => ({
    ...state.trucks
  });

  const mapDispatchToProps = dispatch => ({
    onOnSetModule: () => 
      dispatch({ type: CURRENT_MODULE_LOADED, module:'TRUCKS' }),
    onSetPage: (payload, page) => 
      dispatch({ type: SET_TRUCKS_PAGE, payload, page }),
    onBeforeDeleteTruck: () => 
      dispatch({ type:CLEAR_TRUCK_SUBMIT_RESPONSE }),
    onDeleteTruck: (truckID, payload) => 
      dispatch({ type: DELETE_TRUCK, truckID, payload })
  });

let cleanTrucks = null;

function ListTrucks(props) {

    props.onOnSetModule();
    
    const classes = useStyles();

    const [modalOpen, setModalOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    let page_total = props.pagination ? props.pagination.total : 0;

    const handleTruckEditorLaunch = () => {
        setModalOpen(true);
    };

    const handleTruckEditorClose = () => {
        setModalOpen(false);
    };

    const MySwal = withReactContent(Swal);
    const handleRemoveTruck = (val) => {
        props.onBeforeDeleteTruck();
        MySwal.fire({
            title: 'Are you sure?',
            text: 'Confirm delete',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            
        }).then((result) => {
            if (result.value) {
                props.onDeleteTruck(val, api.Trucks.del(val));
            }
        })
    }

    const handleChangePage = (e, newPage) => {
        props.onSetPage(api.Trucks.all(newPage), newPage);
        setPage(newPage);
    }

    let reducedTrucks = null;
    if(props && props.trucks) {
        reducedTrucks = props.trucks.map(truck => {
            return {
                primary_key: truck.id, 
                truck_id: truck.id, 
                truck_reg_no: truck.reg_no, 
                delivery_agent_name: truck.delivery_agent ? truck.delivery_agent.name : 'null', 
                phone_number: truck.delivery_agent ? truck.delivery_agent.phone_number : 'null', 
                truck_till_number: truck.till_number ? truck.till_number : 'null'
            }
        });
        
        cleanTrucks = [...reducedTrucks];
    }

    if (!cleanTrucks) {
        return ( <div className={classes.proggressRoot}><CircularProgress size={24} thickness={4} /></div> );
    }
    if (cleanTrucks.length === 0) {
        // return ( <div className={classes.loading}> No trucks are here... yet. </div> );
    }
    return (
        <React.Fragment>
            <div  className={classes.list_root}>
                <Container className={classes.pageTitle}>
                    <Box
                        justifyContent="center"
                        display="flex"
                        alignItems="center"
                    >
                        <Grid container display="flex" alignItems="center">
                            <Grid item xs={6}>
                                <Title title="TRUCK DETAILS"></Title>
                            </Grid>
                            <Grid item xs={6}  className={classes.txtRight}>
                            
                            </Grid>
                        </Grid>
                    </Box>
                    {props.responseStatus ? 
                        <ShowSnackbar 
                            opened={props.responseStatus ? true : false}
                            variant={props.responseStatus ? props.responseStatus : null}
                            message={props.responseMessage!=="" ? props.responseMessage : false} />
                        : ''}
                        
                </Container>
                <Table size="small">
                    <TableHead className={classes.listheader}>
                        <TableRow className={classes.rowheader}>
                            <TableCell><b>VAN NUMBER</b></TableCell>
                            <TableCell><b>DELIVERY AGENT</b></TableCell>
                            <TableCell><b>PHONE NUMBER</b></TableCell>
                            <TableCell><b>TILL NUMBER</b></TableCell>
                            <TableCell colSpan={2} align="right">
                                <Button 
                                    variant="contained" 
                                    size="small" 
                                    color="secondary" 
                                    onClick={handleTruckEditorLaunch} 
                                    className={classes.btnAdd}><b>ADD TRUCK</b>
                                </Button>
                                {modalOpen ? <TruckEditorModal
                                    opened={modalOpen}
                                    handleClose={handleTruckEditorClose}
                                    mode="create">
                                </TruckEditorModal> : ''}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                    {cleanTrucks.map((truck, index) => (
                        
                            <TableRow key={index}>
                                <TableCell>{truck.truck_reg_no}</TableCell>
                                <TableCell>{truck.delivery_agent_name !=="null" ? truck.delivery_agent_name : <em className={classes.unassigned}>unassigned</em>}</TableCell>
                                <TableCell>{truck.phone_number !=="null" ? truck.phone_number : <em className={classes.unassigned}>unassigned</em>}</TableCell>
                                <TableCell>{truck.truck_till_number !=="null" ? truck.truck_till_number : <em className={classes.unassigned}>unassigned</em>}</TableCell>
                                
                                <TableCell align="right">
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        onClick={() => handleRemoveTruck(truck.truck_id)}
                                        className={classes.btnRemove}>REMOVE</Button>
                                    <Link variant="contained" size="small" className={classes.btnView} to={`/trucks/${truck.truck_id}`}>VIEW </Link>
                                </TableCell>
                            </TableRow>
                    ))}
                    </TableBody>
                    
                </Table>
                
                <Pagination 
                    page={page} 
                    setPage={handleChangePage} 
                    total={page_total}
                    activeProps={{ style: { backgroundColor: '#db3332' } }}
                    className={classes.pagination} />
            </div>
        </React.Fragment>)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListTrucks);