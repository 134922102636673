import React, { useEffect } from 'react';
import Summary from "./components/summary";
import { LOAD_SUMMARIES, TOGGLE_SUMMARY } from '../../../constants/actionTypes';
import { connect } from 'react-redux';

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseButton from './components/CloseButton';
import CircularProgress from '@material-ui/core/CircularProgress';

//Icons
import iconReturn from '../../../assets/images/img/iconReturn.png';
import iconJaza from '../../../assets/images/img/iconJaza.png';
import iconMpesa from '../../../assets/images/img/iconMpesa.png';
import iconCredit from '../../../assets/images/img/iconCredit.png';
import iconUnaccounted from '../../../assets/images/img/iconUnaccounted.png';
import api from '../../../data/api';
import mockData from '../../../data/mock.json';

const useStyles = makeStyles(theme => ({ 
    paddLeft20: {
        paddingLeft: '32px',
        paddingRight: '32px',
        fontSize: '1.5rem'
    },
    txtRight: {
        textAlign: "right"
    },
    summaryTitle: {
        backgroundColor: "#FFFFFF"
    },
    summaryBoxes: {
        backgroundColor: "#f1f2f6",
        minHeight: 165,
        padding: "20px"
    },
    loading: {
        minHeight: 250,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#fff'
      },
    proggressRoot: {
        display: 'flex',
        '& > * + *': {
        marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        minHeight: '30vh',
    },
 }));

const icons = { 1: iconReturn, 2: iconJaza, 3: iconMpesa, 4: iconCredit, 5: iconUnaccounted }

const mapStateToProps = state => ({
    showSummaries: state.common.toggleSummary,
    summary: state.common.summary,
});
  
const mapDispatchToProps = dispatch => ({
    onLoad: (payload) =>
      dispatch({ type: LOAD_SUMMARIES, payload }),
    onToggleSummary: (payload) =>
      dispatch({ type: TOGGLE_SUMMARY, payload })
});

const {summaryAssets} = mockData.data;

function Summaries (props) {
    
    const classes = useStyles();
    //console.log('props.summary', props.summary);
    //const {sales_return, kcb_jaza_duka, mpesa, bank_credit, cash, unaccounted}  = props.summary;

    // const [showSummaries, setToggle] = React.useState(true);

    const handleSummariesToggle = () => {
        props.onToggleSummary(!props.showSummaries);
    }

    useEffect(() => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        const currentDate = dd + '-' + mm + '-' + yyyy;

        const promise = api.Summary.all(currentDate);
        
        promise.then(response => {
            props.onLoad(response);
        }) 
        
    }, []);

    // let props.showSummaries = toggleStatus;
    if (!props.summary) {
        return ( <div className={classes.proggressRoot}><CircularProgress size={24} thickness={4} /></div> );
    }
    return ( 
    <React.Fragment>
        <section className={classes.section}>
            <Grid container className={classes.summaryTitle} spacing={2}>
                <Grid item xs>
                    {props.showSummaries ?
                    <Typography variant="h6" className={classes.paddLeft20}>
                        SUMMARY
                    </Typography>
                      : 
                      ''}
                </Grid>
                <Grid item xs className={classes.txtRight}>
                    <CloseButton 
                        onClickToggle={handleSummariesToggle}
                        status={props.showSummaries}
                        ></CloseButton>
                </Grid>
            </Grid>
            {props.showSummaries ? 
                <Grid container className={classes.summaryBoxes} justify="center" spacing={2}>
                    <Summary 
                        summary={ props.summary && props.summary.summary ? props.summary.summary.sales_return : null}
                        assets={summaryAssets['sales_return']}
                        logo={icons[1]}>
                    </Summary>
                    <Summary 
                        summary={props.summary && props.summary.summary ? props.summary.summary.kcb_jaza_duka : null}
                        assets={summaryAssets['kcb_jaza_duka']}
                        logo={icons[2]}>
                    </Summary>
                    <Summary 
                        summary={props.summary && props.summary.summary ? props.summary.summary.cash : null}
                        assets={summaryAssets['mpesa']}
                        logo={icons[3]}>
                    </Summary>
                    <Summary 
                        summary={props.summary && props.summary.summary ? props.summary.summary.bank_credit : null}
                        assets={summaryAssets['bank_credit']}
                        logo={icons[4]}>
                    </Summary>
                    <Summary 
                        summary={props.summary && props.summary.summary ? props.summary.summary.unaccounted : null}
                        assets={summaryAssets['unaccounted']}
                        logo={icons[5]}>
                    </Summary>
                    {/* {summaries.map(summary => 
                    <Summary 
                        key={summary.id}
                        summary={summary}
                        logo={icons[summary.id]}>
                    </Summary>)} */}
                </Grid>
            : 
                '' 
            }
        </section>
    </React.Fragment> );
}
 

export default connect(mapStateToProps, mapDispatchToProps)(Summaries);