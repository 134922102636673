import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { 
  UPDATE_SEARCH_FIELD, 
  SEARCH_TRUCKS_SUBMITTED,
  SEARCH_ROUTES_SUBMITTED,
  SEARCH_USERS_SUBMITTED,
  SEARCH_INVOICES_SUBMITTED, 
  SEARCH_SALES_RETURNS_SUBMITTED,
  INVOICE_LIST_PAGE_LOADED
} from '../../../../../constants/actionTypes';
import api from '../../../../../data/api'


//UI
import { fade, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #d4376d',
      height: 40,
      transition: theme.transitions.create('width'),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: '100%',
      },
    },
    searchField: {
      marginLeft: theme.spacing(1),
      flex: 1,
      "& input" : {
        height: 48,
      }
    },
    divider: {
      height: 28,
      margin: 4,
    },
    iconButton: {
      padding: 10,
    },
  }));

  const mapStateToProps = state => ({ 
    ...state, 
    // ...state.common.search, 
    // current_module: state.common.current_module,
    // truckroutes: state.common.truckroutes.data,
  });

  const mapDispatchToProps = dispatch => ({
    onUpdateField: (key, value) => 
      dispatch({ type: UPDATE_SEARCH_FIELD, key, value }),
    onSubmit: (type, payload) => 
      dispatch({ type, payload }),
  });

function Search(props) {
  
  const classes = useStyles();
  
  const [searching, setSearchProgress] = React.useState(false);
  const [hint, setHint] = React.useState("Van number, delivery agent name");

  
  useEffect(() => {
    const this_module = props.common.current_module;
    let currentHint = null;
    if(this_module==="TRUCKS") {
      currentHint = ("Search by Van number")
    } else 
    if(this_module==="TRUCKROUTES") {
      currentHint = ("Search by Route name")
    } else 
    if(this_module==="INVOICES") {
      currentHint = ("Search by shop name, cash memo no.")
    } else 
    if(this_module==="SALESRETURNS") {
      currentHint = ("Search by cash memo number")
    } else 
    if(this_module==="USERS") {
      currentHint = ("Search by User name, phone number")
    }
    setHint(currentHint);
  }, [props.common.current_module]);

    
  const handleSubmit = ev => {
    setSearchProgress(true);
    //then get a new list
    const current_module = props.common.current_module;
    const keyword = props.common.search ? props.common.search.keyword : null;
    if(keyword && keyword.length>0) {
      if(current_module==="TRUCKS") {
        setHint("Search by Van number")
        props.onSubmit(SEARCH_TRUCKS_SUBMITTED, api.Trucks.search(keyword));
      } else 
      if(current_module==="TRUCKROUTES") {
        setHint("Search by Route name")
        props.onSubmit(SEARCH_ROUTES_SUBMITTED, api.Routes.search(keyword));
      } else 
      if(current_module==="INVOICES") {
        setHint("Search by shop name, cash memo no.")
        props.onSubmit(SEARCH_INVOICES_SUBMITTED, api.Invoices.search(keyword));
      } else 
      if(current_module==="SALESRETURNS") {
        setHint("Search by cash memo number")
        props.onSubmit(SEARCH_SALES_RETURNS_SUBMITTED, api.SalesReturns.search(keyword));
      } else 
      if(current_module==="USERS") {
        setHint("Search by: User name, phone number")
        props.onSubmit(SEARCH_USERS_SUBMITTED, api.Users.search(keyword));
      }
    }
    setTimeout(()=>{
      setSearchProgress(false);
    }, 1000);
  };

  const handleRevert = () => {
    //then get a new list
    
    const current_module = props.common.current_module;
    const keyword = props.common.search.keyword;
    //const currentDate = Math.floor(Date.now() / 1000);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    const currentDate = dd + '-' + mm + '-' + yyyy;
    const firstRoute = props.common.truckroutes.data[0].id;
    
    if(current_module==="TRUCKS")
      props.onSubmit(SEARCH_TRUCKS_SUBMITTED, api.Trucks.all());
    else 
    if(current_module==="TRUCKROUTES")
      props.onSubmit(SEARCH_ROUTES_SUBMITTED, api.Routes.all());
    else 
    if(current_module==="INVOICES")
      props.onSubmit(INVOICE_LIST_PAGE_LOADED, api.Invoices.all(currentDate, firstRoute));
    else 
    if(current_module==="SALESRETURNS")
      props.onSubmit(SEARCH_SALES_RETURNS_SUBMITTED, api.SalesReturns.all(keyword));
    else 
    if(current_module==="USERS")
      props.onSubmit(SEARCH_USERS_SUBMITTED, api.Users.all());
  };

  const updateFieldEvent = key => ev =>{
    props.onUpdateField(key, ev.target.value);
    
    if(ev.target.value.length===0){
      handleRevert();
    }     
  };
  const changeSearchInput = updateFieldEvent('keyword');

  const watchForEnter = ev => {
    if (ev.keyCode === 13) {
      
      ev.preventDefault();
      handleSubmit();

    }
  };

  return (
      <React.Fragment>
          {/* <form className={classes.container} noValidate autoComplete="off">
              <TextField
                  id="outlined-basic"
                  className={classes.textField}
                  label="Outlined"
                  margin="normal"
                  variant="outlined"
              />
          </form> */}
          <div className={classes.formControl}>
            <InputBase
              className={classes.searchField}
              placeholder={hint}
              inputProps={{ 'aria-label': 'Quick search' }}
              onChange={changeSearchInput}
              onKeyUp={watchForEnter}
            />
            {searching ? <CircularProgress size={16} thickness={3} /> : ''}
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton className={classes.iconButton} aria-label="search" onClick={handleSubmit}>
              <SearchIcon />
            </IconButton>
          </div>
      </React.Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);