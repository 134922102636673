import {
  SET_TRUCKS_PAGE,
  TRUCK_LIST_APPLY_FILTER,
  TRUCK_LIST_PAGE_LOADED,
  UPDATE_FIELD_EDITOR,
  TRUCK_SUBMITTED,
  TRUCK_LIST_PAGE_UNLOADED,
  TRUCK_VIEW_PAGE_LOADED,
  TRUCK_VIEW_PAGE_UNLOADED,
  TRUCK_SUBMIT_FOR_APPROVAL,
  // ASYNC_START,
  DELETE_TRUCK,
  TRUCK_EDITOR_MODAL_LOADED,
  TRUCK_EDITOR_MODAL_UNLOADED,
  TRUCK_SETTINGS_MODAL_LOADED,
  TRUCK_SETTINGS_MODAL_UNLOADED,
  UPDATE_TRUCK_SETTING_FIELD_EDITOR,
  TRUCK_SETTINGS_SUBMITTED,
  SEARCH_TRUCKS_SUBMITTED,
  CLEAR_TRUCK_SUBMIT_RESPONSE
} from '../constants/actionTypes';

export default (state = {}, action) => {
  // console.log('action', action);
  switch (action.type) {
    case TRUCK_LIST_PAGE_LOADED:
      return {
        ...state,
        pager: action.pager,
        trucks: action.payload ? action.payload.data : null,
        trucksCount: action.payload ? action.payload.meta.pagination.count : null,
        pagination: action.payload ? action.payload.meta.pagination : null,
        currentPage: 0,
        search_results: false,
        meta: action.meta
      };
    case SEARCH_TRUCKS_SUBMITTED:
      return {
        ...state,
        pager: action.pager,
        trucks: action.payload ? action.payload.data : null,
        trucksCount: action.payload ? action.payload.meta.pagination.count : null,
        pagination: action.payload ? action.payload.meta.pagination : null,
        currentPage: 0,
        search_results: action.payload.data ? true : false,
        inProgress: true
      };
    case SET_TRUCKS_PAGE:
      return {
        ...state,
        trucks: action.payload ? action.payload.data : null,
        trucksCount: action.payload ? action.payload.meta.pagination.count : null,
        pagination: action.payload ? action.payload.meta.pagination : null,
        currentPage: action.page
      };
    case TRUCK_LIST_APPLY_FILTER:
      return {
        ...state,
        pager: action.pager,
        trucks: action.payload ? action.payload.data : null,
        trucksCount: action.payload ? action.payload.meta.pagination.count : null,
        pagination: action.payload ? action.payload.meta.pagination : null,
        currentPage: 0
      };
    case TRUCK_VIEW_PAGE_LOADED:
      return {
        ...state,
          truck: {
            id: action.payload ? action.payload.id : null,
            reg_no: action.payload ? action.payload.reg_no : null,
            truck_ref: action.payload ? action.payload.truck_ref : null,
            route_id: action.payload && action.payload.route ? action.payload.route.id : null,
            route_name: action.payload && action.payload.route ? action.payload.route.name : null,
            delivery_agent_id: action.payload && action.payload.delivery_agent ? action.payload.delivery_agent.id: null,
            delivery_agent_name: action.payload && action.payload.delivery_agent ? action.payload.delivery_agent.name : null,
            till_number: action.payload ? action.payload.till_number : null,
          }
      };
    case TRUCK_EDITOR_MODAL_LOADED:
      return {
        ...state,
        truck: action.mode && action.mode==="create" ? null : {...state.truck},
        responseStatus: null,
        responseMessage: null,
      };
    case TRUCK_EDITOR_MODAL_UNLOADED:
      return {};

    case UPDATE_FIELD_EDITOR:
      return { 
          ...state, 
          truck: {
            ...state.truck,
            [action.key]: action.value
          } 
      };
    case TRUCK_SUBMITTED:
      return {
        ...state,
        // trucks: action.payload ? action.payload[1].data : null,
        // trucksCount: action.payload ? action.payload[1].meta.pagination.count : null,
        inProgress: null,
        responseStatus:action.payload ? action.payload.Response.status : null,
        responseMessage:action.payload ? action.payload.Response.message : null,
        // errors: (action && action.error) ? action.payload.errors : null
      };
    case TRUCK_SETTINGS_MODAL_LOADED:
      return {
        ...state,
        responseStatus: null,
        responseMessage: null,
      };
    case UPDATE_TRUCK_SETTING_FIELD_EDITOR:
      return { 
        ...state, 
        truck: {
          ...state.truck,
          [action.key]: action.value
        } 
      };

    case TRUCK_SETTINGS_SUBMITTED:
      return {
        ...state,
        inProgress: null,
        responseStatus:action.payload ? action.payload.Response.status : null,
        responseMessage:action.payload ? action.payload.Response.message : null,
      };

    case TRUCK_SETTINGS_MODAL_UNLOADED:
      return {};

    case TRUCK_SUBMIT_FOR_APPROVAL:
      return {
        ...state,
        truckID: action.error ? null : (state.truckID || []).concat([action.payload.truckID])
      };
    case TRUCK_VIEW_PAGE_UNLOADED:
      return {
        ...state,
        truck: null,
        responseStatus: null,
        responseMessage: null,
      };
      
    case CLEAR_TRUCK_SUBMIT_RESPONSE:
        return {
          ...state,
          responseStatus: null,
          responseMessage: null,
        };
    case DELETE_TRUCK:
      const ID = action.truckID
      return {
        ...state,
        trucks: state.trucks.filter(truck => truck.id !== ID),
        responseStatus:action.payload ? action.payload.Response.status : null,
        responseMessage:action.payload ? action.payload.Response.message : null,
      };

    case TRUCK_LIST_PAGE_UNLOADED: 
      return {
        ...state,
        responseStatus: null,
        responseMessage: null,
      }
      
    default:
      return state;
  }
};
