import React from 'react';
import UserAvatar from './components/UserAvatar/';
import { connect } from 'react-redux';//routing

//Theme
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import SwitchAccounts from './components/switchAccounts/';
import NotificationBell from './components/notification/NotificationBell';
import NotificationList from './components/notification/NotificationList';
import imgLogo from '../../../assets/images/img/logo_300_2.png';
// import AccountsList from './components/switchAccounts/';
import Logout from './components/Logout';
import ShowCircularLoader from '../ShowCircularLoader';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';


const useStyles = makeStyles(theme => ({
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      toolbarIcon: {
        minHeight: 128,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
      appBar: {
        backgroundColor:' #e5eafa',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        // marginLeft: drawerWidth,
        marginLeft: 0,
        // width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      appBarGrid: {
        minHeight: 72
      },
      menuButton: {
        marginRight: 36,
      },
      menuButtonHidden: {
        display: 'none',
      },
      notificationRoot: {
        display: 'flex',
        '& > * + *': {
          marginLeft: 16,
        },
      },
      title: {
        flexGrow: 1,
      },
      button: {
        margin: theme.spacing(1),
        fontSize: '.7rem',
        fontWeight: 700
      },

      footer: {
        backgroundColor: "#555555",
        minHeight: 100
    },
    footerCopy : {
        color: '#FFFFFF'
    },
    logoBox: {
      textAlign: 'center',
      lineHeight: 0,
    },
    logoImage: {
      maxHeight: 80
    },
    appBarLoader: {
      position: 'absolute',
      height: 50,
      width: '100%',
      top: 0,
      right: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }
  }))

  const mapStateToProps = state => ({
    inProgress: state.common.inProgress
});
  
const mapDispatchToProps = dispatch => ({});

function Header(props) {
  const classes = useStyles();
  const open = true;
  const [avatarAnchorEl, setavatarAnchorEl] = React.useState(null);
  
  // const [accountsAnchorEl, setAccountsAnchorEl] = React.useState(null);
  
  // if(props.userInfo == null) {
  //   return (
  //   <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
  //     <Toolbar className={classes.toolbar} style={{textAlign: 'center'}}>
  //       <Typography variant="h6" color="primary">Offline: Please connect internet to continue</Typography> 
  //     </Toolbar>
  //   </AppBar>);
  // }
  // const {name} = props.userInfo.user;
  // const {organisations} = props.userInfo;
  // const handleDrawerOpen = () => {
  //     setOpen(true);
  // };
  const handleShowNotification = event => {
    setavatarAnchorEl(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setavatarAnchorEl(null);
  };

  const clearNotifications = () => {
    
  }

  return (
  <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
          <div className={classes.appBarLoader}>
            <ShowCircularLoader opened={props.inProgress} />
          </div>
          <Grid container 
          className={classes.appBarGrid}
          justifycontent="center"
          display="flex"
          alignItems="center">
            <Grid item  className={classes.logoBox} xs={2}>
              <img src={imgLogo} alt="logo" className={classes.logoImage} />
            </Grid>
            <Grid item  xs={3}></Grid>
            <Grid item xs>
              <div className={classes.root}>
                <NotificationBell onShowNotification={handleShowNotification}></NotificationBell>
                <NotificationList 
                  onHideNotification={handleCloseNotification} 
                  onClearNotifications={clearNotifications} 
                  anchorEl={avatarAnchorEl}>
                </NotificationList>
              </div>
            </Grid>
            <Grid item xs>
              <UserAvatar></UserAvatar>
            </Grid>
            <Grid item xs>
              <SwitchAccounts props={1}></SwitchAccounts>
              {/* <AccountsList
                onHideAccounts={handleCloseAccountsList}
                anchorEl={accountsAnchorEl}>
              </AccountsList> */}
            </Grid>
            <Grid item xs={1}>
              <Logout/>
            </Grid>
          
          </Grid> 
            
      </Toolbar>
    </AppBar>
    )
  
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);