import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Header from './views/common/header/Header'
import MainBody from './views/MainBody'
import SignIn from './views/Auth/SignIn'
import SignUp from './views/Auth/SignUp'
import mockData from './data/mock.json';
import { APP_LOAD, REDIRECT } from './constants/actionTypes';

import api from './data/api'
// import createPalette from 'material-ui/styles/palette';
// import createTypography from 'material-ui/styles/typography';

//routing
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

//Styling
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import './App.css';

//fonts
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Policy from './views/common/Policy';
const montserrat = {
      fontFamily: 'Montserrat Regular',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 400,
      src: "local('Montserrat Regular'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype')",
      unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const theme = createMuiTheme({
    typography: {
      fontFamily: 'Montserrat Regular',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [montserrat],
        },
      },
    },
});

// const theme = createMuiTheme({
//   typography: createTypography(createPalette(), {
//   fontFamily: 'Montserrat Regular',
//   })
// });

const {summary} = mockData.data;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  loading: {
    minHeight: 250,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff'
  },
  proggressRoot: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    minHeight: '100vh',
  },
}));

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    organisations: state.common.organisations,
    redirectTo: state.common.redirectTo,
    loggedOut: state.common.loggedOut,
  }};

const mapDispatchToProps = dispatch => ({
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () =>
    dispatch({ type: REDIRECT })
});

export const Host = (props) => {
  const classes = useStyles();
  
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            <div className={classes.root} theme={theme}>
              <Header userInfo={props.userInfo}></Header>
              <MainBody></MainBody>
            </div>
          </Switch>
        </Router>
      </ThemeProvider>
    </React.Fragment>
  )
}
export const Guest = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/" activeClassName="active" component={SignIn} />
            <Route path="/signup" activeClassName="active" component={SignUp} />
            <Route path="/policy" activeClassName="active" component={Policy} />
          </Switch>
        </Router>
      </ThemeProvider>
    </React.Fragment>
  )
}

const Promise = global.Promise;

function QuadcoApp(props) {
  const classes = useStyles();

  // console.log('props',props);
  useEffect(() => {
    const token = window.localStorage.getItem('jwt');
    if (token) {
      api.setToken(token);
    } 
    props.onLoad(token 
      ? Promise.all(
          [
              api.Auth.current(), 
              api.Organisations.all(), 
              api.Routes.allUnpaged(), 
              api.Users.all(), 
              api.Trucks.all()
          ].map(p=>p.catch(() => undefined ))
        )
      : null, token);
  }, []);
  const user = props.currentUser;
  // const organisations = props.organisations;
  const userInfo = {
    user : props.currentUser,
    organisations : props.organisations,
  }
  if (!user && !props.loggedOut) {
    return ( <div className={classes.proggressRoot}><CircularProgress size={24} thickness={4} /></div> );
  }
  
  return (
    user ? <Host userInfo={userInfo} /> : <Guest/>
    // <Guest/>
  );
}

// export default QuadcoApp;
export default connect(mapStateToProps, mapDispatchToProps)(QuadcoApp);