import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import api from '../../data/api'
import { TRUCK_VIEW_PAGE_LOADED, TRUCK_VIEW_PAGE_UNLOADED } from '../../constants/actionTypes';

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import ThumbTruck from '../../assets/images/img/thumb_truck.png';
import TruckEditorModal from './TruckEditorModal';
import TruckSettingsModal from './TruckSettingsModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShowSnackbar from '../common/ShowSnackbar.jsx';

const useStyles = makeStyles(theme => ({ 
    container: {
        backgroundColor: 'f1f2f6'
    },
    heading: {
        padding: 16,
        backgroundColor: '#FFFFFF',
    },
    pageTitle: {
        fontSize: '1.3rem'
    },
    btnApproval: {
        backgroundColor: '#1c3e5d',
        color: '#FFFFFF'
    },
    btnEdit: {
        backgroundColor: '#1c3e5d',
        minWidth: 100,
        color: '#FFFFFF',
        marginLeft: 10
    },
    boxMap : {
        padding: 16,
        minHeight: 400,
        margin: '0 20px 20px 20px',
        borderRadius: 15,
        // border: '1px solid #1c3e5d',
    },
    boxMapImg : {
        width: '90%;'
    },
    boxDetails: {
        padding: 26,
        backgroundColor: '#FFFFFF',
        fontSize: 12
    },
    loading: {
      minHeight: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fff'
    },
    proggressRoot: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        minHeight: '30vh',
    },
 }));

// const {trucks} = mockData.data;
const mapStateToProps = state => ({
    ...state.trucks,
    truck: state.trucks.truck
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload) =>
      dispatch({ type: TRUCK_VIEW_PAGE_LOADED, payload }),
    onUnload: () =>
      dispatch({  type: TRUCK_VIEW_PAGE_UNLOADED })
});

function ViewTruck (props) {
    const classes = useStyles();
    let { id } = useParams();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [settingsModalOpen, setSettingsModalOpen] = React.useState(false);

    const handleTruckEditorLaunch = () => {
        setModalOpen(true);
    };

    const handleTruckEditorClose = () => {
        setModalOpen(false);
    };

    const handleTruckSettingsLaunch = () => {
        setSettingsModalOpen(true);
    };

    const handleTruckSettingsClose = () => {
        setSettingsModalOpen(false);
    };
    
    useEffect(() => {
        props.onLoad(api.Trucks.get(id));
        return () => {
            props.onUnload();
        }
    }, []);

    let {truck} = props;
    
    if (!props.truck) {
        return ( <div className={classes.proggressRoot}><CircularProgress size={24} thickness={4} /></div> );
    }
    if (props.truck.length === 0) {
        return ( <div className={classes.loading}> No trucks are here... yet. </div> );
    }
    return (
        <Box className={classes.container}>
            {props.responseStatus ? 
                <ShowSnackbar 
                    opened={props.responseStatus ? true : false}
                    variant={props.responseStatus ? props.responseStatus : null}
                    message={props.responseMessage!=="" ? props.responseMessage : false} />
                : ''}
            <Grid container className={classes.heading}> 
                <Grid item xs={6}>
                    <Typography variant='h5' className={classes.pageTitle}>TRUCK DETAILS</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                    <Button className={classes.btnEdit} onClick={()=> {props.history.push(`/`)}}>BACK TO TRUCKS</Button>
                    <Button 
                        variant="contained" 
                        className={classes.btnEdit} 
                        onClick={handleTruckEditorLaunch}>EDIT</Button>
                    {modalOpen ? 
                        <TruckEditorModal 
                            truck={truck}
                            opened={modalOpen}
                            handleClose={handleTruckEditorClose}
                            mode="update" /> 
                        : ''}
                    
                    <Button 
                        variant="contained" 
                        className={classes.btnEdit} 
                        onClick={handleTruckSettingsLaunch}>SETTINGS</Button>
                    {settingsModalOpen ? 
                        <TruckSettingsModal 
                            truck={truck}
                            opened={settingsModalOpen}
                            handleClose={handleTruckSettingsClose} />
                        : '' }
                </Grid>
            </Grid>
            <Box className={classes.detailsBody}>
                <Grid container>
                    <Grid item xs={6}>
                        <Box className={classes.boxDetails}>
                            <Box>
                                <div><Typography variant='h4'>{truck.reg_no}</Typography></div>
                                <div><Typography variant='h6'><strong>Till Number:</strong> {truck.till_number}</Typography></div>
                                <div><Typography variant='h6'><strong>Route:</strong> {truck.route_name}</Typography></div>
                                <div><Typography variant='h6'><strong>Delivery agent:</strong> {truck.delivery_agent_name}</Typography></div>
                            </Box>
                        </Box>
                    </Grid> 
                    <Grid item xs={4}>
                        <Box className={classes.boxMap}>
                            <img src={ThumbTruck} alt="none" className={classes.boxMapImg} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(ViewTruck)