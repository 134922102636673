// import article from './reducers/article';
// import articleList from './reducers/articleList';
import auth from './reducers/auth';
import { combineReducers } from 'redux';
import common from './reducers/common';
import users from './reducers/users';
import trucks from './reducers/trucks';
import truckroutes from './reducers/truckroutes';
import invoices from './reducers/invoices';
import salesreturns from './reducers/salesreturns';
import debtors from './reducers/debtors';
// import editor from './reducers/editor';
// import home from './reducers/home';
import profile from './reducers/profile';
// import settings from './reducers/settings';
import { routerReducer } from 'react-router-redux';

export default combineReducers({
  // article,
  // articleList,
  auth,
  common,
  users,
  trucks,
  truckroutes,
  invoices,
  salesreturns,
  debtors,
  profile,
  // settings,
  router: routerReducer
});
