import {
  APP_LOAD,
  LOAD_AVATAR,
  CURRENT_MODULE_LOADED,
  LOAD_ORGANISATIONS,
  REDIRECT,
  LOGOUT,
  ARTICLE_SUBMITTED,
  SETTINGS_SAVED,
  LOGIN,
  REGISTER,
  DELETE_ARTICLE,
  ARTICLE_PAGE_UNLOADED,
  EDITOR_PAGE_UNLOADED,
  HOME_PAGE_UNLOADED,
  PROFILE_PAGE_UNLOADED,
  PROFILE_FAVORITES_PAGE_UNLOADED,
  SETTINGS_PAGE_UNLOADED,
  LOGIN_PAGE_UNLOADED,
  REGISTER_PAGE_UNLOADED,
  TOGGLE_SUMMARY,
  UPDATE_SEARCH_FIELD,
  USER_EDITOR_MODAL_LOADED,
  UPDATE_FIELD_PROFILE,
  PROFILE_VIEW_PAGE_UNLOADED,
  SWITCH_ORG_ACCOUNT,
  ASYNC_START,
  ASYNC_END,
  LOAD_SUMMARY,
  LOAD_SUMMARIES,
  // SEARCH_TRUCKS_SUBMITTED,
  // SEARCH_ROUTES_SUBMITTED,
  // SEARCH_INVOICES_SUBMITTED,
  // SEARCH_SALES_RETURNS_SUBMITTED,
  // SEARCH_USERS_SUBMITTED
  // SEARCH_SUBMITTED
} from '../constants/actionTypes';

const defaultState = {
  appName: 'Quadco App',
  token: null,
  viewChangeCounter: 0
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        appLoaded: true,
        currentUser: action.payload ? action.payload[0] : null,
        organisations: action.payload ? action.payload[1] : null,
        truckroutes: action.payload ? action.payload[2] : null,
        users: action.payload ? action.payload[3] : null,
        trucks: action.payload ? action.payload[4] : null,
        toggleSummary: true,
        loggedOut: true,
      };
    case LOAD_AVATAR:
      return {
        ...state,
        currentUser: action.payload ? action.payload : null,
      };
    case CURRENT_MODULE_LOADED: 
      return {
        ...state,
        current_module: action.module
      }
    case LOAD_SUMMARIES:
      return {
        ...state,
        summary: action.payload ? action.payload : null
      };
    case LOAD_ORGANISATIONS:
      return {
        ...state,
        organisations: action.payload ? action.payload : null,
        currentUser: {
          ...state.currentUser,
          org_id: action.payload && action.payload.data ? action.payload.data[0].id : null
        }
      };
    case SWITCH_ORG_ACCOUNT:
        return { ...state, 
          currentUser: {
            ...state.currentUser,
            org_id: action.payload
          } 
        };
    case UPDATE_SEARCH_FIELD:
      return { ...state, 
        search: {
          ...state.search,
          [action.key]: action.value
        } 
      };
    // case SEARCH_SUBMITTED: 
    // return {}
    case REDIRECT:
      return { ...state, redirectTo: null };
    case LOGOUT:
      return { 
        ...state, 
        redirectTo: action.error ? null : '/', 
        token: null, 
        currentUser: null, 
        organisations: null,
        trucks: null, 
        profile: null, 
        loggedOut: true, 
      };
    case ARTICLE_SUBMITTED:
      const redirectUrl = `/article/${action.payload.article.slug}`;
      return { ...state, redirectTo: redirectUrl };
    case SETTINGS_SAVED:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        currentUser: action.error ? null : action.payload.Response
      };
    case TOGGLE_SUMMARY: 
      return {
        ...state,
        toggleSummary: action.payload
      }
    case LOGIN:
    case REGISTER:
      return {
        ...state,
        redirectTo: action.error ? null : '/',
        token: action.error ? null : (action.payload ? action.payload.Response.data.access_token : null),
        currentUser: action.error ? null : (action.payload ? action.payload.Response : null),
        loggedOut: action.payload && action.payload.Response.data && action.payload.Response.data.access_token ? false : true,
      };
    case USER_EDITOR_MODAL_LOADED:
      return {
        ...state,
        responseStatus: null,
        responseMessage: null,
      };
    case UPDATE_FIELD_PROFILE:
      return { 
        ...state, 
        currentUser: {
          ...state.currentUser,
          [action.key]: action.value
        } 
      };
      
    case PROFILE_VIEW_PAGE_UNLOADED: 
      return {
        ...state,
        responseStatus: null,
        responseMessage: null,
      };
    case DELETE_ARTICLE:
      return { ...state, redirectTo: '/' };
    case ARTICLE_PAGE_UNLOADED:
    case EDITOR_PAGE_UNLOADED:
    case HOME_PAGE_UNLOADED:
    case PROFILE_PAGE_UNLOADED:
    case PROFILE_FAVORITES_PAGE_UNLOADED:
    case SETTINGS_PAGE_UNLOADED:
    case LOGIN_PAGE_UNLOADED:
    case REGISTER_PAGE_UNLOADED:
      return { ...state, viewChangeCounter: state.viewChangeCounter + 1 };
    case ASYNC_START:
      return { 
        ...state, 
        inProgress: true 
      };
      break;

    case ASYNC_END:
      return { 
        ...state, 
        inProgress: false 
      };
      break;
    default:
      return state;
  }
};
