import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

//Theme
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles({
    paperContainer: {
      width: 350,
    },
    gridContainer: {
        minHeight: 40,
        alignItems: 'center',
    },
    alignCentre: {
        alignItems: 'center',
        display: 'flex',
    },
    paddTitle: {
        paddingLeft: 12,
        fontSize: 12,
    },
    btnClearCont: {
        textAlign: 'right',
        paddingRight: 16
    },
    btnClear: {
        cursor: 'pointer',
        fontSize: 12
    },
    listItemLi: {
        padding: '6px 12px',
        backgroundColor: '#FDF5F5',
        fontSize: 12
    }
  });

export default function NotificationList(props) {
    const classes = useStyles();

    if (!props.notifications) {
        return ( <span/> );
    }
    if (props.notifications.length === 0) {
        return ( <div className={classes.loading}> ... </div> );
    }

    return (
        <Menu
            id="simple-menu"
            anchorEl={props.anchorEl}
            keepMounted
            open={Boolean(props.anchorEl)}
            onClose={props.onHideNotification}
        >
            <Paper className={classes.paperContainer}></Paper>
                <Grid container className={classes.gridContainer}>
                    <Grid item xs={6} className={classes.alignCentre}>
                        <Box>
                            <Typography className={classes.paddTitle}>Notifications </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.btnClearCont}>
                        <Link onClick={props.onClearNotifications} className={classes.btnClear} variant='subtitle1' color="inherit">Mark all as read</Link>
                    </Grid>
                </Grid>
                {props.notifications ? props.notifications.map(notification=> (
                    <MenuItem 
                        key={notification.id}
                        onClick={props.onHideAccounts} 
                        className={classes.listItemLi}
                        >{notification.name}</MenuItem>
                )) : ''}
            <Paper className={classes.root}></Paper>
      </Menu>
    )
}
