import React, { useEffect } from 'react'
import { TRUCK_LIST_PAGE_LOADED, TRUCK_LIST_PAGE_UNLOADED } from '../../constants/actionTypes';
import { connect } from 'react-redux';
import api from '../../data/api'
import ListTrucks from './ListTrucks';

const mapStateToProps = state => ({
    currentUser: state.common.currentUser,
    isSearchResults: state.trucks.search_results ? state.trucks.search_results : false,
});
  
const mapDispatchToProps = dispatch => ({
    onLoad: (payload, pager) =>
      dispatch({ type: TRUCK_LIST_PAGE_LOADED, payload }),
    onUnload: () =>
      dispatch({  type: TRUCK_LIST_PAGE_UNLOADED })
});


function Trucks(props) {
    useEffect(() => {
        if(!props.isSearchResults) {
            props.onLoad(api.Trucks.all(), null);
            return () => {
                props.onUnload();
            }
        }
    }, []);
    
    return (
        <React.Fragment>
            <ListTrucks trucks={props && props.trucks } />
        </React.Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Trucks);
