import {
  SET_SALES_RETURNS_PAGE,
  SALES_RETURNS_LIST_PAGE_LOADED,
  SALES_RETURNS_LIST_APPLY_FILTER,
  SALES_RETURNS_LIST_PAGE_UNLOADED,
  SALES_RETURNS_VIEW_PAGE_LOADED,
  SALES_RETURNS_VIEW_PAGE_UNLOADED,
  // ASYNC_START,
  DELETE_SALES_RETURNS,
  SEARCH_SALES_RETURNS_SUBMITTED,
  SALES_RETURN_VALIDATED,
  SALES_RETURN_LIST_FILTER_BY_PAYMENT_MODE
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SALES_RETURNS_LIST_PAGE_LOADED:
      return {
        ...state,
        pager: action.pager,
          salesreturns: action.payload ? action.payload.data : null,
          // salesreturns_count: action.payload ? action.payload.meta.pagination.count : null,
          // pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: 0,
          meta: action.meta
      };

    case SET_SALES_RETURNS_PAGE:
      return {
        ...state,
        salesreturns: action.payload ? action.payload.data : null,
          // salesreturns_count: action.payload ? action.payload.meta.pagination.count : null,
          // pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: action.page
      };
    case SEARCH_SALES_RETURNS_SUBMITTED:
      return {
        ...state,
        pager: action.pager,
          salesreturns: action.payload ? action.payload.data : null,
          currentPage: action.page,
          search_results: action.payload.data ? true : false,
          inProgress: true
      };
    case SALES_RETURNS_LIST_APPLY_FILTER:
      return {
        ...state,
        pager: action.pager,
          salesreturns: action.payload ? action.payload.data : null,
          // salesreturns_count: action.payload ? action.payload.meta.pagination.count : null,
          // pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: 0
      };
    case SALES_RETURN_LIST_FILTER_BY_PAYMENT_MODE:
      if (action.payload && action.payload.invoice_date) {
        localStorage.setItem('current_sales_returns_filter_date', action.payload.invoice_date);
      } else {
        localStorage.removeItem('current_sales_returns_filter_date');
      }
      if (action.payload && action.payload.route) {
        localStorage.setItem('current_sales_returns_filter_route', JSON.stringify(action.payload.route));
      } else {
        localStorage.removeItem('current_sales_returns_filter_route');
      }
      return {
        ...state,
        pager: action.pager,
          salesreturns: action.payload ? action.payload.data : null,
          sales_returns_date: action.payload ? action.payload.invoice_date : null,
          route: action.payload ? action.payload.route : null,
          current_sales_returns_filter: {
            selected_date: action.payload ? action.payload.invoice_date : null,
            selected_route: action.payload ? action.payload.route : null,
          },
          // invoicesCount: action.payload ? action.payload.invoicesCount : null,
          search_results: false,
          currentPage: 0
      };

    case SALES_RETURNS_LIST_PAGE_UNLOADED:
      return {
        ...state,
        responseStatus: null,
          responseMessage: null,
      }
      case SALES_RETURNS_VIEW_PAGE_LOADED:
        return {
          ...state
        };

      case SALES_RETURN_VALIDATED:
        return {
          ...state,
          inProgress: null,
            responseStatus: action.payload ? action.payload.Response.status : null,
            responseMessage: action.payload ? action.payload.Response.message : null,
        };


      case SALES_RETURNS_VIEW_PAGE_UNLOADED:
        return {
          ...state,
          responseStatus: null,
            responseMessage: null,
        }

        case DELETE_SALES_RETURNS:
          const ID = action.truckID
          return {
            ...state,
            salesreturns: state.sales_returns.filter(sales_return => sales_return.id !== ID)
          };

        default:
          return state;
  }
};