import React from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'

// import ShowError from '../../utils/ShowError';
import api from '../../data/api'
import { connect } from 'react-redux';
import { UPDATE_FIELD_AUTH, LOGIN, LOGIN_PAGE_UNLOADED } from '../../constants/actionTypes';

//Theme
// import clsx from 'clsx';
import { fade, withStyles, makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import imgLogo from '../../assets/images/img/logo_300_2.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';


const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: 10,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    root: {
      height: '100vh',
    },
    imageBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    image: {
      maxWidth: 200
    },
    paper: {
      margin: '64px auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '70%',
      marginTop: '25%',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    titleHeader:{
      color: '#FFFFFF',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(5),
      width: '100%'
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    label: {
      color: '#FFFFFF',
      fontSize: 18,
      textTransform: 'uppercase',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    rightside: {
      backgroundColor: '#1c3e5d'
    },
    btnWrapper: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      position: 'relative',
    },
    btnSubmit: {
        backgroundColor: '#db3332',
        margin: theme.spacing(2, 0, 2),
        padding: '10px 0',
        fontSize: 14,
        "&:hover": {
          backgroundColor: '#9a2828',
        },
    },
    buttonProgress: {
      color: '#FFFFFF',
      position: 'absolute',
      top: '50%',
      right: '5%',
      marginTop: -12,
      marginLeft: -12,
    },
    disabledBtnSubmit: {
      backgroundColor: '#9a2828'
    },
    txtInput: {
      backgroundColor: '#FFFFFF'
    },
    error_msg: {
      color: 'red',
      marginBottom: 30,
      background: 'rgba(69, 125, 177, 0.39)',
      padding: '5px 10px',
      borderRadius: 5,
    },
    policyWrapper: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(1),
      position: 'relative',
    },
    aLink: {
      textDecoration: 'none',
      color: '#FFFFFF',
      margin: '5px',
      "&:hover": {
        color: '#78a8d4',
        textDecoration: 'underline',
      },
    }
  }));

const btnTheme = createMuiTheme({
  palette: {
    backgroundColor: '#db3332',
    action: {
      disabledBackground: '#9a2828',
      disabled: '#FFFFFF'
    }
  }
})

  const mapStateToProps = state => ({ ...state.auth });


  const mapDispatchToProps = dispatch => ({
    onUpdateField: (key, value) => 
      dispatch({ type: UPDATE_FIELD_AUTH, key, value }),
    onSubmit: (email, password) => 
      dispatch({ type: LOGIN, payload: api.Auth.login(email, password) }),
    onUnload: () => 
      dispatch({ type: LOGIN_PAGE_UNLOADED })
  });

function SignIn(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);

    const updateFieldEvent = key => ev => props.onUpdateField(key, ev.target.value);
    const changeEmail = updateFieldEvent('email');
    const changePassword = updateFieldEvent('password');

    let error_msg = props.errors;
    const handleSubmitForm = ev => {
      ev.preventDefault();
      
      if(props.email.length >0  && props.password.length >0) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 5000);
        props.onSubmit(props.email, props.password);
      } 
    };

    return (
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={6} md={6} className={classes.imageBox} >
            <img src={imgLogo} alt="logo" className={classes.image} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.rightside} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <Box className={classes.titleHeader} align="left">
                {/* <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar> */}
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
              </Box>
              
              {error_msg && error_msg!=="Success"? <div className={classes.error_msg}>{error_msg}</div> : "" }
              
              <form className={classes.form} >
                {/* noValidate */}
                
                <InputLabel className={classes.label} shrink htmlFor="bootstrap-input">
                  Username / Email Address *
                </InputLabel>
                <BootstrapInput 
                  fullWidth 
                  required
                  id="email-input"
                  type="text"
                  placeholder="Username / Email Address"
                  error={props.email_error}
                  helperText={props.email_helperText}
                  // value={email}
                  onChange={changeEmail} />
                
                <InputLabel className={classes.label} shrink htmlFor="bootstrap-input">
                  Password *
                </InputLabel>
                <BootstrapInput 
                  fullWidth 
                  id="password-input"
                  type="password"
                  placeholder="Password"
                  error={props.password_error}
                  helperText={props.password_helperText}
                  // value={password}
                  onChange={changePassword}
                   />
                <div className={classes.btnWrapper}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.btnSubmit}
                    disabled={props.inProgress}
                    onClick={handleSubmitForm} 
                  >
                    SIGN IN TO QUADCO DASHBOARD
                  </Button>
                  {/* <CircularProgress size={24} className={classes.buttonProgress} /> */}
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                <div className={classes.policyWrapper}>
                  <Typography variant="subtitle1" color="textSecondary" align="center" alignitems="center" className={classes.footerCopy}>
                      <Link color="inherit" href="/policy" to="/policy" className={classes.aLink} exact >
                          Our privacy policy
                      </Link>
                  </Typography>
                </div>
              </form>
            </div>
          </Grid>
        </Grid>
      );
}


export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
