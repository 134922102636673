import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import api from '../../data/api'
import { ROUTE_STOPS_EDITOR_MODAL_LOADED, UPDATE_FIELD_ROUTE_STOP, TRUCK_ROUTE_STOP_SUBMITTED, ROUTE_STOP_EDITOR_MODAL_UNLOADED } from '../../constants/actionTypes';


import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'block',
      flexWrap: 'wrap',
    },
    textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1)
    },
    btnSubmit: {
        backgroundColor: '#1c3e5d',
        width: '100%',
        color: '#FFFFFF',
        minHeight: 50
    },
    dialogueActions: {
        display: 'block',
        width: '100%',
        padding: '10px 24px 30px',
    }
  }));


  const mapStateToProps = state => ({ 
    //   ...state.state.truckroutes.truckroute,
    truckroute: state.truckroutes.truckroute
});

  const mapDispatchToProps = dispatch => ({
    onLoad: (payload) => 
        dispatch({ type: ROUTE_STOPS_EDITOR_MODAL_LOADED, payload }),
    onUpdateField: (key, value) => 
      dispatch({ type: UPDATE_FIELD_ROUTE_STOP, key, value }),
    onSubmit: (payload, response) => 
        dispatch({ type: TRUCK_ROUTE_STOP_SUBMITTED, payload, response }),
    onUnload: (payload) => 
      dispatch({ type: ROUTE_STOP_EDITOR_MODAL_UNLOADED, payload })
  });

function TruckRouteStopsEditorModal(props) {
    const classes = useStyles();
  
    const [stopnameError, setStopnameError] = React.useState(false);
    const [stopnameErrorText, setStopnameErrorText] = React.useState("Please enter route stop name");

    const [shopnameError, setShopnameError] = React.useState(false);
    const [shopnameErrorText, setShopnameErrorText] = React.useState("Enter multiple names separated by comma , ");

    const [coordinatesError, setCoordinatesError] = React.useState(false);
    const [coordinatesErrorText, setCoordinatesErrorText] = React.useState("Digital format: xx.xxxxxx, -xx.xxxxxx");

    const updateFieldEvent = key => ev => props.onUpdateField(key, ev.target.value);
    //const changeName = updateFieldEvent('name');
    //const changeShopName = updateFieldEvent('shop_names');
    //const changeCoordinates = updateFieldEvent('coordinates');
    const checkk_lat = new RegExp(/^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/);
    const checkk_long = new RegExp(/^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/);
    
    const changeName = ev => {
      const val = ev.target.value;
      setStopnameError(false);
      setStopnameErrorText("");
      props.onUpdateField('name',val);
    }

    const changeShopName = ev => {
      const val = ev.target.value;
      setShopnameError(false);
      setShopnameErrorText("");
      props.onUpdateField('shop_names',val);
  }
    const changeCoordinates = ev => {
      const val = ev.target.value;
      const validNumber = new RegExp(/^[\d,\.-]*$/);
      if(!validNumber.test(val)) {
        setCoordinatesError(true);
        setCoordinatesErrorText("Please enter only numbers, remove any spaces");
        return false;
      }
      props.onUpdateField('coordinates', val);
      setCoordinatesError(false);
    }
    
    useEffect(() => { 
      const selectedRouteStopId = props.selectedRouteStopId ? props.selectedRouteStopId : null;
      const selectedRouteStop = props.truckroute.stops.length ? props.truckroute.stops.filter(r_stop => Number(r_stop.id) === Number(selectedRouteStopId))[0] : null;
      let mSelectedStop=null
      if(selectedRouteStopId) {
        mSelectedStop = {
          id: selectedRouteStop.id,
          name: selectedRouteStop.name,
          coordinates: selectedRouteStop.coordinates,
          shop_names: selectedRouteStop.shop_names.join(),
        }
      }
      props.onLoad(mSelectedStop);
    }, []);

    const handleSubmitForm = ev => {
      ev.preventDefault();
      let valid = true;
      if(!props.truckroute.current_stop || !props.truckroute.current_stop.name) {
        setStopnameError(true);
        setStopnameErrorText("Please enter route stop name");
        valid = false;
      } else {
        setStopnameError(false);
        setStopnameErrorText("");
      }
      
      if(!props.truckroute || !props.truckroute.current_stop || !props.truckroute.current_stop.shop_names) {
        setShopnameError(true);
        setShopnameErrorText("Please enter shop names");
        valid = false;
      } else {
        setShopnameError(false);
        setShopnameErrorText("");
      }
      
      if(!props.truckroute || !props.truckroute.current_stop || !props.truckroute.current_stop.coordinates) {
        setCoordinatesError(true);
        setCoordinatesErrorText("Please enter coordinates");
        valid = false;
      } else {
        setCoordinatesError(false);
        setCoordinatesErrorText("");
      }
      if(!valid) {
        return false;
      }
      
      if(props.truckroute.current_stop && props.truckroute.current_stop.name && props.truckroute.current_stop.shop_names && props.truckroute.current_stop.coordinates) {
        let coordinates = props.truckroute.current_stop.coordinates.split(",");
        
        const lat = coordinates.length ? (coordinates[0]).trim() : null;
        const long = coordinates.length && coordinates[1] ? (coordinates[1]).trim() : null;
        let valid_lat = checkk_lat.test(lat);
        let valid_long = checkk_long.test(long);
        let valid_lat_long = null;
        
        if(valid_lat && valid_long) {
          valid_lat_long = valid_lat + ' ' + valid_long;
        } else {
          setCoordinatesError(true);
          setCoordinatesErrorText("Digital format: xx.xxxxxx, -xx.xxxxxx");
          return false;
        }
        const truckroutestop = {
            name: props.truckroute.current_stop.name,
            shop_names: props.truckroute.current_stop.shop_names,
            coordinates: props.truckroute.current_stop.coordinates,
        };
        const reduxTruckRouteStop = {
          name: props.truckroute.current_stop.name,
          shop_names: props.truckroute.current_stop.shop_names.split(','),
          coordinates: props.truckroute.current_stop.coordinates,
        };
  
        //submit post
        const mStopId = props.selectedRouteStopId ? props.selectedRouteStopId : null;
        const promise = props.mode && props.mode==="update" 
          ? api.RouteStops.update(truckroutestop, props.truckroute.id, mStopId) 
          : api.RouteStops.create(truckroutestop, props.truckroute.id) ;

        promise.then((response)=> {
          props.onSubmit(reduxTruckRouteStop, response);
          //then get a new list
          props.onUnload(api.RouteStops.all(props.truckroute.id))
        })

        //close modal
        props.handleClose();
      } 
    };
    return (
        <Dialog open={props.opened} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <form className={classes.container} noValidate autoComplete="off">
                <DialogTitle id="form-dialog-title">ENTER ROUTE STOPS DETAILS</DialogTitle>
                <DialogContent>
                    
                    <TextField
                        id="route_name"
                        label="STOP NAME"
                        placeholder="Input route name"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 8 }}
                        variant="outlined"
                        error={stopnameError}
                        helperText={stopnameErrorText}
                        value={props.truckroute.current_stop && props.truckroute.current_stop.name ? props.truckroute.current_stop.name : null}
                        onChange={changeName}
                        />
                    
                    <TextField
                        id="shop_name"
                        label="SHOPS NAMES"
                        placeholder="Input shop names"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 8 }}
                        variant="outlined"
                        error={shopnameError}
                        helperText={shopnameErrorText}
                        value={props.truckroute.current_stop && props.truckroute.current_stop.shop_names ? props.truckroute.current_stop.shop_names : null}
                        onChange={changeShopName}
                        />
                    
                    <TextField
                        id="coordinates"
                        label="COORDINATES"
                        placeholder="Input coordinates"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 8 }}
                        variant="outlined"
                        error={coordinatesError}
                        helperText={coordinatesErrorText}
                        value={props.truckroute.current_stop && props.truckroute.current_stop.coordinates ? props.truckroute.current_stop.coordinates : null}
                        onChange={changeCoordinates}
                        />
                    
                </DialogContent>
                <DialogActions className={classes.dialogueActions}>
                    <Button 
                        fullWidth
                        onClick={handleSubmitForm} 
                        className={classes.btnSubmit}>
                        SUBMIT
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckRouteStopsEditorModal);