import React from 'react'
import { Link } from 'react-router-dom';

//Theme
import { fade, withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '18.5px 14px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: 'url(https://source.unsplash.com/random)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: '32px auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '70%'
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    titleHeader:{
      color: '#FFFFFF',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      width: '100%'
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    label: {
      color: '#FFFFFF',
      fontSize: 24,
      textTransform: 'uppercase',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    rightside: {
      backgroundColor: '#1c3e5d'
    },
    btnSubmit: {
        backgroundColor: '#db3332',
        margin: theme.spacing(3, 0, 2),
        padding: '10px 0',
        fontSize: 24
    },
    txtInput: {
      backgroundColor: '#FFFFFF'
    },
    signinLink : {
        color: '#FFFFFF'
    },
    terms: {
        color: '#FFFFFF'
    }
  }));

export default function SignUp() {
    const classes = useStyles();
    return (
        <Grid container component="main" className={classes.root}>
          <CssBaseline />
          <Grid item xs={false} sm={6} md={6} className={classes.image} />
          <Grid item xs={12} sm={6} md={6} className={classes.rightside} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <Box className={classes.titleHeader}>
                <Grid container>
                    <Grid item xs={6} align="left">
                        <Typography component="h1" variant="h5">
                            Sign up
                        </Typography>
                    </Grid>
                    <Grid item xs={6} align="right">
                        <Link className={classes.signinLink} to={`/`}>Sign in using your account</Link>
                    </Grid>
                </Grid>
              </Box>
              <form className={classes.form} noValidate>
                
                {/**First name**/}
                <InputLabel className={classes.label} shrink htmlFor="first_name">
                  FIRST NAME *
                </InputLabel>
                <BootstrapInput fullWidth id="first_name" />
                
                {/**Last name**/}
                <InputLabel className={classes.label} shrink htmlFor="last_name">
                  LAST NAME *
                </InputLabel>
                <BootstrapInput fullWidth id="last_name" />
                
                {/**Phone**/}
                <InputLabel className={classes.label} shrink htmlFor="phone_input">
                  Phone Number *
                </InputLabel>
                <BootstrapInput type="password" fullWidth id="phone_input" />
                
                {/**Email Address**/}
                <InputLabel className={classes.label} shrink htmlFor="email_input">
                  Email Address *
                </InputLabel>
                <BootstrapInput fullWidth id="email_input" />
                
                {/**password Address**/}
                <InputLabel className={classes.label} shrink htmlFor="password_input">
                  Password *
                </InputLabel>
                <BootstrapInput type="password" fullWidth id="password_input" />
                
                <FormControlLabel
                  control={<Checkbox value="remember" color="white" />}
                  className={classes.terms}
                  label="I agree to Quadco Privacy Policy and Terms of Service"
                />
                
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.btnSubmit}
                >
                  SIGN IN TO QUADCO DASHBOARD
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>
      );
}
