import React from 'react';
import { Link, NavLink, useParams } from 'react-router-dom'

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
//import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles(theme => ({ 
    footer: {
        backgroundColor: "#555555",
        minHeight: 70,
        marginLeft: 0,
        marginRight: 0,
        maxWidth: '100%',
        fontSize: 12
    },
    footerCopy : {
        color: '#FFFFFF',
        fontSize: 12
    },
    aLink: {
        textDecoration: 'none',
        color: '#FFFFFF',
        margin: '5px',
      }
 }));

function Footer() {
    const classes = useStyles();
    return (
        <Container className={classes.footer}>
            <Box
                justifyContent="center"
                display="flex"
                alignItems="center"
                css={{ height: 70 }}
            >
                <div>
                    <Typography variant="subtitle1" color="textSecondary" align="center" alignitems="center" className={classes.footerCopy}>
                        {'©'}
                        <Link color="inherit" href="#" className={classes.aLink} >
                            {new Date().getFullYear()} Quadco 317 Ltd. All Rights Reserved
                        </Link>{' '}
                        {'.'}
                        <Link color="inherit" href="/policy" to="/policy" className={classes.aLink} exact >
                            Privacy Policy
                        </Link>
                    </Typography>
                </div>
            </Box>
        </Container>
    )
    
}
export default Footer;