import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import api from '../../../data/api'
import { connect } from 'react-redux';
import { USER_AUTH_MODAL_LOADED, UPDATE_FIELD_D_AGENT, USER_AUTH_SUBMITTED, USER_AUTH_MODAL_UNLOADED } from '../../../constants/actionTypes';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1)
    },
    btnSubmit: {
        backgroundColor: '#1c3e5d',
        width: '100%',
        color: '#FFFFFF',
        minHeight: 50
    },
    dialogueActions: {
        display: 'block',
        width: '100%',
        padding: '10px 24px 30px',
    },

    passcodePaper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.23)'
      },
      input: {
        marginLeft: theme.spacing(1),
        flex: 1,
      },
      iconButton: {
        padding: 10,
        backgroundColor: '#999999',
      },
  }));

const mapStateToProps = state => ({ 
    user: state.users.user 
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload) => 
        dispatch({ type: USER_AUTH_MODAL_LOADED, payload }),
    onChangeUsername: value => 
      dispatch({ type: UPDATE_FIELD_D_AGENT, key: 'username', value }),
    onChangePassword: value => 
      dispatch({ type: UPDATE_FIELD_D_AGENT, key: 'password', value }),
    onSubmit: (payload) => 
        dispatch({ type: USER_AUTH_SUBMITTED, payload }),
  });

function UserAuthModal(props) {
    // console.log('UserAuthModal > props:', props);
    
    const classes = useStyles();
    const [isUpdate, setIsUpdate] = React.useState(false);
    const [passcodeError, setPasscodeError] = React.useState(false);
    
    // console.log('handleEditForm load', props);
    const changeUsername = ev => props.onChangeUsername(ev.target.value);
    const changePassword = ev => props.onChangePassword(ev.target.value);
    // const handleGeneratePasscode = () => {
    //     return console.log('code:', Math.random(0, 1000) * 10000);
    // };

    useEffect(() => {
        const promise = api.Users.getCreds(props.user.id);
        
        promise.then(response => {
            if(response.username !=null) {
                setIsUpdate(true);
            }
        })
        .catch(error=> {
            console.log('error', error);
        }) 
        props.onLoad(promise);
    }, []);

    const handleSubmitForm = ev => {
        ev.preventDefault();
        
        if(props.user.password.length > 4) {
            setPasscodeError(true);
            return false;
        }
        if(props.user.username && props.user.password) {
            const user = {
                username: props.user.username,
                password: props.user.password,
                id: props.user.id
            };
    
            //submit post
            let promise = null;
            if(isUpdate) {
                promise = api.Users.updateCreds(user);
            } else {
                promise = api.Users.createCreds(user);
            }
            
            
            promise.then((response)=> {
                //then get a new list
                props.onSubmit(response);
            })
    
            //close modal
            props.handleClose();
        }
    };


    // let {id, name, id_number, phone_number} = props.user;

    return (
        <Dialog open={props.opened} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <form 
                className={classes.container} 
                noValidate 
                autoComplete="off">
                <DialogTitle id="form-dialog-title">SET USER LOGIN DETAILS</DialogTitle>
                <DialogContent>
                    
                    <TextField
                        id="username"
                        label="USERNAME"
                        fullWidth
                        margin="normal"
                        size="small"
                        style={{ marginBottom: 8 }}
                        variant="outlined"
                        value={props.user.username ? props.user.username : null}
                        onChange={changeUsername}
                        />

                    <TextField
                        id="password"
                        label="PASS CODE"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 8 }}
                        variant="outlined"
                        helperText="Enter only digits. Max 4 digits"
                        value={props.user.password ? props.user.password : null}
                        onChange={changePassword}
                        type="number"
                        error={passcodeError}
                        InputProps={{ inputProps: { min: 0, max: 4 } }}
                        />
            
                </DialogContent>
                <DialogActions className={classes.dialogueActions}>
                    <Button 
                        fullWidth
                        onClick={handleSubmitForm} 
                        className={classes.btnSubmit}
                        type="submit">
                        SUBMIT
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAuthModal);