import React from 'react';
import { Link, NavLink, useParams } from 'react-router-dom'

//Theme
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

//icons
import TrucksIcon from '../../../assets/images/img/ic_truck.png';
import TrucksRoutesIcon from '../../../assets/images/img/ic_trackroutes.png';
import SettingsIcon from '../../../assets/images/img/ic_settings.png';
import InvoicesIcon from '../../../assets/images/img/ic_invoices.png';
// import StatisticsIcon from '../../../assets/images/img/ic_statistics.png';
import DebtorsIcon from '../../../assets/images/img/ic_debtors.png';


const drawerWidth = 215;

const useStyles = makeStyles (theme => ({
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        backgroundColor: '#db3332',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        // marginTop: 152,
        paddingTop: 120
      },
      drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      },
      aBtn: {
        padding: '0px 16px 0px 0'
      },
      aBtnSub: {
        padding: '0 16px 16px 0'
      },
      aLink: {
        display: 'flex',
        textDecoration: 'none',
        color: '#FFFFFF',
        padding: '16px 16px 16px 0',
        flexGrow: 1
      },
      aSubLink: {
        display: 'flex',
        textDecoration: 'none',
        color: '#FFFFFF',
        padding: '0px 16px 0px 0',
        fontWeight: 'bold'
      },
      inActiveBtn: {
        color: '#FFFFFF'
      },
      activeBtn: {
        color: '#000000'
      },
      activeBtnLink: {
        backgroundColor: '#E25C5B'
      },
      listIcon: {
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center'
      },
      menuLabel:{
        "& span": {
          fontSize: 20,
          fontWeight: 'bold',
        }
      },
      subMenuLabel:{
        "& span": {
          fontSize: 16,
        }
      },
      menuIcon: {
        height: 36,
      },
      trucksIcon : {
        maxWidth: 40
      },
      routesIcon : {
        maxWidth: 20
      },
      invoicesIcon : {
        maxWidth: 24
      },
      statisticsIcon : {
        maxWidth: 28
      },
      settingsIcon : {
        maxWidth: 28
      },
      indented: {
        paddingLeft: 30
      },
      active: {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
      },
}))


function Leftmenu() {
    const classes = useStyles();
    const params = useParams();
    const open = true;
    
    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };
    // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <Drawer
            variant="permanent"
            classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={true}
        >
            
            <List>
              <div>
                <ListItem component={NavLink} to="/" exact button activeClassName={classes.activeBtnLink} className={classes.aBtn}>
                  <NavLink to="/" exact activeClassName={classes.activeBtn} className={classes.aLink}>
                    <ListItemIcon className={classes.listIcon}><img src={TrucksIcon} className={classes.trucksIcon} alt="icon"/></ListItemIcon>
                    <ListItemText className={classes.menuLabel} primary="TRUCKS" />
                  </NavLink>
                </ListItem>
                <ListItem component={NavLink} to="/routes" exact button activeClassName={classes.activeBtnLink} className={classes.aBtn}>
                  <NavLink to="/routes" activeClassName={classes.activeBtn} className={classes.aLink}>
                    <ListItemIcon className={classes.listIcon} ><img src={TrucksRoutesIcon} className={classes.routesIcon} alt="icon" /> </ListItemIcon>
                    <ListItemText className={classes.menuLabel} primary="ROUTES" />
                  </NavLink>
                </ListItem>
                <ListItem component={NavLink} button to="/invoices" exact activeClassName={classes.activeBtnLink} className={classes.aBtn}>
                  <NavLink to="/invoices" exact activeClassName={classes.activeBtn} className={classes.aLink}>
                    <ListItemIcon className={classes.listIcon}> <img src={InvoicesIcon} className={classes.invoicesIcon} alt="icon" /> </ListItemIcon>
                    <ListItemText className={classes.menuLabel} primary="INVOICES" />
                  </NavLink>
                </ListItem>
                <ListItem component={NavLink} button to="/salesreturns" exact activeClassName={classes.activeBtnLink} className={clsx(classes.aBtnSub, classes.indented)}>
                  <NavLink to="/salesreturns" exact activeClassName={classes.activeBtn} className={classes.aSubLink}>
                    <ListItemIcon className={classes.listIcon}> </ListItemIcon>
                    <ListItemText className={classes.subMenuLabel} primary="Sales Returns" />
                  </NavLink>
                </ListItem>
                {/*<ListItem component={NavLink} button to="/debtors" exact activeClassName={classes.activeBtnLink} className={classes.aBtn}>
                  <NavLink to="/debtors" exact activeClassName={classes.activeBtn} className={classes.aLink}>
                    <ListItemIcon className={classes.listIcon}> <img src={DebtorsIcon} className={classes.statisticsIcon} alt="icon" /> </ListItemIcon>
                    <ListItemText className={classes.menuLabel} primary="DEBTORS" />
                  </NavLink>
                </ListItem>
                <ListItem button  className={classes.aBtn}>
                  <Link to="/statistics" className={classes.aLink}>
                    <ListItemIcon className={classes.listIcon}> <img src={StatisticsIcon} className={classes.statisticsIcon} alt="icon" /> </ListItemIcon>
                    <ListItemText className={classes.menuLabel} primary="STATISTICS" />
                  </Link>
                </ListItem> */}
                <ListItem button activeClassName={classes.activeBtnLink} className={classes.aBtn}>
                  <Link className={classes.aLink}>
                    <ListItemIcon className={classes.listIcon}> <img src={SettingsIcon} className={classes.settingsIcon} alt="icon" /> </ListItemIcon>
                    <ListItemText className={classes.menuLabel} primary="SETTINGS" />
                  </Link>
                </ListItem>
                <ListItem component={NavLink} button to="/agents" exact activeClassName={classes.activeBtnLink} className={clsx(classes.aBtnSub, classes.indented)}>
                  <NavLink to="/agents" exact activeClassName={classes.activeBtn} className={classes.aSubLink}>
                    <ListItemIcon className={classes.listIcon}> </ListItemIcon>
                    <ListItemText className={classes.subMenuLabel} primary="Agents" />
                  </NavLink>
                </ListItem>
              </div>
            </List>
            <Divider />
        </Drawer>
        )
    
}
export default Leftmenu;