import {
    SET_DEBTORS_PAGE,
    DEBTOR_LIST_APPLY_FILTER,
    DEBTOR_LIST_PAGE_LOADED,
    UPDATE_FIELD_EDITOR,
    DEBTOR_SUBMITTED,
    DEBTOR_LIST_PAGE_UNLOADED,
    DEBTOR_VIEW_PAGE_LOADED,
    DEBTOR_VIEW_PAGE_UNLOADED,
    DEBTOR_SUBMIT_FOR_APPROVAL,
    // ASYNC_START,
    DELETE_DEBTOR,
    DEBTOR_EDITOR_MODAL_LOADED,
    DEBTOR_EDITOR_MODAL_UNLOADED,
    DEBTOR_SETTINGS_MODAL_LOADED,
    DEBTOR_SETTINGS_MODAL_UNLOADED,
    UPDATE_DEBTOR_SETTING_FIELD_EDITOR,
    DEBTOR_SETTINGS_SUBMITTED,
    SEARCH_DEBTORS_SUBMITTED,
    CLEAR_DEBTOR_SUBMIT_RESPONSE
  } from '../constants/actionTypes';
  
  export default (state = {}, action) => {
    // console.log('action', action);
    switch (action.type) {
      case DEBTOR_LIST_PAGE_LOADED:
        return {
          ...state,
          pager: action.pager,
          debtors: action.payload ? action.payload.data : null,
          debtorsCount: action.payload ? action.payload.meta.pagination.count : null,
          pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: 0,
          meta: action.meta
        };
      case SEARCH_DEBTORS_SUBMITTED:
        return {
          ...state,
          pager: action.pager,
          debtors: action.payload ? action.payload.data : null,
          debtorsCount: action.payload ? action.payload.meta.pagination.count : null,
          pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: 0,
          inProgress: true
        };
      case SET_DEBTORS_PAGE:
        return {
          ...state,
          debtors: action.payload ? action.payload.data : null,
          debtorsCount: action.payload ? action.payload.meta.pagination.count : null,
          pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: action.page
        };
      case DEBTOR_LIST_APPLY_FILTER:
        return {
          ...state,
          pager: action.pager,
          debtors: action.payload ? action.payload.data : null,
          debtorsCount: action.payload ? action.payload.meta.pagination.count : null,
          pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: 0
        };
      case DEBTOR_VIEW_PAGE_LOADED:
        return {
          ...state,
            debtor: {
              id: action.payload ? action.payload.id : null,
              org_id: action.payload ? action.payload.org_id : null,
              shop_name: action.payload ? action.payload.shop_name : null,
              contact_number: action.payload ? action.payload.contact_number : null,
              contact_name: action.payload ? action.payload.contact_name : null,
              debt_limit: action.payload ? action.payload.debt_limit : null,
              grace_period: action.payload ? action.payload.grace_period : null,
              status: action.payload ? action.payload.status : null,
            }
        };
      case DEBTOR_EDITOR_MODAL_LOADED:
        return {
          ...state,
          debtor: action.mode && action.mode==="create" ? null : {...state.debtor},
          responseStatus: null,
          responseMessage: null,
        };
      case DEBTOR_EDITOR_MODAL_UNLOADED:
        return {};
  
      case UPDATE_FIELD_EDITOR:
        return { 
            ...state, 
            debtor: {
              ...state.debtor,
              [action.key]: action.value
            } 
        };
      case DEBTOR_SUBMITTED:
        return {
          ...state,
          // DEBTORs: action.payload ? action.payload[1].data : null,
          // DEBTORsCount: action.payload ? action.payload[1].meta.pagination.count : null,
          inProgress: null,
          responseStatus:action.payload ? action.payload.Response.status : null,
          responseMessage:action.payload ? action.payload.Response.message : null,
          // errors: (action && action.error) ? action.payload.errors : null
        };
      case DEBTOR_SETTINGS_MODAL_LOADED:
        return {
          ...state,
          responseStatus: null,
          responseMessage: null,
        };
      case UPDATE_DEBTOR_SETTING_FIELD_EDITOR:
        return { 
          ...state, 
          debtor: {
            ...state.debtor,
            [action.key]: action.value
          } 
        };
  
      case DEBTOR_SETTINGS_SUBMITTED:
        return {
          ...state,
          inProgress: null,
          responseStatus:action.payload ? action.payload.Response.status : null,
          responseMessage:action.payload ? action.payload.Response.message : null,
        };
  
      case DEBTOR_SETTINGS_MODAL_UNLOADED:
        return {};
  
      case DEBTOR_SUBMIT_FOR_APPROVAL:
        return {
          ...state,
          debtorID: action.error ? null : (state.debtorID || []).concat([action.payload.debtorID])
        };
      case DEBTOR_VIEW_PAGE_UNLOADED:
        return {
          ...state,
          debtor: null,
          responseStatus: null,
          responseMessage: null,
        };
        
      case CLEAR_DEBTOR_SUBMIT_RESPONSE:
          return {
            ...state,
            responseStatus: null,
            responseMessage: null,
          };
      case DELETE_DEBTOR:
        const ID = action.debtorID
        return {
          ...state,
          debtors: state.debtors.filter(debtor => debtor.id !== ID),
          responseStatus:action.payload ? action.payload.Response.status : null,
          responseMessage:action.payload ? action.payload.Response.message : null,
        };
  
      case DEBTOR_LIST_PAGE_UNLOADED: 
        return {
          ...state,
          responseStatus: null,
          responseMessage: null,
        }
        
      default:
        return state;
    }
  };
  