import {
  PROFILE_PAGE_LOADED,
  // PROFILE_PAGE_UNLOADED,
  // FOLLOW_USER,
  // UNFOLLOW_USER,
  USER_EDITOR_MODAL_LOADED,
  UPDATE_FIELD_PROFILE
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    // case PROFILE_PAGE_LOADED:
    //   return {
    //     ...state,
    //     currentUser: action.payload ? action.payload : null
    //   };
    // case USER_EDITOR_MODAL_LOADED:
    //   return {
    //     ...state,
    //     name: action.payload ? action.payload.name : '',
    //   };
    // case UPDATE_FIELD_PROFILE:
    //   return { ...state, [action.key]: action.value };
    default:
      return state;
  }
};
