import React, { useEffect, useCallback } from "react";
import { debounce } from "lodash";
import api from "../../../data/api";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    display: "block",
    flexWrap: "wrap",
    width: 450
  },
  textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1)
  },
  btnSubmit: {
    backgroundColor: "#1c3e5d",
    width: "100%",
    color: "#FFFFFF",
    minHeight: 50
  },
  dialogueActions: {
    display: "block",
    width: "100%",
    padding: "10px 24px 30px"
  },
  error_text: {
    fontSize: "0.75rem",
    color: "#f44336"
  },
  search_box: {
    position: "relative"
  },
  search_box_input: {
    alignItems: "center",
    backgroundColor: "hsl(0,0%,100%)",
    borderRadius: "4px",
    border: "1px solid hsl(0,0%,80%)",
    cursor: "default",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    minHeight: "38px",
    outline: "0 !important",
    position: "relative",
    transition: "all 100ms",
    boxSizing: "border-box",
    padding: "17px",
    width: "100%",
    fontSize: "1rem",
    cursor: "pointer"
  },
  results_holder: {
    zIndex: "1000",
    background: "#fff",
    border: "1px solid #e4e4e4",
    width: "100%"
  },
  results_ul: {
    padding: "10px",
    marginTop: 0
  },
  results_ul_li: {
    listStyle: "none",
    padding: "10px",
    cursor: "pointer",
    borderBottom: "1px solid #e4e4e4",
    fontWeight: "bold",
    "&:hover": {
      color: "#127ee4"
    }
  },
  progress_text: {
    color: "#3085d6",
    fontSize: "12px"
  }
}));

export default function AutoSuggestBetter(props) {
  const classes = useStyles();
  //console.log("props.defaultValue.label", props.defaultValue.label);
  const [options, setOptions] = React.useState([]);
  const [keyword, setKeyword] = React.useState(
    props.defaultValue && props.defaultValue.label
  );
  const [displayResults, setDisplayResults] = React.useState("none");
  const [displayProgress, setDisplayProgress] = React.useState("none");
  const [displayProgressMsg, setDisplayProgressMsg] = React.useState(
    "Loading..."
  );

  // let myKeyWord = props.defaultValue.label;
  // const promiseOptions = debounce((inputValue, callback) => {

  //Called repetitvely when searching
  function promiseOptions(inputValue) {
    //const inputValue = keyword;
    let promise = null;
    switch (props.entity) {
      case "agents":
        promise = api.Users.search(inputValue);
        break;
      case "routes":
        promise = api.Routes.search(inputValue);
        break;
      default:
        promise = api.Routes.search(inputValue);
        break;
    }
    return promise
      .then(response => {
        if (response.data.length > 0) {
          const mapped = response.data.map(item => {
            return {
              value: item.id,
              label: item.name
            };
          });
          setDisplayProgress("none");
          setDisplayResults("block");
          setOptions(mapped);
        } else {
          setDisplayProgress("block");
          setDisplayProgressMsg("No results found. Please refine your keyword");
          setDisplayResults("none");
        }
      }) // my option list array?
      .catch(err => {
        console.log("some error", err);
      }); /**/
  }
  const onChangeKeyword = inputValue => {
    setKeyword(inputValue);
    setDisplayProgress("block");
    setDisplayProgressMsg("Loading...");
    setDisplayResults("none");
    handleDebouncedSearch(inputValue);
  };
  const handleDebouncedSearch = useCallback(
    debounce(inputValue => promiseOptions(inputValue), 1000),
    []
  );
  // }, 500);

  //Called only once when loading
  useEffect(() => {
    //setKeyword(props.defaultValue.label);
    let promise = null;

    switch (props.entity) {
      case "agents":
        promise = api.Users.all();
        break;
      case "routes":
        promise = api.Routes.all();
        break;
      default:
        promise = api.Routes.all();
        break;
    }
    promise.then(response => {
      const mapped = response.data.map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
      setOptions(mapped);
    }); /**/
    //  handleDebouncedSearch();
    //}, [keyword, handleDebouncedSearch]);
  }, []);

  const handleItemSelect = val => {
    const itemObj = options.filter(
      option => Number(option.value) === Number(val)
    )[0];
    setKeyword(itemObj.label);
    setDisplayResults("none");
    props.onOptionSelected(itemObj);
  };
  return (
    <React.Fragment>
      <div>
        <div className={classes.search_box}>
          <input
            type="search"
            placeholder={props.placeholder}
            value={keyword}
            className={classes.search_box_input}
            onChange={evt => onChangeKeyword(evt.target.value)}
          />
          <div
            className={classes.progress_text}
            style={{ display: displayProgress }}
          >
            {displayProgressMsg}
          </div>
          <div
            className={classes.results_holder}
            style={{ display: displayResults }}
          >
            <ul className={classes.results_ul}>
              {options.map((i, index) => (
                <li
                  key={index}
                  data-val={i.value}
                  className={classes.results_ul_li}
                  onClick={() => handleItemSelect(i.value)}
                >
                  {i.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
