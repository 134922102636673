import {
  SET_ROUTES_PAGE,
    ROUTE_LIST_PAGE_LOADED,
    ROUTE_LIST_APPLY_FILTER,
    ROUTE_LIST_PAGE_UNLOADED,
    ROUTE_VIEW_PAGE_LOADED,
    ROUTE_VIEW_PAGE_UNLOADED,
    ROUTE_EDITOR_MODAL_LOADED,
    TRUCK_ROUTE_SUBMITTED,
    UPDATE_FIELD_ROUTE,
    DELETE_ROUTE,
    ROUTE_STOPS_LIST_LOADED,
    ROUTE_STOPS_EDITOR_MODAL_LOADED,
    UPDATE_FIELD_ROUTE_STOP,
    TRUCK_ROUTE_STOP_SUBMITTED,
    SEARCH_ROUTES_SUBMITTED,
    CLEAR_ROUTE_SUBMIT_RESPONSE,
    DELETE_ROUTE_STOP,
    ROUTE_STOP_EDITOR_MODAL_UNLOADED,
  } from '../constants/actionTypes';
// import { array } from 'prop-types';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case ROUTE_LIST_PAGE_LOADED:
        return {
          ...state,
          pager: action.pager,
          truckroutes: action.payload ? action.payload.data : null,
          truckroutesCount: action.payload ? action.payload.meta.pagination.count : null,
          pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: 0,
          search_results: false,
        };
      case SET_ROUTES_PAGE:
        return {
          ...state,
          truckroutes: action.payload ? action.payload.data : null,
          truckroutesCount: action.payload ? action.payload.meta.pagination.count : null,
          pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: action.page
        };
      case ROUTE_LIST_APPLY_FILTER:
        return {
          ...state,
          pager: action.pager,
          truckroutes: action.payload ? action.payload.data : null,
          truckroutesCount: action.payload ? action.payload.trucksCount : null,
          currentPage: 0,
          search_results: false,
        };
      case SEARCH_ROUTES_SUBMITTED:
        return {
          ...state,
          pager: action.pager,
          truckroutes: action.payload ? action.payload.data : null,
          truckroutesCount: action.payload ? action.payload.meta.pagination.count : null,
          pagination: action.payload ? action.payload.meta.pagination : null,
          currentPage: 0,
          search_results: action.payload ? action.payload.data : true,
        };
      case ROUTE_EDITOR_MODAL_LOADED:
        return {
          ...state,
          truckroute: action.mode && action.mode==="create" ? null : {...state.truckroute},
          responseStatus: null,
          responseMessage: null,
        }
      case UPDATE_FIELD_ROUTE:
          return { 
            ...state, 
            truckroute: {
              ...state.truckroute,
              [action.key]: action.value
            } 
          };
      case TRUCK_ROUTE_SUBMITTED:
        return {
          ...state,
          // truckroutes: action.payload ? action.payload.data : null,
          inProgress: null,
          responseStatus:action.payload ? action.payload.Response.status : null,
          responseMessage:action.payload ? action.payload.Response.message : null,
          // errors: (action && action.error) ? action.payload.errors : null
        };
      case ROUTE_LIST_PAGE_UNLOADED:
        return {
          ...state,
          responseStatus: null,
          responseMessage: null,
        };
      case ROUTE_VIEW_PAGE_LOADED:
        return {
          ...state,
          truckroute: action.payload
        };
      case ROUTE_VIEW_PAGE_UNLOADED:
        return {
          ...state,
          truckroute: null,
          responseStatus: null,
          responseMessage: null,
        };
      case CLEAR_ROUTE_SUBMIT_RESPONSE:
          return {
            ...state,
            responseStatus: null,
            responseMessage: null,
          };
      case DELETE_ROUTE:
        const ID = action.routeID
        return {
          ...state,
          truckroutes: state.truckroutes.filter(route => Number(route.id) !== Number(ID)),
          responseStatus:action.payload ? action.payload.Response.status : null,
          responseMessage:action.payload ? action.payload.Response.message : null,
        };
      case DELETE_ROUTE_STOP:
        const routeStopID = action.routeStopID
        return {
          ...state,
          truckroute: {
            ...state.truckroute,
            stops: state.truckroute.stops.filter(stop => Number(stop.id) !== Number(routeStopID))
          }
          // truckroutes: state.truckroutes.filter(route => Number(route.id) !== Number(stopID)),
          // responseStatus:action.payload ? action.payload.Response.status : null,
          // responseMessage:action.payload ? action.payload.Response.message : null,
        };
      case ROUTE_STOPS_LIST_LOADED:
        return {
          ...state,
          pager: action.pager,
          truckroutesstops: action.payload ? action.payload.data : null,
          currentPage: 0
        };
      
      case ROUTE_STOPS_EDITOR_MODAL_LOADED:
        return {
          ...state,
          responseStatus: null,
          responseMessage: null,
          truckroute: {
            ...state.truckroute,
            current_stop: action.payload ? action.payload : null
          }
        }
      case UPDATE_FIELD_ROUTE_STOP:
          return { 
            ...state, 
            truckroute: {
              ...state.truckroute,
              current_stop: {
                ...state.truckroute.current_stop,
                [action.key]: action.value
              }
            }
          };
      // case UPDATE_FIELD_ROUTE_STOP:
      //   return { 
      //     ...state, 
      //     truckroute: {
      //       ...state.truckroute,
      //       stops: {
      //         ...state.truckroute.stops, 
      //         current: {[action.key]: action.value}
      //       }
      //     }
      //   };
      case TRUCK_ROUTE_STOP_SUBMITTED:
        return {
          ...state,
          truckroutesstops: action.payload ? action.payload : null,
          // truckroute: {
          //   ...state.truckroute,
          //   stops: state.truckroute.stops.concat([action.payload]),
          // },
          inProgress: null,
          responseStatus:action.response ? action.response.Response.status : null,
          responseMessage:action.response ? action.response.Response.message : null,
          // errors: (action && action.error) ? action.payload.errors : null
        }; 
      case ROUTE_STOP_EDITOR_MODAL_UNLOADED:
        return {
          ...state,
          truckroute: {
            ...state.truckroute,
            stops: action.payload ? action.payload.data : null,
          },
        }
      default:
        return state;
    }
  };
  