import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../data/api";
import { connect } from "react-redux";
import {
  ADD_INVOICE_MODAL_LOADED,
  INVOICE_SUBMITTED
} from "../../constants/actionTypes";

import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// import Grid from '@material-ui/core/Grid';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateFnsUtils from "@date-io/date-fns";
import AutoSuggestBetter from "../common/AutoSuggest/AutoSuggestBetter";
// import getTime from 'date-fns/getTime';
import format from "date-fns/format";
import {
  MuiPickersUtilsProvider,
  // KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: "330px",
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1)
  },
  btnSubmit: {
    backgroundColor: "#1c3e5d",
    width: "100%",
    color: "#FFFFFF",
    minHeight: 50
  },
  dialogueActions: {
    display: "block",
    width: "100%",
    padding: "10px 24px 30px"
  },
  uploadButton: {
    textAlign: "center",
    backgroundColor: "#EEEEEE",
    width: "100%",
    margin: "16px 0 8px"
  },
  selectRoute: {
    maxWidth: "100%",
    "& label": {
      transform: "translate(14px, 13px) scale(1)"
    },
    "& div": {
      "& div": {
        padding: 10
      }
    }
  }
}));

const customSelectStyles = {
  control: (base, state) => ({
    ...base,
    padding: "1px"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? "grey" : "white"
  }),
  menu: (provided, state) => ({
    ...provided,
    padding: 5,
    zIndex: 10000,
    background: "#FFFFFF"
  })
};

const mapStateToProps = state => ({
  ...state.invoices,
  truckroutes: state.common.truckroutes ? state.common.truckroutes.data : null
});

const mapDispatchToProps = dispatch => ({
  onLoad: mode => dispatch({ type: ADD_INVOICE_MODAL_LOADED, mode }),
  onSubmit: promise => {
    dispatch({ type: INVOICE_SUBMITTED, payload: promise });
  }
});

function AddInvoiceModal(props) {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  const classes = useStyles();
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [firstRoute, setSelectedRoute] = React.useState(
    props.truckroutes && props.truckroutes.length ? props.truckroutes[0].id : []
  );
  const [selectedDate, setSelectedDate] = React.useState(
    mm + "-" + dd + "-" + yyyy
  );

  const handleRouteChange = event => {
    const val = event.value;

    setSelectedRoute(event.value);
  };

  const handleDateChange = date => {
    setSelectedDate(format(date, "MM-dd-yyyy"));
  };

  useEffect(() => {
    props.onLoad(props.mode);
  }, []);

  const onFileSelectedHandler = event => {
    setSelectedFile(event.target.files[0]);
  };
  const onSubmitClickHandler = () => ev => {
    ev.preventDefault();

    if (selectedDate && selectedFile) {
      let mTimeStamp = selectedDate;
      const invoice = {
        invoice_date: format(new Date(selectedDate), "dd-MM-yyyy"),
        route_id: firstRoute,
        invoice_file: selectedFile
      };
      const promise = api.Invoices.create(invoice);
      props.onSubmit(promise);

      //close modal
      props.handleClose();
    }
  };

  return (
    <Dialog
      open={props.opened}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      className={classes.root}
    >
      <form
        className={classes.container}
        noValidate
        autoComplete="off"
        onSubmit={onSubmitClickHandler()}
      >
        <DialogTitle id="form-dialog-title">ENTER INVOICE DETAILS</DialogTitle>
        <DialogContent>
          <AutoSuggestBetter
            placeholder="Search a route"
            entity="routes"
            onOptionSelected={handleRouteChange}
            style={customSelectStyles}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              // disableToolbar
              // variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Invoice date"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              style={{ width: "100%" }}
            />
          </MuiPickersUtilsProvider>
          <input
            // accept="image/*"
            className={classes.uploadButton}
            // style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={onFileSelectedHandler}
          />
          {/* <label htmlFor="raised-button-file">
                      <Button variant="raised" component="span" className={classes.uploadButton}>
                          Select invoice file
                      </Button>
                  </label> */}
        </DialogContent>
        <DialogActions className={classes.dialogueActions}>
          <Button fullWidth className={classes.btnSubmit} type="submit">
            SUBMIT
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(AddInvoiceModal);
