import React, { useEffect } from 'react'
import {debounce} from 'lodash'
import AsyncSelect, { makeAsyncSelect } from 'react-select/async';
import Select from 'react-select';
import api from '../../../data/api'
import { makeStyles } from '@material-ui/core/styles';

const customStyles = {
    control: (base, state) => ({
        ...base,
        padding: "10px",
        // background: "#023950",
        // // match with the menu
        // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
        // // Overwrittes the different states of border
        // borderColor: state.isFocused ? "yellow" : "green",
        // // Removes weird border around container
        // boxShadow: state.isFocused ? null : null,
        // "&:hover": {
        //   // Overwrittes the different states of border
        //   borderColor: state.isFocused ? "red" : "blue"
        // }
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused || state.isSelected ? 'grey' : 'white'
      }),
    menu: (provided, state) => ({
      ...provided,
      padding: 5,
      zIndex: 9999,
      background: '#FFFFFF'
    }),
    singleValue: (provided, state) => {
      const opacity = 1;
  
      return { ...provided, opacity };
    },
}

const useStyles = makeStyles(theme => ({
    my_select__menu : {
        zIndex: "5 !important"
        }
}))

export default function AutoSuggest(props) {
    const [options, setOptions] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    // const promiseOptions = debounce((inputValue, callback) => {
    //Called repetitvely when searching
    const promiseOptions = (inputValue, callback) => {
        let promise = null;
        switch(props.entity) {
            case 'agents':
                promise = api.Users.search(inputValue);
            break;
            case 'routes':
                promise = api.Routes.search(inputValue);
            break;
            default:
                promise = api.Routes.search(inputValue);
            break;
        }
        return promise.then(response => response.data.map(item=> {
                    return {
                        value: item.id,
                        label: item.name,
                    }
                })) // my option list array?
            .catch(err => {
                console.log('some error', err);
            });
    };
    // }, 500);
    
    //Called only once when loading
    useEffect(()=> {
        let promise = null;
        
        switch(props.entity) {
            case 'agents':
                promise = api.Users.all();
            break;
            case 'routes':
                promise = api.Routes.all();
            break;
            default:
                promise = api.Routes.all();
            break;
        }
        promise.then((response)=> {
            const mapped = response.data.map(item=> {
                return {
                    value: item.id,
                    label: item.name,
                }
            });
            setOptions(mapped);
        });
    }, [])
    
    const handleItemSelect = val => {
        setSelected({ val });
        props.onOptionSelected(val);
      };
   
    return (
        <AsyncSelect 
            cacheOptions 
            defaultValue={props.defaultValue ? props.defaultValue :null}
            defaultOptions={options} 
            placeholder={props.placeholder}
            loadOptions={promiseOptions}
            onChange={handleItemSelect}
            menuColor='red'
            classNamePrefix="my_select"
            styles={props.style != null ? props.style : customStyles} />
    )
}
