import React, { useEffect } from 'react'
import { ROUTE_LIST_PAGE_LOADED, ROUTE_LIST_PAGE_UNLOADED } from '../../constants/actionTypes';
import { connect } from 'react-redux';
import api from '../../data/api'
import ListTruckRoutes from './ListTruckRoutes';
 
// const routes = null;
let reducedRoutes = null; 
// let routesMenu = null; 
// let cleanRoutes = null; //[...reducedRoutes];

const mapStateToProps = state => ({
    currentUser: state.common.currentUser,
    isSearchResults: state.truckroutes.search_results ? state.truckroutes.search_results : false,
});
  
const mapDispatchToProps = dispatch => ({
    onLoad: (payload) =>
      dispatch({ type: ROUTE_LIST_PAGE_LOADED, payload }),
    onUnload: () =>
      dispatch({  type: ROUTE_LIST_PAGE_UNLOADED })
});

function TruckRoutes(props) {

    useEffect(() => {
        if(!props.isSearchResults) {
            props.onLoad(api.Routes.all(), null);
            return () => {
                props.onUnload();
            }
        }
    }, []);

    return (
        <React.Fragment>
            <ListTruckRoutes truckroutes={props && props.truckroutes } />
        </React.Fragment>)
}


export default connect(mapStateToProps, mapDispatchToProps)(TruckRoutes);