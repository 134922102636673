import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../data/api";
// import mockData from '../../data/mock.json';
import {
  SALES_RETURNS_VIEW_PAGE_LOADED,
  SALES_RETURN_VALIDATED,
  SALES_RETURNS_VIEW_PAGE_UNLOADED
} from "../../constants/actionTypes";

//Theme
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ShowSnackbar from "../common/ShowSnackbar.jsx";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: "f1f2f6"
  },
  boxInvoiceDetails: {
    padding: 16,
    backgroundColor: "#FFFFFF"
  },
  boxMapTitles: {
    fontWeight: "bold"
  },
  btnValidateSales: {
    margin: theme.spacing(1),
    backgroundColor: "#1c3e5d",
    width: 270,
    color: "#FFFFFF"
  },
  buttonProgress: {
    color: "#FFFFFF",
    position: "absolute",
    top: "50%",
    right: "5%",
    marginTop: -12,
    marginLeft: -12
  },
  disabledBtnSubmit: {
    backgroundColor: "#9a2828"
  },
  btnView: {
    backgroundColor: "#1c3e5d",
    width: "100%"
  },
  txtRight: {
    textAlign: "right"
  },
  heading: {
    padding: 16,
    backgroundColor: "#FFFFFF"
  }
}));

// const {invoiceDetails} = mockData.data;

const mapStateToProps = state => ({
  ...state.salesreturns,
  salesreturns: state.salesreturns.salesreturns
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload =>
    dispatch({ type: SALES_RETURNS_VIEW_PAGE_LOADED, payload }),
  onValidate: promise =>
    dispatch({ type: SALES_RETURN_VALIDATED, payload: promise }),
  onUnload: () => dispatch({ type: SALES_RETURNS_VIEW_PAGE_UNLOADED })
});

function ViewSalesReturn(props) {
  const classes = useStyles();
  const [routeID, setRouteID] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [validated, setValidated] = React.useState(false);

  let { id } = useParams();

  let salesreturn = props.salesreturns
    ? props.salesreturns.filter(ret => ret.invoice_id === id)[0]
    : null;

  let route_id = null;

  useEffect(() => {
    if (salesreturn) {
      route_id = salesreturn.route_id;
      setRouteID(route_id);
    }
  }, []);
  const handleValidateSalesReturn = () => {
    // console.log("salesreturn", salesreturn);
    // console.log("salesreturn.invoice_id", salesreturn.invoice_id);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    const promise = api.SalesReturns.validate({
      invoices: [salesreturn.invoice_id]
    });

    promise.then(response => {
      props.onValidate(response);
    });
  };

  useEffect(() => {
    return () => {
      props.onUnload();
    };
  }, []);
  const prettyNum = x => {
    return x !== null && x !== undefined
      ? x
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : "-";
  };

  return (
    <Box className={classes.container}>
      {props.responseStatus ? (
        <ShowSnackbar
          opened={props.responseStatus ? true : false}
          variant={props.responseStatus ? props.responseStatus : null}
          message={props.responseMessage !== "" ? props.responseMessage : false}
        />
      ) : (
        ""
      )}
      <Box container className={classes.heading}>
        <Typography variant="h6">
          <b>FISCAL TAX INVOICE</b>
        </Typography>
      </Box>
      {salesreturn ? (
        <Box className={classes.boxInvoiceDetails}>
          <Grid container>
            <Grid item xs={6}>
              <Box className={classes.boxMapTitles}>
                <Typography variant="h6">
                  <b>{salesreturn.shop.name}</b>
                </Typography>
                {/* <Typography variant="h6"><b>WILLIAM HOUSE 9TH FLOOR NGONG AVENUE</b></Typography> */}
                {/* <Typography>KISUMU - P051596136M</Typography>
                            <Typography>TEL/MOBILE: 0723 - 753 - 694</Typography>
                            <Typography>PAYBILL NUMBER: 900300</Typography>
                            <Typography>VAT NO: 0123003S BPN</Typography> */}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.boxMapTitles}>
                <Typography variant="h6">
                  <b>POP NAME: BARAKA AHERO</b>
                </Typography>
                {/* <Typography><b>ADDRESS: AHERO E FRIDAY</b></Typography> */}
                <Typography>
                  PAYMENT MODE: <b>{salesreturn.shop.payment_mode}Q</b>
                </Typography>
                {/* <Typography>SALES REP: <b>K47 : VAN_E_OB</b></Typography> */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
      {salesreturn ? (
        <Box className={classes.boxInvoiceDetails}>
          <Table>
            <TableHead className={classes.listheader}>
              <TableRow className={classes.rowheader}>
                <TableCell>SKU DESCRIPTION</TableCell>
                <TableCell>SALES QTY</TableCell>
                <TableCell>PRICE PER CASE</TableCell>
                <TableCell>AMOUNT BEFORE DISCOUNT</TableCell>
                <TableCell>REASON FOR RETURN</TableCell>
                <TableCell>AMOUNT FOR VAT</TableCell>
                <TableCell>RETAIL AMOUNT (VAT)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {salesreturn.items.map(i => (
                <TableRow key={i.record_id}>
                  <TableCell>{i.SKU_description}</TableCell>
                  <TableCell>{prettyNum(i.qty.total)}</TableCell>
                  <TableCell>{prettyNum(i.price_per_case)}</TableCell>
                  <TableCell>{prettyNum(i.amount_before_discount)}</TableCell>
                  <TableCell>None</TableCell>
                  <TableCell>{prettyNum(i.amount_for_vat)}</TableCell>
                  <TableCell>{prettyNum(i.retail_amount_vat)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br />
          <Grid container>
            <Grid item xs={6}>
              <Typography>TOTAL SKUS</Typography>
            </Grid>
            <Grid item xs={6}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="right">
                      GROSS SALES VALUE BEFORE DISCOUNT:{" "}
                    </TableCell>
                    <TableCell align="left">
                      <b>
                        {prettyNum(
                          salesreturn.totals.gross_sales_value_before_discount
                        )}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      TRADE DISCOUNT - SCHEME:{" "}
                    </TableCell>
                    <TableCell align="left">
                      <b>
                        {prettyNum(salesreturn.totals.trade_discount_schemes)}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      TRADE DISCOUNT - OTHERS:{" "}
                    </TableCell>
                    <TableCell align="left">
                      <b>
                        {prettyNum(salesreturn.totals.trade_discount_others)}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      GROSS SALES VALUE AFTER DISCOUNT:{" "}
                    </TableCell>
                    <TableCell align="left">
                      <b>
                        {prettyNum(
                          salesreturn.totals.gross_sales_value_after_discount
                        )}
                      </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">VAT 16.00%: </TableCell>
                    <TableCell align="left">
                      <b>{prettyNum(salesreturn.totals.vat_16)}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">NET AMOUNT: </TableCell>
                    <TableCell align="left">
                      <Typography classame={classes.netamount}>
                        <b>{prettyNum(salesreturn.totals.net_amount)}</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
      <Box
        className={classes.boxInvoiceDetails}
        justifyContent="center"
        display="flex"
      >
        <Button
          onClick={handleValidateSalesReturn}
          className={classes.btnValidateSales}
          disabled={props.inProgress}
        >
          VALIDATE SALES RETURN
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}{" "}
        </Button>
      </Box>
    </Box>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewSalesReturn);
