import React from 'react'

//Theme
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import Avatar from '@material-ui/core/Avatar';
// import notificationAvatar from '../../../../../assets/images/img/notification_bell.png';
import Notifications from '@material-ui/icons/Notifications';

const useStyles = makeStyles(theme => ({
    
    userAvatar: {
        marginRight: 5,
        borderRadius:0, 
        "& img" : {
            width: 'auto'
        }
    },
    button: {
        margin: theme.spacing(1),
        fontSize: '.9rem',
        fontWeight: 700
      }
}))

export default function NotificationBell(props) {
    const classes = useStyles();
    return (
        <Button className={classes.button} onClick={props.onShowNotification}>
            <Notifications  className={clsx(classes.userAvatar, classes.bigAvatar)} />
            {/* <NotificationsIcon fontSize="large"/> */}
            {/* <Avatar variant="square" alt="Remy Sharp"  onClick={props.onShowNotification} src={notificationAvatar} className={clsx(classes.userAvatar, classes.bigAvatar)} /> */}
            NOTIFICATION
        </Button>
    )
}
