import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

//Theme
import Menu from '@material-ui/core/Menu';
// import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles({
    paperContainer: {
      width: 200,
    },
    gridContainer: {
        minHeight: 40,
        alignItems: 'center',
    },
    alignCentre: {
        alignItems: 'center',
        display: 'flex',
    },
    paddTitle: {
        paddingLeft: 16,
    },
    btnClearCont: {
        textAlign: 'right',
        paddingRight: 16
    },
    btnClear: {
        cursor: 'pointer'
    },
    listItemLi: {
        padding: '6px 12px',
        backgroundColor: '#FDF5F5',
        fontSize: 12,
    }
  });

export default function AccountsList(props) {
    const classes = useStyles();

    const handleMenuItemClick = (index) => {
        props.onSwitchAccounts(index);
      };

    if (!props.organisations) {
        return ( <span/> );
    }
    if (props.organisations.length === 0) {
        return ( <div className={classes.loading}> ... </div> );
    }
    const organisations = props.organisations ? props.organisations.data : null;
    return (
        <Menu
            id="simple-menu"
            anchorEl={props.anchorEl}
            keepMounted
            open={Boolean(props.anchorEl)}
            onClose={props.onHideAccounts}
        >
            <Paper className={classes.paperContainer}></Paper>
                {organisations.map(org=> (
                    <MenuItem 
                        key={org.id}
                        onClick={event => handleMenuItemClick(org.id)} 
                        className={classes.listItemLi}
                        >{org.name}</MenuItem>
                ))}
            <Paper className={classes.root}></Paper>
      </Menu>
    )
}
