import {
  SET_INVOICES_PAGE,
    INVOICE_LIST_PAGE_LOADED,
    INVOICE_LIST_APPLY_ROUTE_FILTER,
    INVOICE_LIST_FILTER_BY_PAYMENT_MODE,
    INVOICE_LIST_PAGE_UNLOADED,
    INVOICE_VIEW_PAGE_LOADED,
    INVOICE_VIEW_PAGE_UNLOADED,
    ADD_INVOICE_MODAL_LOADED,
    INVOICE_SUBMITTED,
    SEARCH_INVOICES_SUBMITTED
  } from '../constants/actionTypes';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case INVOICE_LIST_PAGE_LOADED:
        return {
          ...state,
          pager: action.pager,
          invoices: action.payload ? action.payload.invoices : null,
          invoice_date: action.payload ? action.payload.invoice_date : null,
          route: action.payload ? action.payload.route : null,
          current_invoice_filter : {
            selected_date: action.payload && action.payload.invoice_date ? action.payload.invoice_date : localStorage.getItem('current_invoice_filter_date'),
            selected_route: action.payload && action.payload.route  ? action.payload.route : JSON.parse(localStorage.getItem('current_invoice_filter_route')),
          },
          // invoicesCount: action.payload ? action.payload.meta.pagination.count : null,
          search_results: false,
          currentPage: 0
        };
      case SEARCH_INVOICES_SUBMITTED:
        return {
          ...state,
          pager: action.pager,
          invoices: action.payload ? action.payload : null,
          invoice_date: action.payload ? action.payload.invoice_date : null,
          route: action.payload ? action.payload.route : null,
          current_invoice_filter : {
            selected_date: action.payload ? action.payload.invoice_date : null,
            selected_route: action.payload ? action.payload.route : null,
          },
          currentPage: action.page,
          search_results: action.payload ? true : false,
        };
      case ADD_INVOICE_MODAL_LOADED :
        return {
          ...state,
          responseStatus: null,
          responseMessage: null,
        };
      case INVOICE_SUBMITTED: 
        return {
          ...state,
          inProgress: null,
          responseStatus:action.payload ? action.payload.Response.status : null,
          responseMessage:action.payload ? action.payload.Response.message : null,
        }
      case SET_INVOICES_PAGE:
        return {
          ...state,
          invoices: action.payload ? action.payload.invoices : null,
          invoice_date: action.payload ? action.payload.invoice_date : null,
          route: action.payload ? action.payload.route : null,
          current_invoice_filter : {
            selected_date: action.payload ? action.payload.invoice_date : null,
            selected_route: action.payload ? action.payload.route : null,
          },
          currentPage: action.page
        };
      case INVOICE_LIST_APPLY_ROUTE_FILTER:
          if(action.payload && action.payload.invoice_date) {
            localStorage.setItem('current_invoice_filter_date', action.payload.invoice_date);
          } else {
            localStorage.removeItem('current_invoice_filter_date');
          }
          if(action.payload && action.payload.route) {
            localStorage.setItem('current_invoice_filter_route', JSON.stringify(action.payload.route));
          } else {
            localStorage.removeItem('current_invoice_filter_route');
          }
          return {
          ...state,
          pager: action.pager,
          invoices: action.payload ? action.payload.invoices : null,
          invoice_date: action.payload ? action.payload.invoice_date : null,
          route: action.payload ? action.payload.route : null,
          current_invoice_filter : {
            selected_date: action.payload ? action.payload.invoice_date : null,
            selected_route: action.payload ? action.payload.route : null,
          },
          // invoicesCount: action.payload ? action.payload.invoicesCount : null,
          search_results: false,
          currentPage: 0
        };
      case INVOICE_LIST_FILTER_BY_PAYMENT_MODE:
          if(action.payload && action.payload.invoice_date) {
            localStorage.setItem('current_invoice_filter_date', action.payload.invoice_date);
          } else {
            localStorage.removeItem('current_invoice_filter_date');
          }
          if(action.payload && action.payload.route) {
            localStorage.setItem('current_invoice_filter_route', JSON.stringify(action.payload.route));
          } else {
            localStorage.removeItem('current_invoice_filter_route');
          }
          return {
          ...state,
          pager: action.pager,
          invoices: action.payload ? action.payload.invoices : null,
          invoice_date: action.payload ? action.payload.invoice_date : null,
          route: action.payload ? action.payload.route : null,
          current_invoice_filter : {
            selected_date: action.payload ? action.payload.invoice_date : null,
            selected_route: action.payload ? action.payload.route : null,
          },
          // invoicesCount: action.payload ? action.payload.invoicesCount : null,
          search_results: false,
          currentPage: 0
        };
    
      case INVOICE_LIST_PAGE_UNLOADED: 
        return {
          ...state,
          responseStatus: null,
          responseMessage: null,
        }
      case INVOICE_VIEW_PAGE_LOADED:
        return {
          ...state,
          // truckroute: action.payload[0].truck
        };
      case INVOICE_VIEW_PAGE_UNLOADED:
        return {};
        
      default:
        return state;
    }
  };
  