import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import api from '../../data/api'
import { DEBTOR_VIEW_PAGE_LOADED, DEBTOR_VIEW_PAGE_UNLOADED } from '../../constants/actionTypes';

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DebtorEditorModal from './DebtorEditorModal.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShowSnackbar from '../common/ShowSnackbar.jsx';

const useStyles = makeStyles(theme => ({ 
    container: {
        backgroundColor: 'f1f2f6'
    },
    heading: {
        padding: 16,
        backgroundColor: '#FFFFFF',
    },
    btnApproval: {
        backgroundColor: '#1c3e5d',
        color: '#FFFFFF'
    },
    btnEdit: {
        backgroundColor: '#1c3e5d',
        minWidth: 100,
        color: '#FFFFFF',
        marginLeft: theme.spacing(1),
    },
    boxMap : {
        padding: 16,
        minHeight: 400
    },
    boxDetails: {
        padding: 26,
        backgroundColor: '#FFFFFF',
    },
    input: {display: 'none'},
    userDetailsThumb: {
        minHeight: 300,
        minWidth: 100,
        margin: '0 20px 20px 20px',
        borderRadius: 15,
        border: '1px solid #1c3e5d',
    },
 }));

 const mapStateToProps = state => ({
    ...state.debtors,
    debtor: state.debtors.debtor
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload) =>
      dispatch({ type: DEBTOR_VIEW_PAGE_LOADED, payload }), 
    onUnload: () =>
      dispatch({  type: DEBTOR_VIEW_PAGE_UNLOADED })   
});
 
function ViewDebtor(props) {
    const classes = useStyles();
    
    const [modalOpened, setModalOpen] = React.useState(false);

    let { id } = useParams();   
    
    const handleDebtorEditorLaunch = () => {
        setModalOpen(true);
    };
    const handleDebtorEditorClose = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        props.onLoad(api.Debtors.get(id));
        return () => {
            props.onUnload();
        }
    }, []);

    if (!props.debtor) {
        return (
          <div className="article-preview">Loading...</div>
        );
    }
    
    if (props.debtor.length === 0) {
        return (
          <div className="article-preview">
            No details here... yet.
          </div>
        );
    }
    if (!props.debtor) {
        return ( <div className={classes.proggressRoot}><CircularProgress size={24} thickness={4} /></div> );
    }
    if (props.debtor.length === 0) {
        return ( <div className={classes.loading}> Nothing here... yet. </div> );
    }
    // console.log(' ViewDebtor user', user);
    return (
        <Box className={classes.container}>
            {props.responseStatus ? 
                <ShowSnackbar 
                    opened={props.responseStatus ? true : false}
                    variant={props.responseStatus ? props.responseStatus : null}
                    message={props.responseMessage!=="" ? props.responseMessage : false} />
                : ''}
            <Grid container className={classes.heading}> 
                <Grid item xs={6}>
                    DEBTOR
                </Grid>
                <Grid item xs={6} align="right">
                    <Button 
                        variant="contained" 
                        size="small" 
                        className={classes.btnEdit} 
                        onClick={handleDebtorEditorLaunch}>EDIT</Button>
                {modalOpened ? 
                    <DebtorEditorModal
                        opened={modalOpened}
                        handleClose={handleDebtorEditorClose}
                        debtor={props.debtor ? props.debtor : null}
                        mode="update" />
                    : ''}
                    
                </Grid>
            </Grid>
            <Box className={classes.detailsBody}>
                <Grid container> 
                    <Grid item xs={9}>
                        <Box className={classes.boxDetails}>
                            <Box>
                                <div><Typography variant='h6'><strong>Shop:</strong> {props.debtor.shop_name}</Typography></div>
                                <div><Typography variant='h6'><strong>ID Number:</strong> {props.debtor.contact_name}</Typography></div>
                                <div><Typography variant='h6'><strong>Phone Number:</strong> {props.debtor.contact_number}</Typography></div>
                                <div><Typography variant='h6'><strong>Debt limit:</strong> Ksh {props.debtor.debt_limit}</Typography></div>
                                <div><Typography variant='h6'><strong>Grace period:</strong> {props.debtor.grace_period} days</Typography></div>
                                <div><Typography variant='h6'><strong>Status:</strong> {props.debtor.status}</Typography></div>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            
        </Box>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewDebtor)