import React from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'f1f2f6'
    },
    boxInvoiceDetails: {
        padding: 16,
        backgroundColor: '#FFFFFF',
    },
    boxMapTitles: {
        fontWeight:'bold',
    },
    btnValidateSales: {
        margin: theme.spacing(1),
        backgroundColor: '#1c3e5d',
        width: 200,
        color: '#FFFFFF'
    },
    btnView: {
        backgroundColor: '#1c3e5d',
        width: '100%'
    },
    txtRight: {
        textAlign: "right"
    },
    heading: {
        padding: 16,
        backgroundColor: '#FFFFFF',
    },
    loading: {
      minHeight: 250,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fff'
    },
    proggressRoot: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        minHeight: '30vh',
    },
  }));

const mapStateToProps = state => ({
    ...state.invoices,
});

const mapDispatchToProps = dispatch => ({});

function ViewInvoice(props) {
    const classes = useStyles();
    //  console.log('props',props);
    let { id } = useParams();
    // console.log('props id',id);
    if(!props.invoices) {
        return props.history.push(`/`);
    }
    const record = props.invoices[id];
    if (!record) {
        return ( <div className={classes.proggressRoot}><CircularProgress size={24} thickness={4} /></div> );
    }
    if (record.length === 0) {
        return ( <div className={classes.loading}> No trucks are here... yet. </div> );
    }
    const invoice = record.invoice;
    const returns_ = (record.returns)[0];
    const prettyNum = (x)=> {
        return x !==null && x !== undefined ? x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '-';
    }
    return (
        <Box className={classes.container}>
            <Box container className={classes.heading}> 
                <Typography variant="h6">
                    <b>FISCAL TAX INVOICE</b>
                </Typography>
            </Box>
            <Box className={classes.boxInvoiceDetails}>
                <Grid container> 
                    <Grid item xs={6}>
                        <Box className={classes.boxMapTitles}>
                            <Typography variant="h6"><b>{invoice.shop.name}</b></Typography>
                            {/* <Typography variant="h6"><b>WILLIAM HOUSE 9TH FLOOR NGONG AVENUE</b></Typography> */}
                            <Typography>CASH MEMO NO. - {invoice.shop.cash_memo_no}</Typography>
                            {/* <Typography>TEL/MOBILE: 0723 - 753 - 694</Typography> */}
                            {/* <Typography>PAYBILL NUMBER: 900300</Typography> */}
                            {/* <Typography>VAT NO: 0123003S BPN</Typography> */}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={classes.boxMapTitles}>
                            <Typography variant="h6"><b>POP NAME: {invoice.shop.route}</b></Typography>
                            {/* <Typography><b>ADDRESS: AHERO E FRIDAY</b></Typography> */}
                            <Typography>PAYMENT MODE: <b>{invoice.payment_mode}</b></Typography>
                            {/* <Typography>SALES REP: <b>K47 : VAN_E_OB</b></Typography> */}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/* <Box className={classes.boxInvoiceDetails}>
                <Typography><b>ORDER PLACING</b></Typography>
                <Typography><b>START DATE</b>: 18/10/2019: 4:00AM</Typography>
                <Typography><b>END DATE</b>: 18/10/2019: 12:00AM</Typography>
            </Box> */}
            <Box className={classes.boxInvoiceDetails}>
                <Table>
                    <TableHead className={classes.listheader}>
                        <TableRow className={classes.rowheader}>
                            <TableCell>SKU DESCRIPTION</TableCell>
                            <TableCell>SALES QTY</TableCell>
                            <TableCell>PRICE PER CASE</TableCell>
                            <TableCell>AMOUNT BEFORE DISCOUNT</TableCell>
                            {/* <TableCell>REASON FOR RETURN</TableCell> */}
                            <TableCell>AMOUNT FOR VAT</TableCell>
                            <TableCell>RETAIL AMOUNT (VAT)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoice.items.map((i) => (
                            <TableRow key={i.uuid}>
                                <TableCell>{i.SKU_description}</TableCell>
                                <TableCell>{i.qty ? i.qty.total : '-'} {i.qty ? '(' + i.qty.unit + ')' : ''}</TableCell>
                                <TableCell>{prettyNum(i.price_per_case)}</TableCell>
                                <TableCell>{prettyNum(i.amount_before_discount)}</TableCell>
                                <TableCell>{returns_ ? returns_.reason : ''}</TableCell>
                                <TableCell>{prettyNum(i.amount_for_vat)}</TableCell>
                                <TableCell>{prettyNum(i.retail_amount_vat)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <br/>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography>TOTAL SKUS</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Table>
                            <TableBody>
                                <TableRow><TableCell align="right"><b>GROSS SALES VALUE BEFORE DISCOUNT:</b> </TableCell><TableCell align="left"><b>{prettyNum(invoice.totals.gross_sales_value_before_discount)}</b></TableCell></TableRow>
                                <TableRow><TableCell align="right"><b>TRADE DISCOUNT - SCHEME:</b> </TableCell><TableCell align="left"><b>{prettyNum(invoice.totals.trade_discount_schemes)}</b></TableCell></TableRow>
                                <TableRow><TableCell align="right"><b>TRADE DISCOUNT - OTHERS:</b> </TableCell><TableCell align="left"><b>{prettyNum(invoice.totals.trade_discount_others)}</b></TableCell></TableRow>
                                <TableRow><TableCell align="right"><b>GROSS SALES VALUE AFTER DISCOUNT:</b> </TableCell><TableCell align="left"><b>{prettyNum(invoice.totals.gross_sales_value_after_discount)}</b></TableCell></TableRow>
                                <TableRow><TableCell align="right"><b>VAT 16.00%:</b> </TableCell><TableCell align="left"><b>{prettyNum(invoice.totals.vat_16)}</b></TableCell></TableRow>
                                <TableRow><TableCell align="right"><b>NET AMOUNT:</b> </TableCell><TableCell align="left"><Typography classame={classes.netamount}><b>{prettyNum(invoice.totals.net_amount)}</b></Typography></TableCell></TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Box>
            <Box className={classes.boxInvoiceDetails} justifyContent="center" display="flex">
                <Button className={classes.btnValidateSales} onClick={()=> {props.history.push(`/invoices/`)}}>BACK TO INVOICES</Button>
            </Box>
        </Box>
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoice)