import React from 'react'

//UI
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';
import BarChartIcon from '@material-ui/icons/BarChart';

const useStyles = makeStyles(theme => ({ 
    btnClose: {
        margin: theme.spacing(1),
        minWidth: "30px",
        backgroundColor: '#db3332',
        padding: '2px 4px'
      }
 }));

export default function CloseButton(props) {
    const classes = useStyles();
    return (
        <Box className={classes.card}>
            {props.status===false ? 
            <Button 
                variant="contained" 
                color="secondary" 
                className={classes.btnClose} 
                onClick={props.onClickToggle}>
                <BarChartIcon />
            </Button>
            : 
            <Button 
                variant="contained" 
                color="secondary" 
                className={classes.btnClose} 
                onClick={props.onClickToggle}>
                <CloseIcon />
            </Button>
            }
        </Box>
    )
}
