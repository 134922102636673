import React from 'react'
import { LOGOUT } from '../../../../constants/actionTypes';
import { connect } from 'react-redux';//routing
import { Link } from 'react-router-dom'

//Theme
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
        fontSize: '.9rem',
        fontWeight: 700,
        height: 30
    },
}))

const mapStateToProps = state => ({
    currentUser: state.common.currentUser
});
  
const mapDispatchToProps = dispatch => ({
    onLogoutUser: () =>
      dispatch({ type: LOGOUT }),
});


function Logout(props) {
    const classes = useStyles();

    const handleLogout = () => {
        props.onLogoutUser();
    }
    if (!props.currentUser) {
        return <Link to="/" />;
    }
    return (
        <Button 
            variant="contained" 
            className={classes.button} 
            onClick={handleLogout}
            to={`/`} 
            component={Link}>
            LOGOUT
        </Button>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);