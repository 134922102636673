import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
// import { FormLabel, MenuItem } from '@material-ui/core'
import api from '../../data/api'
import { connect } from 'react-redux';
import { TRUCK_EDITOR_MODAL_LOADED, TRUCK_LIST_PAGE_LOADED, UPDATE_FIELD_EDITOR, TRUCK_SUBMITTED } from '../../constants/actionTypes';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'block',
      flexWrap: 'wrap',
    },
    textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1)
    },
    btnSubmit: {
        backgroundColor: '#1c3e5d',
        width: '100%',
        color: '#FFFFFF',
        minHeight: 50
    },
    dialogueActions: {
        display: 'block',
        width: '100%',
        padding: '10px 24px 30px',
    }
  }));

  const mapStateToProps = state => ({ 
    truck: state.trucks.truck, 
  });

  const mapDispatchToProps = dispatch => ({
    onLoad: mode => 
        dispatch({ type: TRUCK_EDITOR_MODAL_LOADED, mode }),
    onReLoad: payload => 
        dispatch({ type: TRUCK_LIST_PAGE_LOADED, payload }),
    onUpdateField: (key, value) => 
      dispatch({ type: UPDATE_FIELD_EDITOR, key, value }),
    onSubmit: (promise) =>  {
      dispatch({ type: TRUCK_SUBMITTED, payload: promise })
    },
    // onUnload: () => 
    //   dispatch({ type: UPDATE_FIELD_EDITOR }),
  });

  const Promise = global.Promise;

function TruckEditorModal(props) {
  // console.log('props', props);
  const classes = useStyles();

  const updateFieldEvent = key => ev => props.onUpdateField(key, ev.target.value);

  const changeRegNo = updateFieldEvent('reg_no');
  const changeRefNo = updateFieldEvent('truck_ref');

  useEffect(() => {
    props.onLoad(props.mode);
    // return () => {
    //     props.onUnload();
    // }
  }, []);
  
  const handleSubmitForm = ev => {
    ev.preventDefault();
    if(props.truck && props.truck.reg_no.length > 0 && props.truck.truck_ref.length > 0) {
      const truck = {
        reg_no: props.truck.reg_no,
        truck_ref: props.truck.truck_ref
      };

      //submit post
      const submitPromise = props.mode && props.mode==="update" ? api.Trucks.update(truck, props.truck.id) : api.Trucks.create(truck);
      submitPromise.then((response)=> {
        props.onSubmit(response)
        props.onReLoad(api.Trucks.all())
      })

      //close modal
      props.handleClose();
    }
  };

  // let reg_no = props.truck.reg_no;
  // let truck_ref = props.truck.truck_ref;
    
  return (
      <Dialog open={props.opened} onClose={props.handleClose} aria-labelledby="form-dialog-title">
          <form 
            className={classes.container} 
            noValidate 
            autoComplete="off">
              <DialogTitle id="form-dialog-title">ENTER TRUCK DETAILS</DialogTitle>
              <DialogContent>
                  
                  <TextField
                      id="reg_no"
                      label="TRUCK REGISTRATION NO"
                      placeholder="Input number"
                      fullWidth
                      margin="normal"
                      style={{ marginBottom: 8 }}
                      variant="outlined"
                      value={props.truck ? props.truck.reg_no : null}
                      onChange={changeRegNo}
                      />
                  <TextField
                      id="till_no"
                      label="TRUCK REF NO"
                      placeholder="Input number"
                      fullWidth
                      margin="normal"
                      style={{ marginBottom: 8 }}
                      variant="outlined"
                      value={props.truck ? props.truck.truck_ref : null}
                      onChange={changeRefNo}
                      />
                  {/* <TextField
                      id="truck_route"
                      select
                      fullWidth
                      label="TRUCK ROUTE"
                      className={classes.textField}
                      value={area}
                      onChange={handleAreaChange}
                      SelectProps={{
                          MenuProps: {
                          className: classes.menu,
                          },
                      }}
                      margin="normal"
                      style={{ marginBottom: 8 }}
                      variant="outlined"
                      >
                      {truck_routes.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                          {option.name}
                          </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                      id="delivery_agent"
                      select
                      fullWidth
                      label="DELIVERY AGENT"
                      className={classes.textField}
                      value={agent}
                      onChange={handleRouteChange}
                      SelectProps={{
                          MenuProps: {
                          className: classes.menu,
                          },
                      }}
                      margin="normal"
                      style={{ marginBottom: 8 }}
                      variant="outlined"
                      >
                      {delivery_agents.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                          {option.name}
                          </MenuItem>
                      ))}
                  </TextField>
                  <TextField
                      id="delivery_agent_phone"
                      label="DELIVERY AGENT PHONE NUMBER"
                      placeholder="Input number"
                      fullWidth
                      margin="normal"
                      style={{ marginBottom: 8 }}
                      variant="outlined"
                      /> */}
                  
              </DialogContent>
              <DialogActions className={classes.dialogueActions}>
                  <Button 
                      fullWidth
                      onClick={handleSubmitForm} 
                      className={classes.btnSubmit}
                      type="submit">
                      SUBMIT
                  </Button>
              </DialogActions>
          </form>
      </Dialog>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(TruckEditorModal);
