import ListUsers from './ListUsers'
import React, { useEffect } from 'react'
import { USER_LIST_PAGE_LOADED, USER_LIST_PAGE_UNLOADED } from '../../../constants/actionTypes';
import { connect } from 'react-redux';
import api from '../../../data/api'

const mapStateToProps = state => ({
    currentUser: state.common.currentUser,
    isSearchResults: state.users.search_results ? state.users.search_results : false,
});
  
const mapDispatchToProps = dispatch => ({
    onLoad: (payload, pager) =>
      dispatch({ type: USER_LIST_PAGE_LOADED, payload }),
    onUnload: () =>
      dispatch({  type: USER_LIST_PAGE_UNLOADED })
});


function UserManagement(props) {
    useEffect(() => {
        if(!props.isSearchResults) {
            props.onLoad(api.Users.all(), null);
            return () => {
                props.onUnload();
            }
        }
    }, []);
    
    return (
        <React.Fragment>
            <ListUsers users={props && props.users } />
        </React.Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
