import React from 'react'
import api from '../../data/api'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import ListRow from '../common/listRow/ListRow';
// import mockData from '../../data/mock.json';
import Title from '../common/listRow/listTitle/components/Title';
import { CURRENT_MODULE_LOADED, SET_DEBTORS_PAGE, CLEAR_DEBTOR_SUBMIT_RESPONSE, DELETE_DEBTOR } from '../../constants/actionTypes';

//Theme
import { makeStyles } from '@material-ui/core/styles';
// import InputBase from '@material-ui/core/InputBase';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import Pagination from 'react-mui-pagination'; //https://github.com/bnabriss/react-mui-pagination
import DebtorEditorModal from './DebtorEditorModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import ShowSnackbar from '../common/ShowSnackbar.jsx';

const useStyles = makeStyles(theme => ({
    list_root: {
        minHeight: 300
    },
    listheader:{
        backgroundColor: '#EEEEEE',
    },
    rowheader: {
        height: 60
    },
    seeMore: {
      marginTop: theme.spacing(3),
    },
    btnAdd: {
        backgroundColor: '#db3332',
        width: 200
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
    },
    selectTruck: {
        maxWidth: 200,
        minWidth: 150,
        textAlign: 'left'
    },
    selectLable: {
        zIndex: 100,
        top: 17,
        left: 10,
        backgroundColor: '#FFFFFF',
    },
    txtRight: {
        textAlign: "right"
    },
    pageTitle: {
        minHeight: 100,
        backgroundColor: '#FFFFFF',
        maxWidth: '100%'
    },
    loading: {
      minHeight: 350,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#fff'
    },
    proggressRoot: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        minHeight: '30vh',
    },
    btnEdit: {
        backgroundColor: '#1c3e5d',
        width: '100%',
        color: '#FFFFFF'
    },
    btnRemove: {
        color: '#1c3e5d',
        border: '1px solid #1c3e5d',
        width: '100%',
        fontSize: 16,
        "& span": {
            fontSize: 16
        }
    },
    btnView : {
        backgroundColor: '#1c3e5d',
        width: '100%',
        minWidth: 64,
        boxSizing: 'border-box',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontWeight: 500,
        lineHeight: 1.75,
        borderRadius: 4,
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        margin: 0,
        display: 'inline-flex',
        verticalAlign: 'middle',
        color: '#FFFFFF',
        justifyContent: 'center',
        textDecoration: 'none',
        padding: '4px 0',
        "&:hover": {
            backgroundColor: '#cccccc'
        }
    },
    tableBody: {
        "& tr td": {
            fontSize: 16
        }
    }
  }));


  const mapStateToProps = state => ({
    ...state.debtors
  });

  const mapDispatchToProps = dispatch => ({
    onOnSetModule: () => 
      dispatch({ type: CURRENT_MODULE_LOADED, module:'DEBTORS' }),
    onSetPage: (payload, page) => 
      dispatch({ type: SET_DEBTORS_PAGE, payload, page }),
    onBeforeDeleteDebtor: () => 
      dispatch({ type:CLEAR_DEBTOR_SUBMIT_RESPONSE }),
    onDeleteDebtor: (debtorID, payload) => 
      dispatch({ type: DELETE_DEBTOR, debtorID, payload })
  });

function ListDebtors(props) {

    props.onOnSetModule();
    
    const classes = useStyles();

    const [modalOpen, setModalOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);

    const handleDebtorEditorLaunch = () => {
        setModalOpen(true);
    };

    const handleDebtorEditorClose = () => {
        setModalOpen(false);
    };

    const MySwal = withReactContent(Swal);
    const handleRemoveDebtor = (val) => {
        props.onBeforeDeleteDebtor();
        MySwal.fire({
            title: 'Are you sure?',
            text: 'Confirm delete',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            
        }).then((result) => {
            if (result.value) {
                props.onDeleteDebtor(val, api.Debtors.del(val));
            }
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        props.onSetPage(api.Debtors.all(newPage), newPage);
    };

    // let reducedDebtors = null;
    // if(props && props.debtors) {
    //     reducedDebtors = props.debtors.map(truck => {
    //         return {
    //             primary_key: truck.id, 
    //             truck_id: truck.id, 
    //             truck_reg_no: truck.reg_no, 
    //             delivery_agent_name: truck.delivery_agent ? truck.delivery_agent.name : 'null', 
    //             phone_number: truck.delivery_agent ? truck.delivery_agent.phone_number : 'null', 
    //             truck_till_number: truck.till_number ? truck.till_number : 'null'
    //         }
    //     });
        
    //     cleanDebtors = [...reducedTrucks];
    // }

    if (!props.debtors) {
        return ( <div className={classes.proggressRoot}><CircularProgress size={24} thickness={4} /></div> );
    }
    // if (props.debtors.length === 0) {
    //     return ( <div className={classes.loading}> No debtor are here... yet. </div> );
    // }
    let page_total = props.pagination ? props.pagination.total : 0;
    return (
        <React.Fragment>
            <div  className={classes.list_root}>
                <Container className={classes.pageTitle}>
                    <Box
                        justifyContent="center"
                        display="flex"
                        alignItems="center"
                        css={{ height: 100 }}
                    >
                        <Grid container display="flex" alignItems="center">
                            <Grid item xs={6}>
                                <Title title="DEBTOR DETAILS"></Title>
                            </Grid>
                            <Grid item xs={6}  className={classes.txtRight}>
                            
                            </Grid>
                        </Grid>
                    </Box>
                    {props.responseStatus ? 
                        <ShowSnackbar 
                            opened={props.responseStatus ? true : false}
                            variant={props.responseStatus ? props.responseStatus : null}
                            message={props.responseMessage!=="" ? props.responseMessage : false} />
                        : ''}
                        
                </Container>
                <Table size="small">
                    <TableHead className={classes.listheader}>
                        <TableRow className={classes.rowheader}>
                            <TableCell><b>SHOP NAME</b></TableCell>
                            <TableCell><b>CONTACT NUMBER</b></TableCell>
                            <TableCell><b>CONTACT NAME</b></TableCell>
                            <TableCell><b>DEBT LIMIT</b></TableCell>
                            <TableCell><b>GRACE PERIOD</b></TableCell>
                            <TableCell><b>STATUS</b></TableCell>
                            <TableCell colSpan={2} align="right">
                                <Button 
                                    variant="contained" 
                                    size="small" 
                                    color="secondary" 
                                    onClick={handleDebtorEditorLaunch} 
                                    className={classes.btnAdd}><b>ADD DEBTOR</b>
                                </Button>
                                {modalOpen ? <DebtorEditorModal
                                    opened={modalOpen}
                                    handleClose={handleDebtorEditorClose}
                                    mode="create">
                                </DebtorEditorModal> : ''}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                    {props.debtors ? props.debtors.map((debtor, index) => (
                        
                            <TableRow key={index}>
                                <TableCell>{debtor.shop_name}</TableCell>
                                <TableCell>{debtor.contact_number}</TableCell>
                                <TableCell>{debtor.contact_name}</TableCell>
                                <TableCell>{debtor.debt_limit}</TableCell>
                                <TableCell>{debtor.grace_period}</TableCell>
                                <TableCell>{debtor.status}</TableCell>
                                
                                <TableCell align="right">
                                    <Button 
                                        variant="outlined" 
                                        size="small" 
                                        onClick={() => handleRemoveDebtor(debtor.id)}
                                        className={classes.btnRemove}>REMOVE</Button>
                                </TableCell>
                                <TableCell align="right">
                                    <Link variant="contained" size="small" className={classes.btnView} to={`/debtors/${debtor.id}`}>VIEW </Link>
                                </TableCell>
                            </TableRow>
                    )) : ''}
                    </TableBody>
                    
                </Table>
                <Pagination 
                    page={page} 
                    setPage={handleChangePage} 
                    total={page_total}
                    activeProps={{ style: { backgroundColor: '#db3332' } }} />
            </div>
        </React.Fragment>)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListDebtors);