import React, { useEffect } from 'react'
import { SALES_RETURNS_LIST_PAGE_LOADED, SALES_RETURNS_VIEW_PAGE_UNLOADED } from '../../constants/actionTypes';
import { connect } from 'react-redux';
import api from '../../data/api'
import ListSalesReturns from './ListSalesReturns';

const mapStateToProps = state => ({
    ...state.salesreturns,
    // trucks: state.trucks ? state.trucks.trucks : null,
    truckroutes: state.common.truckroutes ? state.common.truckroutes.data : null,
});
  
const mapDispatchToProps = dispatch => ({
    onLoad: (payload, pager) =>
      dispatch({ type: SALES_RETURNS_LIST_PAGE_LOADED, payload }),
    onUnload: () =>
      dispatch({  type: SALES_RETURNS_VIEW_PAGE_UNLOADED })
});

function SalesReturns(props) {
    useEffect(() => {
        props.onLoad(api.SalesReturns.all(), null);
        return () => {
            props.onUnload();
        }
    }, []);

    return (
        <React.Fragment>
            <ListSalesReturns 
                salesreturns={props && props.salesreturns} 
                routes={props.truckroutes ? props.truckroutes : null} />
        </React.Fragment>)
}
export default connect(mapStateToProps, mapDispatchToProps)(SalesReturns);