import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import api from '../../data/api'
import { connect } from 'react-redux';
import { DEBTOR_EDITOR_MODAL_LOADED, DEBTOR_LIST_PAGE_LOADED, UPDATE_FIELD_EDITOR, DEBTOR_SUBMITTED } from '../../constants/actionTypes';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'block',
      flexWrap: 'wrap',
    },
    textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1)
    },
    btnSubmit: {
        backgroundColor: '#1c3e5d',
        width: '100%',
        color: '#FFFFFF',
        minHeight: 50
    },
    dialogueActions: {
        display: 'block',
        width: '100%',
        padding: '10px 24px 30px',
    },
    selectRoute: {
      maxWidth: 200,
      "& label": {
          transform: 'translate(14px, 13px) scale(1)',
      },
      "& div": {
          "& div" :{
              padding: 10
          }
      }
  },
  }));

const mapStateToProps = state => ({ 
  debtor: state.debtors.debtor, 
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
  onLoad: mode => 
      dispatch({ type: DEBTOR_EDITOR_MODAL_LOADED, mode }),
  onReLoad: payload => 
      dispatch({ type: DEBTOR_LIST_PAGE_LOADED, payload }),
  onUpdateField: (key, value) => 
    dispatch({ type: UPDATE_FIELD_EDITOR, key, value }),
  onSubmit: (promise) =>  {
    dispatch({ type: DEBTOR_SUBMITTED, payload: promise })
  },
});


function DebtorEditorModal(props) {
  // console.log('props', props);
  const classes = useStyles();

  const [firstStatus, setSelectedStatus] = React.useState(1);
  const org_id = props.currentUser && props.currentUser.org_id;

  const updateFieldEvent = key => ev => props.onUpdateField(key, ev.target.value);

  const changeShopName = updateFieldEvent('shop_name');
  const changeContactNo = updateFieldEvent('contact_number');
  const changeContactName = updateFieldEvent('contact_name');
  const changeDebtLimit = updateFieldEvent('debt_limit');
  const changeGracePeriod = updateFieldEvent('grace_period');
  
  const handleSetStatus = event => {
    const val = event.target.value;    
    setSelectedStatus(val);
};

  useEffect(() => {
    props.onLoad(props.mode);
  }, []);
  
  const handleSubmitForm = ev => {
    ev.preventDefault();
    if(props.debtor && props.debtor.shop_name.length > 0 
      && props.debtor.contact_number.length > 0 
      && props.debtor.contact_name.length > 0) {
      const debtor = {
        shop_name: props.debtor.shop_name,
        contact_number: props.debtor.contact_number,
        contact_name: props.debtor.contact_name,
        debt_limit: props.debtor.debt_limit,
        grace_period: props.debtor.grace_period,
        status: firstStatus,
        org_id
      };

      //submit post
      const submitPromise = props.mode && props.mode==="update" ? api.Debtors.update(debtor, props.debtor.id) : api.Debtors.create(debtor);
      submitPromise.then((response)=> {
        props.onSubmit(response)
        props.onReLoad(api.Debtors.all())
      })

      //close modal
      props.handleClose();
    }
  };
 
  return (
      <Dialog open={props.opened} onClose={props.handleClose} aria-labelledby="form-dialog-title">
          <form 
            className={classes.container} 
            noValidate 
            autoComplete="off">
              <DialogTitle id="form-dialog-title">ENTER DEBTOR DETAILS</DialogTitle>
              <DialogContent>
                
                  <TextField
                      id="shop_name"
                      label="SHOP NAME"
                      placeholder="Input phone number"
                      fullWidth
                      margin="normal"
                      style={{ marginBottom: 8 }}
                      variant="outlined"
                      value={props.debtor ? props.debtor.shop_name : null}
                      onChange={changeShopName}
                      />
                  <TextField
                      id="contact_number"
                      label="PHONE NO"
                      placeholder="Input phone number"
                      fullWidth
                      margin="normal"
                      style={{ marginBottom: 8 }}
                      variant="outlined"
                      value={props.debtor ? props.debtor.contact_number : null}
                      onChange={changeContactNo}
                      />
                  <TextField
                      id="contact_name"
                      label="NAME"
                      placeholder="Input name"
                      fullWidth
                      margin="normal"
                      style={{ marginBottom: 8 }}
                      variant="outlined"
                      value={props.debtor ? props.debtor.contact_name : null}
                      onChange={changeContactName}
                      />
                  <TextField
                      id="debt_limit"
                      label="DEBT LIMIT(Ksh)"
                      placeholder="Input debt limit"
                      fullWidth
                      margin="normal"
                      style={{ marginBottom: 8 }}
                      variant="outlined"
                      type="number"
                      value={props.debtor ? props.debtor.debt_limit : null}
                      onChange={changeDebtLimit}
                      />
                  
                  <TextField
                      id="grace_period"
                      label="GRACE PERIOD (DAYS)"
                      placeholder="Input grace period"
                      fullWidth
                      margin="normal"
                      style={{ marginBottom: 8 }}
                      variant="outlined"
                      type="number"
                      value={props.debtor ? props.debtor.grace_period : null}
                      onChange={changeGracePeriod}
                      />

                  <TextField
                      id="status"
                      select
                      fullWidth
                      label="SELECT STATUS"
                      className={classes.selectRoute}
                      value={firstStatus}
                      onChange={handleSetStatus}
                      SelectProps={{
                          MenuProps: {
                          className: classes.menu,
                          },
                      }}
                      margin="normal"
                      style={{ marginBottom: 8 }}
                      variant="outlined"
                      >
                          <MenuItem key={1} value={1}> ACTIVE </MenuItem>
                          <MenuItem key={2} value={2}> INACTIVE </MenuItem>
                      
                  </TextField>
                  
              </DialogContent>
              <DialogActions className={classes.dialogueActions}>
                  <Button 
                      fullWidth
                      onClick={handleSubmitForm} 
                      className={classes.btnSubmit}
                      type="submit">
                      SUBMIT
                  </Button>
              </DialogActions>
          </form>
      </Dialog>
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(DebtorEditorModal);
