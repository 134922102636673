import React from 'react'

//Theme
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({ 
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: 240
    },
    selectElement: {
        height: 36
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        height: 30
    },
    selectLabel: {
        transform: 'translate(14px, 11px) scale(1)'
    },
 }));

export default function Filter(props) {
    const {slug} = props;
    // console.log('Filter props:', props);
    // console.log('Filter slug:'+ slug);
    const classes = useStyles();
    const inputLabel = React.useRef(null);

    const [truck, setAge] = React.useState('');

    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    
    const handleChange = event => {
        setAge(event.target.value);
    };

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} id="filter-select-label" className={classes.selectLabel}>
                Filter by: SELECT
            </InputLabel>
            <Select
                labelId="filter-select-label"
                id="demo-simple-select-outlined"
                value={truck}
                onChange={handleChange}
                labelWidth={labelWidth}
                className={classes.selectElement}
                >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                <MenuItem value={10}>DATE</MenuItem>
                <MenuItem value={20}>STATUS: Delivered</MenuItem>
                <MenuItem value={30}>STATUS: Pending</MenuItem>
                <MenuItem value={40}>Some other</MenuItem>
                <MenuItem value={50}>And another</MenuItem>
                <MenuItem value={60}>And more</MenuItem>
            </Select>
        </FormControl>
    )
}
