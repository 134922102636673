import React, { useEffect } from 'react'
import { DEBTOR_LIST_PAGE_LOADED, DEBTOR_LIST_PAGE_UNLOADED } from '../../constants/actionTypes';
import { connect } from 'react-redux';
import api from '../../data/api'
import ListDebtors from './ListDebtors';

const mapStateToProps = state => ({
    currentUser: state.common.currentUser
});
  
const mapDispatchToProps = dispatch => ({
    onLoad: (payload, pager) =>
      dispatch({ type: DEBTOR_LIST_PAGE_LOADED, payload }),
    onUnload: () =>
      dispatch({  type: DEBTOR_LIST_PAGE_UNLOADED })
});


function Debtors(props) {
    useEffect(() => {
        props.onLoad(api.Debtors.all(), null);
        return () => {
            props.onUnload();
        }
    }, []);
    
    return (
        <React.Fragment>
            <ListDebtors DEBTORs={props && props.debtors } />
        </React.Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Debtors);
