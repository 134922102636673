import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import api from '../../data/api'
import { 
        ROUTE_EDITOR_MODAL_LOADED, 
        ROUTE_LIST_PAGE_LOADED, 
        UPDATE_FIELD_ROUTE, 
        TRUCK_ROUTE_SUBMITTED, 
        ROUTE_EDITOR_MODAL_UNLOADED 
} from '../../constants/actionTypes';


import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'block',
      flexWrap: 'wrap',
    },
    textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1)
    },
    btnSubmit: {
        backgroundColor: '#1c3e5d',
        width: '100%',
        color: '#FFFFFF',
        minHeight: 50
    },
    dialogueActions: {
        display: 'block',
        width: '100%',
        padding: '10px 24px 30px',
    }
  }));

  const mapStateToProps = state => ({ ...state.truckroutes });

  const mapDispatchToProps = dispatch => ({
    onLoad: (payload, mode) => 
        dispatch({ type: ROUTE_EDITOR_MODAL_LOADED, payload, mode }),
    onReLoad: payload => 
        dispatch({ type: ROUTE_LIST_PAGE_LOADED, payload }),
    onUpdateField: (key, value) => 
      dispatch({ type: UPDATE_FIELD_ROUTE, key, value }),
    onSubmit: (payload) => 
        dispatch({ type: TRUCK_ROUTE_SUBMITTED, payload }),
    // onUnload: () => 
    //   dispatch({ type: ROUTE_EDITOR_MODAL_UNLOADED })
  });

function TruckRouteEditorModal(props) {
    const classes = useStyles();
  
    const updateFieldEvent = key => ev => props.onUpdateField(key, ev.target.value);
    const changeName = updateFieldEvent('name');
    
    useEffect(() => {
      if(props && props.truckroute) {
          props.onLoad(api.Routes.get(props.truckroute.id), props.mode);
      } 
    //   return () => {
    //       props.onUnload();
    //   }
    }, []);
    // console.log('handleSubmitForm > first_name:', props);
    const handleSubmitForm = ev => {
      ev.preventDefault();
      if(props.truckroute && props.truckroute.name.length > 0) {
        const truckroute = {
            name: props.truckroute.name,
            org_id: 1,
        };
  
        //submit post
        const submitPromise = props.mode && props.mode==="update" ? api.Routes.update(truckroute, props.truckroute.id) : api.Routes.create(truckroute) ;

        submitPromise.then(()=> {
            props.onSubmit(submitPromise)

            //then get a new list
            props.onReLoad(api.Routes.all())
        })

        //close modal
        props.handleClose();
      }
    };
    
    let value = props.truckroute ? props.truckroute.name : '';
    return (
        <Dialog open={props.opened} onClose={props.handleClose} aria-labelledby="form-dialog-title">
            <form className={classes.container} noValidate autoComplete="off">
                <DialogTitle id="form-dialog-title">ENTER ROUTE DETAILS</DialogTitle>
                <DialogContent>
                    
                    <TextField
                        id="route_name"
                        label="ROUTE NAME"
                        placeholder="Input route name"
                        fullWidth
                        margin="normal"
                        style={{ marginBottom: 8 }}
                        variant="outlined"
                        value={value}
                        onChange={changeName}
                        />
                    
                </DialogContent>
                <DialogActions className={classes.dialogueActions}>
                    <Button 
                        fullWidth
                        onClick={handleSubmitForm} 
                        className={classes.btnSubmit}>
                        SUBMIT
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckRouteEditorModal);