import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { LOAD_AVATAR } from '../../../../../constants/actionTypes';
import api from '../../../../../data/api'
import UserAvatarButton from './UserAvatarButton';

const mapStateToProps = state => ({
    currentUser: state.common.currentUser
});
  
const mapDispatchToProps = dispatch => ({
    onLoad: (payload) =>
      dispatch({ type: LOAD_AVATAR, payload }),
});


function UserAvatar(props) {
    useEffect(() => {
        props.onLoad(api.Auth.current());
    }, []);
    
    return (
        <React.Fragment>
            <UserAvatarButton user={props && props.common && props.common.currentUser } />
        </React.Fragment>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(UserAvatar);
