import React from 'react'
import { connect } from 'react-redux';

//Theme
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import Avatar from '@material-ui/core/Avatar';
// import userAvatar from '../../../../assets/images/img/avatar1.jpg';
import { Link } from 'react-router-dom'
import AccountCircle from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles(theme => ({
    userAvatar: {
        marginRight: 5,
        borderRadius:0, 
        "& img" : {
            width: 'auto'
        }
    },
    button: {
        margin: theme.spacing(1),
        fontSize: '.9rem',
        fontWeight: 700
      }
}))

const mapStateToProps = state => ({
    currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({});

function UserAvatarButton(props) {
    const classes = useStyles();
    // console.log('UserAvatar props', props);
    if (!props.currentUser) {
        return ( <div>...</div> );
    }
    if (props.currentUser.length === 0) {
        return ( <div> ... </div> );
    }
    return (
        <Button className={classes.button} to={`/profile`} component={Link} > 
            <AccountCircle  className={clsx(classes.userAvatar, classes.bigAvatar)} />
            {props.currentUser.name}
        </Button> 
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAvatarButton);